import React, { useContext } from "react";
import AuroLogo from "../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";
import { DataContext } from "../../context/data/DataState";
import { useMediaQuery } from 'react-responsive'


const Reports = () => {
  const {isVisibleSb, setIsVisibleSb} = useContext(DataContext)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });
  
  return (
    <div className={`w-full flex bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
      <div className="w-full mb-12 h-screen overflow-y-scroll">
        <div className="hidden justify-between items-center pl-4 pr-8 pt-4 xs:flex sm:flex md:flex">
        <FiMenu className="text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2" onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>
          <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">
            Reports
          </h1>
          <AuroLogo />
        </div>
        <div className="flex justify-between items-center pl-4 pr-8 pt-4 xs:hidden sm:hidden md:hidden">
          <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">
            Reports
          </h1>
          <AuroLogo />
        </div>
        <div class="mr-12 ml-4 mt-12 txns overflow-scroll no-scrollbar h-[80vh]">
          <table class="w-full text-[18px] 3xl:text-[12px] border-collapse border font-medium text-center">
            <thead class="sticky top-0 bg-theme-color text-white font-medium border border-theme-color">
              <th className="p-2">Campaign name</th>
              <th className="p-2">Sent</th>
              <th className="p-2">Opened</th>
              <th className="p-2">Click</th>
              <th className="p-2">Bounced</th>
            </thead>
            <tbody class="">
              <tr className="">
                <td className="px-4 py-3 ">Campaigns A</td>
                <td className="px-4 py-3">1000</td>
                <td className="px-4 py-3 ">500</td>
                <td className="px-4 py-3">354</td>
                <td className="px-4 py-3">122</td>
              </tr>

              <tr className="">
                <td className="px-4 py-3 ">Campaigns A</td>
                <td className="px-4 py-3">1000</td>
                <td className="px-4 py-3 ">500</td>
                <td className="px-4 py-3">354</td>
                <td className="px-4 py-3">122</td>
              </tr>
              <tr className="">
                <td className="px-4 py-3 ">Campaigns A</td>
                <td className="px-4 py-3">1000</td>
                <td className="px-4 py-3 ">500</td>
                <td className="px-4 py-3">354</td>
                <td className="px-4 py-3">122</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Reports;