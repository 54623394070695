import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/data/DataState";
import AuroLogo from "../../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";

const GroupHeader = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const handleCreateGroup = () => {
    localStorage.removeItem('groupInfo');
    setShowGroupModal(false)
    navigate('/group-creation')
  };
  const groupInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { groupListState, setGroupListState, setShowGroupModal, isVisibleSb, setIsVisibleSb } = useContext(DataContext);
  return (
    <>
      <div className="4xl:pl-8 pl-6 4xl:pr-8 pr-6 pt-[27px] lg:pt-3 xl:pt-3">
        <div className='block sm:hidden xs:hidden md:hidden'>
          <div className="flex justify-between 4xl:pr-6 pr-4 w-full">
            <div className="flex justify-between items-center w-full">
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Audience <span></span></h1>
          {/* <p className={`3xl:text-[22px] 4xl:text-[30px] text-[#777676] 2xl:text-[18px] text-base 4xl:mt-6 ${location.pathname === '/group-dashboard' ? "mb-[16px]" : ""}`}>{groupInfo?.name}</p> */}
          {/* <div className={`flex justify-between items-center ${location.pathname === '/group-dashboard' ? "pt-4 xl:pt-2 lg:pt-2 " : ""}`}> */}
            {/* {
              location.pathname === '/group-dashboard' ? <p className="text-theme-color font-medium lg:text-[1.5rem] xl:text-[1.5rem] 2xl:text-[1.7rem] 3xl:text-[30px] 4xl:text-[40px]">Groups</p> : <></>
            } */}

            {
              location.pathname === '/group-dashboard' ? groupListState.length > 0 ?
              <button type='button' className=" p-[.45rem_1.5rem] mr-4 lg:p-[.4rem_1rem] xl:p-[.4rem_1rem] lg:text-[13px] xl:text-[13px] font-medium 3xl:text-[15px] 4xl:text-[18px] border-1 border-theme-color bg-white text-theme-color rounded-lg ... " onClick={() => { handleCreateGroup() }}>
                  Create New Group
                </button> :
                <>
                </>
                : <>
                </>
            }
            </div>
            <AuroLogo />
            </div>

          {/* </div> */}

        </div>

        <div className='hidden xs:block sm:block md:block'>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center w-full">
            <FiMenu className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`} onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>
                  <AuroLogo />
</div>
<div className="flex justify-between items-center">
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Audience <span></span></h1>
          {/* <p className={`3xl:text-[22px] 4xl:text-[30px] text-[#777676] 2xl:text-[18px] text-base 4xl:mt-6 ${location.pathname === '/group-dashboard' ? "mb-[16px]" : ""}`}>{groupInfo?.name}</p> */}
          {/* <div className={`flex justify-between items-center ${location.pathname === '/group-dashboard' ? "pt-4 xl:pt-2 lg:pt-2 " : ""}`}> */}
            {/* {
              location.pathname === '/group-dashboard' ? <p className="text-theme-color font-medium lg:text-[1.5rem] xl:text-[1.5rem] 2xl:text-[1.7rem] 3xl:text-[30px] 4xl:text-[40px]">Groups</p> : <></>
            } */}

            {
              location.pathname === '/group-dashboard' ? groupListState.length > 0 ?
              <button type='button' className="p-[.45rem_1.5rem] mr-4 lg:p-[.4rem_1rem] xl:p-[.4rem_1rem] lg:text-[13px] xl:text-[13px] font-medium 3xl:text-[15px] 4xl:text-[18px] border-1 border-theme-color bg-white text-theme-color rounded-lg ... " onClick={() => { handleCreateGroup() }}>
                  Create New Group
                </button> :
                <>
                </>
                : <>
                </>
            }
            </div>
            </div>

          {/* </div> */}

        </div>
      </div>
    </>
  )
}
export default GroupHeader;