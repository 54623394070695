import React from "react";
import { useState } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { RiPencilFill } from 'react-icons/ri';
import bin from '../../../assets/images/bin.svg';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import DeleteTemplateModal from "./TemplateModal/DeleteModal";
import noTemplateData from '../../../assets/images/notemplate.svg';
const IvrTemplateList = () => {
  const location = useLocation();
  const { state } = location;
  const { getIvrTemplate, getStateIvrTemplate, setDeleteIvrTempId, setDeleteTemplateModal, deleteTemplateModal } = useContext(DataContext);

  const navigate = useNavigate();
  const type = 'Ivr'
  useEffect(() => {
    getIvrTemplate(state.item._id)
  }, []);
  const handleDeleteIvr = (id, type) => {
    console.log(id, type)
    setDeleteTemplateModal(true)
    setDeleteIvrTempId({ templateId: id, category: type });
  };
  const handlenavigate = (categoryId) => {
    navigate(`/ivr-create-list/${categoryId}`)
  };
  const handleUpdateTemplate = (categoryId, item) => {
    navigate(`/ivr-update-template/${categoryId}`, {
      state: {
        item
      }
    })
  };

  return (
    <>
      <div className="w-full flex bg-white h-screen">
        <div className="bg-theme-color h-screen">
          
        </div>
        <div className="w-full mb-12 h-screen overflow-x-scroll">
          {deleteTemplateModal === true ? <DeleteTemplateModal></DeleteTemplateModal> : <></>}
          <div className="pl-4 pr-12 flex justify-between mt-4 lg:items-center items-end mb-4">
          <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium">Template List</h1>
            <button type='button' className="p-[.45rem_2.4rem] text-[18px] 4xl:text-[24px] bg-theme-white border border-theme-color text-bg-theme-color rounded-lg ... " onClick={() => { handlenavigate(state.item._id) }}>
              Create Templates
            </button>
          </div>
          <div className={`${getStateIvrTemplate.length === 0 ? "px-8 min-h-[80vh] flex" : "px-8"}`}>
            {
              getStateIvrTemplate.length === 0 ? <img src={noTemplateData} className="h-[50vh] m-auto"></img> :
                getStateIvrTemplate.map((item) => (
                  <div className="mb-[32px]">
                    <h1 className="text-[27px] 4xl:text-[36px] font-semibold text-theme-color">{item.templateName}<span className="text-[10px] 4xl:text-[14px] font-normal text-[#16161699] ml-2">{`(Created on ${(item.createdAt).slice(0, 10)})`}</span></h1>
                    <div className="mt-[24px] flex justify-between" >
                      <div className=''>
                        <audio controls className="w-[800px] h-[54px]">
                          <source src={item.audioFile} />
                        </audio>
                      </div>
                      <div className=" flex items-center mt-2">
                        <span className="cursor-pointer text-[28px] text-theme-color" onClick={() => { handleUpdateTemplate(state.item._id, item) }}><RiPencilFill /></span> <span><img src={bin} width='24px' className="cursor-pointer ml-[38px]" onClick={() => { handleDeleteIvr(item._id, type) }}></img></span>
                      </div>

                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default IvrTemplateList;