import React, { useContext } from "react";
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DataContext } from "../../../context/data/DataState";
import settImg from "../../../assets/images/sett_modal.svg";
import work from "../../../assets/images/work.svg";
import calendar from "../../../assets/images/calendar.svg";
import day_night from "../../../assets/images/day_night.svg";
import caret_blue from "../../../assets/images/caret blue.svg";
import keyIcon from "../../../assets/images/keyboard_icon.svg";
import TimeKeeper from "react-timekeeper";
import DatePicker from "react-datepicker";
import pencil from '../../../assets/images/editpencil.svg'
import { MdOutlineModeEditOutline } from 'react-icons/md';


import "react-datepicker/dist/react-datepicker.css";
import "../CampaignModals/timePicker/timepicker.css";
import "./datePicker/datepicker.css"

const Calender = () => {
  const [openPopGroup, setOpenPopGroup] = useState(true);
  const { setPickDateTime , setCalenderPopUp} = useContext(DataContext);
  const [time, setTime] = useState("12:34pm");
  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        // Close the Datepicker
        console.log("Clicked outside of Datepicker");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[150px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full p-[2rem_4rem] sm:max-w-lg">
                  <div className="flex justify-center items-center flex-col">

                    {/* date picker end  */}
                    <div className=" border-[rgba(22,_22,_22,_0.6)] p-2  rounded-md w-[400px] h-500px">
                      <span className="mr-auto inline-block text-base">Select date</span>
                      <div className="flex text-[43px] py-4 border-b justify-between items-center mb-6">
                        <h1 className="">Tue, Feb 7</h1>
                        <MdOutlineModeEditOutline />
                      </div>
                      <DatePicker selected={startDate}
                        ref={datePickerRef}
                        showYearDropdown
                        dropdownMode="select"
                        inline
                        onChange={(date) => setStartDate(date)} />
                      <div className="flex gap-4 text-theme-color text-[24px] font-semibold justify-end">
                        <span className="cursor-pointer" onClick={()=>{{setPickDateTime(true); setCalenderPopUp(false)}}}>Ok</span>
                        <span className="cursor-pointer" onClick={()=>{{setCalenderPopUp(false)}}}>Cancel</span>
                      </div>
                    </div>
                  </div>


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Calender;
