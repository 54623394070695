import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RxCaretDown, AiFillCaretLeft, RxCaretUp } from "react-icons/rx";
import Speaker from "../../assets/images/Speaker.svg";
import Audience1 from "../../assets/images/Audience1.svg";
import Reports from "../../assets/images/Reports.svg";
import Logout from "../../assets/images/Logout.svg";
import Setting from "../../assets/images/Setting.svg";
import template from "../../assets/images/template.svg";
import { DataContext } from "../../context/data/DataState";
import { useContext } from "react";
import LogoutModal from "../Modal/LogoutModal";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import open_img from "../../assets/images/navbar_icons/open.svg";
import close_img from "../../assets/images/navbar_icons/close.svg";
import logo_sidebar from "../../assets/images/navbar_icons/brand_logo.svg";
import verif from "../../assets/images/navbar_icons/verified-user.svg";
import sav_temp from "../../assets/images/navbar_icons/saved_docs.svg";
import my_plans from "../../assets/images/navbar_icons/Group 176879.svg";
import admin_img from "../../assets/images/navbar_icons/admin.svg";
import creat_camp from "../../assets/images/navbar_icons/create_camp.svg";
import hist_camp from "../../assets/images/navbar_icons/hist_camp.svg";
import sched_meet from "../../assets/images/navbar_icons/sched_meet.svg";
import caret_up from "../../assets/images/navbar_icons/caret.svg";
import profileImg from "../../assets/images/default.png";
import { BiTransfer } from "react-icons/bi"
import { useMediaQuery } from 'react-responsive'
import { AiOutlineClose } from "react-icons/ai";
import { FaUsers, FaGripVertical, FaRegIdBadge, FaSave, FaFileMedical, FaRegPaperPlane, FaUserEdit, FaDraftingCompass, FaStopwatch, FaRegBookmark } from 'react-icons/fa';

// import {
//   ProSidebar,
//   Menu,
//   MenuItem,
//   SidebarHeader,
//   SidebarFooter,
//   SidebarContent,
// } from "react-pro-sidebar";
import {
  ProSidebarProvider,
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

const SideNavBar = () => {

  const [menuCollapse, setMenuCollapse] = useState(false);
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  let location = useLocation();
  const { logoutState, setLogoutState, checkLogin, groupListState, isVisibleSb, setIsVisibleSb } = useContext(DataContext);

  let userData = JSON.parse(localStorage.getItem("userInfo"));

  const [isOpen, setIsOpen] = useState({
    isFirstDropDown: false,
    isSecondDropDown: false,
    isThirdDropDown: false,
  });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });


  let activeStyle = {
    backgroundColor: "white",
    color: "black",
  };

  let activeClassName = "underline";
  const navigate = useNavigate();



  useEffect(() => { }, [location]);
  return (
    <>
      {logoutState === true ? <LogoutModal></LogoutModal> : <></>}
      {checkLogin == true ? (
        <>
          {isSmallScreen ? (<AiOutlineClose className={`absolute left-[205px] md:left top-[34px] font-bold text-3xl text-theme-color rounded-full bg-white z-[99] p-2 ${!isVisibleSb ? "block" : "hidden"}`} onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>) : ""}
         <div className={`bg-theme-color xs:absolute sm:absolute md:absolute left-0 transition-all z-50 h-screen ${isVisibleSb  ? "-left-[245px] md:-left-[251px]" : "left-0" }`}>
        <ProSidebar collapsed={menuCollapse} id="ProSidebar" className="no-scrollbar">
          <div className="bg-theme-color h-full">
                <SidebarHeader>
                  <div className="pt-8 pb-4  4xl:pt-12 lg:pb-4 lg:px-2 xl:pb-4 xl:px-2 3xl:pb-6 4xl:pb-8 pr-4 pl-2 3xl:pl-4 4xl:pl-4 border-b border-[#ffffff75] flex 4xl:gap-0 relative items-center xs:justify-start sm:justify-start md:justify-start justify-between xs:gap-3 sm:gap-3">
                    <img src={userData?.profile === "undefined" ? profileImg : userData?.profile} className="w-[36px] h-[36px] 4xl:w-[50px] 4xl:h-[50px] rounded-full" />
                    {menuCollapse ? (
                      ""
                    ) : (
                      <span className="text-[24px] 4xl:text-[30px] lg:text-[20px] xl:text-[20px] font-bold text-white m-0 p-0">
                        Dashboard
                      </span>
                    )}

                    {!isSmallScreen ? (<div className="closemenu cursor-pointer" onClick={menuIconClick}>
                      {menuCollapse ? (
                        <img
                          src={open_img}
                          className="3xl:text-[15px] w-[15px] 4xl:w-[24px] 4xl:ml-1 max-w-none"
                        />
                      ) : (
                        <img
                          src={close_img}
                          className="3xl:text-[15px] 4xl:ml-1 4xl:w-[24px] w-[15px] max-w-none"
                        />
                      )}
                    </div>) : <></>}
                  </div>
                </SidebarHeader>

                <div className="w-full">
                  <nav className="flex flex-col">
                    {/* Audience */}
                    <MenuItem onClick={()=>{setIsVisibleSb(!isVisibleSb)}}>
                      <ul className={`inline-block py-2.5 w-full font-normal text-xs transition duration-150 ease-in-out ${location.pathname === '/group-dashboard' ? "bg-white" : <></>}`}>
                        <div className="flex pb-3 pl-4 lg:pl-1 xl:pl-1 mt-2.5">
                          <div className="flex ml-[12px] items-center gap-2">
                            {/* <span className='text-[18px]'>Logout</span> */}
                            <NavLink
                              className={`active:text-[#ADD8E6] text-[14px] 4xl:text-[18px] flex gap-3 items-center ${location.pathname === '/group-dashboard' ? "text-theme-color" : "text-white"}`}
                              to="/group-dashboard"
                            >
                              {/* <img
                            src={Audience1}
                            className=" w-[19px] 4xl:w-[20px] max-w-none"
                          /> */}
                              <span className={`text-[19px] 4xl:text-[26px] max-w-none ${location.pathname === '/group-dashboard' ? "text-theme-color" : "text-white"}`}><FaUsers /></span>
                              <span className="aside_title xl:text-[12px] lg:text-[12px]">
                                {" "}
                                Audience dashboard
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </ul>
                    </MenuItem>

                    {/* All Templates */}
                    <MenuItem onClick={()=>{setIsVisibleSb(!isVisibleSb)}}>
                      <ul
                        className={`inline-block py-2.5 w-full text-white font-normal text-xs transition duration-150 ease-in-out `}
                      // onClick={() => { setIsOpen(!isOpen) }}
                      >
                        <div
                          className="flex pb-3 mt-2.5 pl-4 lg:pl-1 xl:pl-1 justify-between px-[10px] cursor-pointer items-center"
                          onClick={(e) => {
                            e.stopPropagation();

                            setIsOpen((isOpen) => ({
                              ...isOpen,
                              isFirstDropDown: !isOpen.isFirstDropDown,
                            }));
                          }}
                        >
                          <div className="flex ml-[12px] gap-3 items-center">
                            <img
                              src={template}
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            ></img>
                            <span className=" text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px] flex gap-3 items-center aside_title xl:text-[12px] lg:text-[12px]">
                              Template
                            </span>
                          </div>
                          {isOpen.isFirstDropDown === true ? (
                            <img src={caret_up} className="w-[11px]" />
                          ) : (
                            <img src={caret_up} className="w-[11px]  rotate-180" />
                          )}
                        </div>

                        <li
                          className={` ${isOpen.isFirstDropDown === true ? "visible" : "collapse"
                            }  ${isOpen.isFirstDropDown === true ? "" : "hidden"
                            } multi-collapse`}
                          id="multiCollapseExample3"
                        >
                          <ul
                            className="flex flex-col"
                            aria-labelledby="dropdownMenuMediumButton"
                          >
                            <li className={`pl-[3.65rem] xl:pl-8 lg:pl-8 py-[8px] ${location.pathname === '/verified-sender-id' ? "bg-white" : <></>}`}>
                              <NavLink
                                className={`flex items-center gap-3 text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] `}
                                to="/verified-sender-id"
                              // to={groupListState.length > 0 ? "/verified-sender-id" : null}
                              >
                                {/* <img
                              src={verif}
                              alt=""
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            /> */}
                                <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/verified-sender-id' ? "text-theme-color" : " text-white"}`}>
                                  <FaRegIdBadge />
                                </span>
                                <span className={`text-[14px] 4xl:text-[18px] aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/verified-sender-id' ? "text-theme-color" : "text-white"}`}>
                                  {" "}
                                  Verified ID's
                                </span>
                              </NavLink>
                            </li>
                            <li className={`pl-[3.65rem] xl:pl-8 lg:pl-8 py-[8px] ${location.pathname === '/categories' ? "bg-white" : <></>}`}>
                              {/* <NavLink
                                className="text-white flex items-center gap-3  active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[18px]"
                                
                                to="/categories"
                              > */}
                              <NavLink
                                className="text-white flex items-center gap-3  active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[18px]"
                                to={userData.userType === 'USER'
                                  ? '/categories'
                                  : userData.userType === 'SUBUSER' && userData.permission.emailCategory.create === true
                                    ? '/categories'
                                    : null
                                }
                                onClick={() => {
                                  if (userData.userType === 'SUBUSER' && userData.permission.emailCategory.create !== true) {
                                    alert('You don\'t have permission ask Admin to give permission.');
                                  }
                                }}
                              >
                              <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/categories' ? "text-theme-color" : "text-white"}`}
                              ><FaSave />
                              </span>
                              <span className={`text-[14px] 4xl:text-[18px] aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/categories' ? "text-theme-color" : "text-white"}`}>
                                {" "}
                                Saved templates
                              </span>
                            </NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </MenuItem>

                {/* Lists of campaigns */}
                {/* <MenuItem>
                <ul className="inline-block py-2.5 w-full text-white font-normal text-xs transition duration-150 ease-in-out ">
                  <div className="flex pl-4 xl:pl-1">
                    <div className="flex ml-[12px] items-center gap-2">
                      <img src={Speaker} className=" 3xl:w-[19px] 3xl:h-[19px] 4xl:w-[26px] 4xl:h-[26px] max-w-none"/> */}
                {/* <span className='text-[18px]'>Logout</span> */}
                {/* <NavLink
                        className="text-[14px] 4xl:text-[18px] flex gap-3 items-center aside_title xl:text-[12px] lg:text-[12px]"
                        to="#"
                      >
                        My Campaigns
                      </NavLink>
                    </div>
                  </div>
                </ul>
              </MenuItem> */}
                <MenuItem onClick={()=>{setIsVisibleSb(!isVisibleSb)}}>

                  <ul
                    className="inline-block py-2.5 w-full text-white font-normal text-xs transition duration-150 ease-in-out "
                  // onClick={() => { setIsOpen(!isOpen) }}
                  >
                    <div
                      className="flex pb-3 mt-2.5 pl-4 lg:pl-1 xl:pl-1 justify-between px-[10px] cursor-pointer items-center"
                      onClick={(e) => {
                        e.stopPropagation();

                        setIsOpen((isOpen) => ({
                          ...isOpen,
                          isSecondDropDown: !isOpen.isSecondDropDown,
                        }));
                      }}
                    >
                      <div className="flex ml-[12px] gap-3 items-center">
                        <img
                          src={template}
                          className=" w-[19px] 4xl:w-[20px] max-w-none"
                        ></img>
                        <span className=" text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px] flex gap-3 items-center aside_title xl:text-[12px] lg:text-[12px]">
                          My Campaigns
                        </span>
                      </div>
                      {isOpen.isSecondDropDown === true ? (
                        <img src={caret_up} className="w-[11px]" />
                      ) : (
                        <img src={caret_up} className="w-[11px]  rotate-180" />
                      )}
                    </div>

                    <li
                      className={` ${isOpen.isSecondDropDown === true ? "visible" : "collapse"
                        }  ${isOpen.isSecondDropDown === true ? "" : "hidden"
                        } multi-collapse`}
                      id="multiCollapseExample3"
                    >
                      <ul
                        className="flex flex-col pt-6 pb-[10px]"
                        aria-labelledby="dropdownMenuMediumButton"
                      >
                        <li className={`pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/create-campaign' ? "bg-white" : <></>}`}>
                          {/* <NavLink
                            className="flex items-center gap-3 text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px]"
                            // to={groupListState.length > 0 ? "/create-campaign" : null}
                            to={"/create-campaign"}
                          > */}
                           <NavLink
                                 className="flex items-center gap-3 text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px]"
                                to={userData.userType === 'USER'
                                  ? "/create-campaign"
                                  : userData.userType === 'SUBUSER' && userData.permission.sendEmailCampaign.send === true
                                    ? "/create-campaign"
                                    : null
                                }
                                onClick={() => {
                                  if (userData.userType === 'SUBUSER' && userData.permission.sendEmailCampaign.send !== true) {
                                    alert('You don\'t have permission ask Admin to give permission.');
                                  }
                                }}
                              >
                            {/* <img
                              src={creat_camp}
                              alt=""
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            /> */}
                            <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/create-campaign' ? "text-theme-color" : "text-white"}`}><FaFileMedical /></span>
                            <span className={`aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/create-campaign' ? "text-theme-color" : "text-white"}`}>
                              Create campaigns
                            </span>
                          </NavLink>
                        </li>
                        <li className={`pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/history-campaign' ? "bg-white" : <></>}`}>
                          <NavLink
                            className="text-white flex items-center gap-3  active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px]"
                            // to={groupListState.length > 0 ?"/history-campaign" : null}
                            to="/history-campaign"
                          >
                            {/* <img
                              src={hist_camp}
                              alt=""
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            /> */}
                            <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/history-campaign' ? "text-theme-color" : "text-white"}`}><FaRegPaperPlane /></span>
                            <span className={`aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/history-campaign' ? "text-theme-color" : "text-white"}`}>
                              Sent campaigns
                            </span>
                          </NavLink>
                        </li>
                        <li className={`pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/scheduled-meeting' ? "bg-white" : <></>}`}>



                          <NavLink
                            className="text-white flex items-center gap-3  active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px]"
                            // to={groupListState.length > 0 ? "/categories" : null}
                            to="/scheduled-meeting"
                          >
                            {/* <img
                              src={sched_meet}
                              alt=""
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            /> */}
                            <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/scheduled-meeting' ? "text-theme-color" : "text-white"}`}><FaStopwatch /></span>
                            <span className={`aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/scheduled-meeting' ? "text-theme-color" : "text-white"}`}>
                              Schedule Campaigns
                            </span>
                          </NavLink>
                        </li>


                        <li className={`mt-1 pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/drafts' ? "bg-white" : <></>}`}>
                          <NavLink
                            className="text-white flex items-center gap-3  active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px]"
                            // to={groupListState.length > 0 ?"/history-campaign" : null}
                            to="/drafts"
                          >
                            {/* <img
                              src={hist_camp}
                              alt=""
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            /> */}
                            <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/drafts' ? "text-theme-color" : "text-white"}`}><FaRegBookmark /></span>
                            <span className={`aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/drafts' ? "text-theme-color" : "text-white"}`} >
                              Drafts
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </MenuItem>

                {/* Reports */}
                {
                  userData?.userType === 'USER' ?
                  <MenuItem onClick={()=>{setIsVisibleSb(!isVisibleSb)}}>
                      <ul className={`inline-block py-2.5 w-full font-normal text-xs transition duration-150 ease-in-out ${location.pathname === '/reports' ? "bg-white" : <></>}`}>
                        <div className="flex pb-3  pl-4 lg:pl-1 xl:pl-1 mt-2.5">
                          <div className="flex ml-[12px] items-center gap-2">
                            {/* <span className='text-[18px]'>Logout</span> */}
                            <NavLink
                              className={`active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px] flex gap-3 items-center ${location.pathname === '/reports' ? "text-theme-color" : "text-white"}`}
                              to="/reports"
                            >
                              {/* <img
                            src={Audience1}
                            className=" w-[19px] 4xl:w-[20px] max-w-none"
                          /> */}
                              <span className={`text-[20px] 4xl:text-[26px] max-w-none ${location.pathname === '/reports' ? "text-theme-color" : "text-white"}`}><FaUsers /></span>
                              <span className="aside_title xl:text-[12px] lg:text-[12px]">
                                {" "}
                                Reports
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </ul>
                    </MenuItem> : <></>}

                {/* <MenuItem>
                  <ul className={`pt-6 pb-[10px] gap-4 inline-block w-full text-white font-normal text-xs transition duration-150 ease-in-out`}>
                  <li className={`mt-1 pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/reports' ? "bg-white" : <></>}`}>
                    <NavLink
                      // to={groupListState.length > 0 ? "/reports" : null} 
                      to="/reports"
                    >

                      <div className="flex pl-4 lg:pl-1 xl:pl-1 cursor-pointer">
                        <div className="flex ml-[12px] items-center gap-2">
                          <img
                            src={Reports}
                            className=" w-[19px] 4xl:w-[20px] max-w-none"
                          />
                          <span className=" text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[14px] 4xl:text-[18px] flex gap-3 items-center aside_title xl:text-[12px] lg:text-[12px]">
                            Reports
                          </span>
                        </div>
                      </div>
                    </NavLink>
                    </li>
                  </ul>
                </MenuItem> */}
                {/* Setting */}
                <MenuItem onClick={()=>{setIsVisibleSb(!isVisibleSb)}}>
                  <ul
                    className="inline-block py-2.5 w-full text-white font-normal text-xs transition duration-150 ease-in-out "
                  // onClick={() => { setIsOpen(!isOpen) }}
                  >
                    <div
                      className="flex pb-3 mt-2.5 pl-4 lg:pl-1 xl:pl-1 justify-between px-[10px] cursor-pointer items-center"
                      onClick={(e) => {
                        e.stopPropagation();

                        setIsOpen((isOpen) => ({
                          ...isOpen,
                          isThirdDropDown: !isOpen.isThirdDropDown,
                        }));
                      }}
                    >
                      <div className="flex ml-[12px] items-center gap-2">
                        <img
                          src={Setting}
                          className=" w-[19px] 4xl:w-[20px] max-w-none"
                        ></img>
                        <span className=" text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[16px] 4xl:text-[18px] flex gap-3 items-center aside_title xl:text-[12px] lg:text-[12px]">
                          Profile
                        </span>
                      </div>
                      {isOpen.isThirdDropDown === true ? (
                        <img src={caret_up} className="w-[11px]" />
                      ) : (
                        <img src={caret_up} className="w-[11px]  rotate-180" />
                      )}
                    </div>

                    <li
                      className={` ${isOpen.isThirdDropDown === true ? "visible" : "collapse"
                        }  ${isOpen.isThirdDropDown === true ? "" : "hidden"
                        } multi-collapse`}
                      id="multiCollapseExample3"
                    >
                      <ul
                        className="flex flex-col pt-6 pb-[10px]"
                        aria-labelledby="dropdownMenuMediumButton"
                      >
                        <li className={`pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/profile' ? "bg-white" : <></>}`}>
                          <NavLink
                            className=" text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[18px] flex items-center gap-3"
                            to="/profile"
                          // to={groupListState.length > 0 ? "/profile" : null}
                          >
                            {" "}
                            {/* <img
                              src={my_plans}
                              alt=""
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            /> */}
                            <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/profile' ? "text-theme-color" : "text-white"}`}
                            ><FaUserEdit /></span>
                            <span className={`text-[14px] 4xl:text-[18px] aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/profile' ? "text-theme-color" : "text-white"}`}>
                              {" "}
                              My Profile
                            </span>
                          </NavLink>
                        </li>

                        <li className={`pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/transactions' ? "bg-white" : <></>}`}>
                          <NavLink
                            className=" text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[18px] flex items-center gap-3"
                            to="/transactions"
                          // to={groupListState.length > 0 ? "/profile" : null}
                          >
                            {" "}
                            {/* <img
                              src={my_plans}
                              alt=""
                              className=" w-[19px] 4xl:w-[20px] max-w-none"
                            /> */}
                            <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none ${location.pathname === '/transactions' ? "text-theme-color" : "text-white"}`}
                            ><BiTransfer /></span>
                            <span className={`text-[14px] 4xl:text-[18px] aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/transactions' ? "text-theme-color" : "text-white"}`}>
                              {" "}
                              Transactions History
                            </span>
                          </NavLink>
                        </li>

                        {
                          userData?.userType === 'USER' ? <li className={`mt-1 pl-[3.65rem] xl:pl-8 lg:pl-8 py-[10px] ${location.pathname === '/members-list' ? "bg-white" : <></>}`}>
                            <NavLink
                              //  to={groupListState.length > 0 ? "/admin"  : null}
                              to="/members-list"
                              className="text-white active:text-[#ADD8E6] hover:text-[#ADD8E6] text-[18px] flex items-center gap-3">
                              {/* <img
                                  src={admin_img} 
                                  alt=""
                                  className=" w-[19px] 4xl:w-[20px] max-w-none"
                                /> */}
                              <span className={`text-[15px] 4xl:w-[20px] 4xl:h-[20px] max-w-none  
                                ${location.pathname === '/members-list' ? "text-theme-color" : "text-white"}`}><FaDraftingCompass /></span>
                              <span className={`text-[14px] 4xl:text-[18px] aside_title xl:text-[12px] lg:text-[12px] ${location.pathname === '/members-list' ? "text-theme-color" : "text-white"}`}>
                                {" "}
                                Administration
                              </span>
                            </NavLink>
                          </li> : <></>
                        }

                      </ul>
                    </li>
                  </ul>
                </MenuItem>
                {/* Logout */}
                <MenuItem onClick={()=>{setIsVisibleSb(!isVisibleSb)}}>
                  <ul className="inline-block py-2.5 w-full text-white font-normal text-xs transition duration-150 ease-in-out ">
                    <div className="flex pb-3 mt-2.5 pl-4 xl:pl-1 lg:pl-1">
                      <div className="flex ml-[12px] items-center gap-2">
                        <img
                          src={Logout}
                          className=" w-[19px] 4xl:w-[20px] max-w-none"
                          onClick={() => setLogoutState(true)}  ></img>
                        {/* <span className='text-[18px]'>Logout</span> */}
                        <NavLink
                          className=" text-[14px] 4xl:text-[18px] flex gap-3 items-center aside_title xl:text-[12px] lg:text-[12px]"
                          onClick={() => setLogoutState(true)}
                        // to='/'
                        >
                          Logout
                        </NavLink>
                      </div>
                    </div>
                  </ul>
                </MenuItem>
              </nav>
          </div>
        </div>
            </ProSidebar> </div > </>) : ""
}
    </>
  );
};
export default SideNavBar;

{
  /* <p class="mb-4">
  <button class="inline-block px-6 py-2.5 bg-blue-600 text-white font-normal text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
    Toggle width collapse
  </button>
</p>
<div style="min-height: 120px;">
  <div class="collapse collapse-horizontal" id="collapseWidthExample">
    <div class="block p-6 rounded-lg shadow-lg bg-white max-w-sm" style="width: 300px;">
      This is some placeholder content for NavLink horizontal collapse. It's hidden by default and shown when triggered.
    </div>
  </div>
</div> */
}
