import React from "react";
import profileImg from "../../assets/images/default.png";
import camera from "../../assets/images/Camera.svg";
import AuroLogo from '../Logo/AuroLogo';
import { useContext } from "react";
import { DataContext } from "../../context/data/DataState";
import { useEffect } from "react";
import { BsCheck2 } from "react-icons/bs";
import {FiMessageSquare, FiPhoneCall, FiMenu} from 'react-icons/fi';
import {AiOutlineWhatsApp, AiOutlineMail} from 'react-icons/ai';
import { TbCurrencyRupee } from "react-icons/tb";
import { useMediaQuery } from 'react-responsive';


const Profile = () => {
  const { myProfile, setMyProfile, GetMyProfile, UpdateMyProfile, isVisibleSb, setIsVisibleSb } = useContext(DataContext);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });


  // Save Function to Update Data
  const handleSubmit = () => {
    UpdateMyProfile(myProfile)
  };

  useEffect(() => {
    GetMyProfile()
  }, [])

  return (
    <div className={`w-full h-screen 4xl:pr-6 pr-4 overflow-scroll ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
      <div className="hidden flex-col gap-4 xs:flex sm:flex pt-4 md:flex pl-4">
        <div className="justify-between items-center flex">
        <FiMenu className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`} onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>
        <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium pt-4 pb-6 4xl:pl-8 3xl:pl-6 pl-4">
          My Profile
        </h1>
    <AuroLogo/>
        </div>
      </div>
      <div className="flex justify-between items-center 4xl:pr-6 pr-4 xs:hidden sm:hidden md:hidden">
        <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium pt-4 pb-6 4xl:pl-8 3xl:pl-6 pl-4">
          My Profile
        </h1>
        <AuroLogo />
      </div>
      <div className="outer pl-4 3xl:pl-6 4xl:pl-8 pr-12 xs:pr-4 sm:pr-4 md:pr-4">
        <div className="flex items-center gap-12 pb-4">
          <div className="relative">
            <img
              src={
                myProfile?.profile !== undefined
                  ? typeof myProfile.profile === 'string' // check if profile is a string
                    ? myProfile.profile // use as image source
                    : URL.createObjectURL(myProfile.profile) // create object URL for file
                  : profileImg // use default image
              }

              className="drop-shadow-myp_filter border-[6px] 3xl:w-[110px] 4xl:w-[125px] 4xl:h-[125px] 3xl:h-[110px] h-[90px] w-[90px] object-cover rounded-full border-[#4D86BF]" />
            <div class="image-upload absolute bg-theme-color right-0 bottom-0 p-2 rounded-full">
              <label for="file-input">
                <img className="cursor-pointer" src={camera} alt="" />
              </label>
              <input id="file-input" className="hidden" type="file" onChange={(e) => setMyProfile({
                ...myProfile, profile:
                  e.target.files[0]
              })} />
            </div>
          </div>
          <p className="text-[#083A50] 4xl:text-[24px] 3xl:text-[20px] text-[18px] font-semibold">
            {myProfile?.businessName}
          </p>
        </div>
        <div className="grid grid-cols-4 gap-4 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 place-content-center place-items-ccenter 4xl:mr-28 3xl:mr-10 pt-4">
          <div className="flex gap-4 items-center justify-start border border-black rounded-lg py-2 px-4">
            <div><FiMessageSquare className="4xl:text-[20px] 3xl:text-[18px] text-[15px] text-theme-color font-medium"/></div>
            <div className="flex flex-col">
              <p className="text-theme-color 4xl:text-[20px] xl:text-[14px] lg:text-[13px] xs:text-[13px]  font-semibold">800<span className="text-black"> SMS</span></p>
              <span className="text-[#5F6D7E] 4xl:text-[11px] text-[10px] lg:text-[9px]">left of 1000 sms</span>
            </div>

          </div>
          <div className="flex gap-4 items-center justify-start border border-black rounded-lg py-2 px-4">
            <div><FiPhoneCall className="4xl:text-[20px] 3xl:text-[18px] text-[15px] text-theme-color font-medium"/></div>
            <div className="flex flex-col">
              <p className="text-theme-color 4xl:text-[20px] xl:text-[14px] lg:text-[13px] xs:text-[13px]  font-semibold">650<span className="text-black"> IVR</span></p>
              <span className="text-[#5F6D7E] 4xl:text-[11px] text-[10px] lg:text-[9px]">left of 1000 IVR</span>
            </div>

          </div>
          <div className="flex gap-4 items-center justify-start border border-black rounded-lg py-2 px-4">
            <div><AiOutlineWhatsApp className="4xl:text-[20px] 3xl:text-[18px] text-[15px] text-theme-color font-medium"/></div>
            <div className="flex flex-col">
              <p className="text-theme-color 4xl:text-[20px] xl:text-[14px] lg:text-[13px] xs:text-[13px]  font-semibold">200<span className="text-black"> Whatsapp</span></p>
              <span className="text-[#5F6D7E] 4xl:text-[11px] text-[10px] lg:text-[9px] ">left of 1000 Whatsapp</span>
            </div>

          </div>
          <div className="flex gap-4 items-center justify-start border border-black rounded-lg py-2 px-4">
            <div><AiOutlineMail className="4xl:text-[20px] 3xl:text-[18px] text-[15px] text-theme-color font-medium"/></div>
            <div className="flex flex-col">
              <p className="text-theme-color 4xl:text-[20px] xl:text-[14px] lg:text-[13px] xs:text-[13px] font-semibold">800<span className="text-black"> Email</span></p>
              <span className="text-[#5F6D7E] 4xl:text-[11px] text-[10px] lg:text-[9px]">left of 1000 Email</span>
            </div>

          </div>
        </div>
        <div className="pt-6 4xl:mb-6 3xl:mb-2">
          <form action="" className=" gap-12 grid grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1">

            <div className="personal  3xl:gap-24 4xl:gap-32 gap-20">
              <h1 className="font-semibold 4xl:text-[24px] 3xl:text-[20px] text-[18px] pb-2">
                Personal
              </h1>
              <div class="relative mb-4">
                <label for="full-name" class="leading-7 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium">
                  Enter name
                </label>
                <input type="text" id="full-name" name="full-name" defaultValue={myProfile?.name}
                  class="w-full bg-white rounded-md border border-[#241C15] focus:border-theme-color   text-base lg:text-[14px] xs:text-[14px] outline-none text-gray-700 py-1 px-3 leading-8 3xl:leading-4 transition-colors duration-200 ease-in-out"
                  onChange={(e) => setMyProfile({ ...myProfile, name: e.target.value })}
                />
              </div>
              <div class="relative mb-4">
                <label
                  for="phone"
                  class="leading-7 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  defaultValue={myProfile?.phoneNumber}
                  class="w-full bg-white rounded-md border border-[#241C15] focus:border-theme-color   text-base lg:text-[14px] xs:text-[14px] outline-none text-gray-700 py-1 px-3 leading-8 3xl:leading-4 transition-colors duration-200 ease-in-out" onChange={(e) => setMyProfile({ ...myProfile, phoneNumber: e.target.value })}
                />
              </div>
              <div class="relative mb-4">
                <label
                  for="mail"
                  class="leading-7 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                >
                  Email
                </label>
                <input
                  type="text"
                  id="mail"
                  name="mail"
                  defaultValue={myProfile?.email}
                  class="w-full bg-white rounded-md border border-[#241C15] focus:border-theme-color   text-base lg:text-[14px] xs:text-[14px] outline-none text-gray-700 py-1 px-3 leading-8 3xl:leading-4 transition-colors duration-200 ease-in-out" onChange={(e) => setMyProfile({ ...myProfile, email: e.target.value })}
                />
              </div>
              <div class="relative">
                <label
                  for="dob"
                  class="leading-7 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  max={new Date().toISOString().split("T")[0]}
                  defaultValue={myProfile?.dateofbirth}
                  value={myProfile?.dateofbirth}
                  class="w-full bg-white rounded-md border border-[#241C15] focus:border-theme-color   text-base lg:text-[14px] xs:text-[14px]  outline-none text-gray-700 py-1 px-3 leading-[1.5] 4xl:leading-8 transition-colors duration-200 ease-in-out" onChange={(e) => setMyProfile({ ...myProfile, dateofbirth: e.target.value })}
                />
              </div>
              <button type="button" className="p-[.6rem_1.5rem] mt-12 4xl:mt-8 bg-theme-color text-white rounded-md mb-2 xs:hidden sm:hidden md:hidden" onClick={() => { handleSubmit() }}>
          Save
        </button>
            </div>
            <div className="business  3xl:gap-24 4xl:gap-32 gap-20">
              <div className="relative">
                <h1 className="font-semibold 4xl:text-[24px] 3xl:text-[20px] text-[18px] pb-2">
                  Business
                </h1>
                <div class="relative mb-4">
                  <label
                    for="full-name"
                    class="leading-7 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                  >
                    Business name
                  </label>
                  <input
                    type="text"
                    id="business_name"
                    name="business_name"
                    defaultValue={myProfile?.businessName}
                    class="w-full bg-white rounded-md border border-[#241C15] focus:border-theme-color   text-base lg:text-[14px] xs:text-[14px] outline-none text-gray-700 py-1 px-3 leading-8 3xl:leading-4 transition-colors duration-200 ease-in-out" onChange={(e) => setMyProfile({ ...myProfile, businessName: e.target.value })}
                  />
                </div>
                <div class="relative mb-4">
                  <label
                    for="full-name"
                    class="leading-7 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                  >
                    GST No.
                  </label>
                  <input
                    type="text"
                    id="gst"
                    name="gst"
                    defaultValue={myProfile?.gstNumber}
                    class="w-full bg-white rounded-md border border-[#241C15] focus:border-theme-color   text-base lg:text-[14px] xs:text-[14px]  outline-none text-gray-700 py-1 px-3 leading-8 3xl:leading-4 transition-colors duration-200 ease-in-out" onChange={(e) => setMyProfile({ ...myProfile, gstNumber: e.target.value })}
                  />
                </div>
                <div class="relative">
                  <label
                    for="Authorized person"
                    class="leading-7 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                  >
                    Authorized Person
                  </label>
                  <input
                    type="text"
                    id="auth_person"
                    name="auth_person"
                    defaultValue={myProfile?.authorization}
                    class="w-full bg-white rounded-md border border-[#241C15] focus:border-theme-color   text-base lg:text-[14px]  xs:text-[14px] outline-none text-gray-700 py-1 px-3 leading-8 3xl:leading-4 transition-colors duration-200 ease-in-out" onChange={(e) => setMyProfile({ ...myProfile, authorization: e.target.value })}
                  />
                </div>
              </div>

            </div>
            <div className="plans">
              <h1 className="font-semibold 4xl:text-[24px] 3xl:text-[20px] pb-2 text-[18px]">
                Plans
              </h1>
              <div className="ml-auto mb-8">
                <div className=" bg-white border border-gray-600 rounded-md 4xl:max-w-[1800px] 3xl:w-[18rem] 4xl:w-[22rem] p-[23px] flex flex-col text-center">
                  <div className="body flex-auto text-start">
                    <span className="text-[10px]">Basic plan 1</span>
                    <h1 className="font-semibold text-[30px]"><span className="flex items-center"><TbCurrencyRupee/>19/mo</span></h1>
                    <span className="text-[10px]">or $199 yearly</span>
                    <div className="line w-full h-[1px] bg-black my-4"></div>
                    <span className="text-[10px] inline-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates vitae quia amet ullam ab vel molestias natus error quis aspernatur?</span>
                    <div className="plan_details flex flex-col pb-4">
                      <span className="text-[10px] py-2">Includes:</span>
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-4">
                          <BsCheck2 />
                          <span className="text-[10px]"><span className="text-theme-color font-semibold 4xl:text-[10px]">1000</span> Sms messages</span>
                        </div>
                        <div className="flex gap-4">
                          <BsCheck2 />
                          <span className="text-[10px]"><span className="text-theme-color font-semibold 4xl:text-[10px]">1000</span> Sms messages</span>
                        </div>
                        <div className="flex gap-4">
                          <BsCheck2 />
                          <span className="text-[10px]"><span className="text-theme-color font-semibold 4xl:text-[10px]">1000</span> Sms messages</span>
                        </div>
                        <div className="flex gap-4">
                          <BsCheck2 />
                          <span className="text-[10px]"><span className="text-theme-color font-semibold 4xl:text-[10px]">1000</span> Sms messages</span>
                        </div>
                      </div>
                    </div>
                    <button className="bg-theme-color text-white p-[.45rem_1rem] w-full rounded-md">Upgrade</button>

                  </div>
                </div>
              </div>
            </div>
          <div className="hidden xs:block sm:block md:block mt-8 xs:mt-[-25px] sm:mt-[-20px]   md:mt-[-20px]">
            <button type="button" className="p-[.6rem_1.5rem] bg-theme-color w-auto text-white rounded-md mb-2 " onClick={() => { handleSubmit() }}>
          Save
        </button>
        </div>
            
          </form>
        </div>
        
      </div>
    </div>
  );
};

export default Profile;
