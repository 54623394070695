import { clear } from "@testing-library/user-event/dist/clear";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/data/DataState";
import Accordion from "./Accordian";

const Filter = () => {

  var arr = [];
  const { id } = useParams()
  const { filterSpecific } = useContext(DataContext);
  const [filterValue, setFilterValue] = useState({
    filteredUser: [],
  });
  const [filterMore, setFilterMore] = useState(10);
  const { getAudienceData, columnHeader, filterAudience } = useContext(DataContext);

  const handleChange = (e, columnHeader) => {
    const { checked, value } = e.target;
    const { filteredUser } = filterValue;
    if (checked && value) {
      setFilterValue({
        filteredUser: [...filteredUser, { [columnHeader]: [value] }],
      })
    }
    else {
      const removeItem = { [columnHeader]: [value] };
      const filteredArray = filterValue.filteredUser.filter(item => {
        for (const [key, values] of Object.entries(removeItem)) {
          if (item[key] && item[key].some(name => values.includes(name))) {
            return false;
          }
        }
        return true;
      });
      setFilterValue({
        filteredUser: filteredArray,
      })
    }
  }

  const handleClear = () => {
    const all = document.getElementsByName('boxes');
    for (var a = 0; a < all.length; a++) {
      all[a].checked = false;
    }
    getAudienceData(id);
    setFilterValue({
      filteredUser: []
    })
  };

  const checker = (k) => {
    const result = k.reduce((acc, curr) => {
      const [key, value] = Object.entries(curr)[0];
      if (key in acc) {
        acc[key] = [...acc[key], ...value];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
    filterSpecific(result, id)
  };
  const showMore = () => {
    setFilterMore(filterMore + 2);

  }
  const showLess = () => {
    setFilterMore(filterMore - 2)
  }

  useEffect(() => {
    checker(filterValue.filteredUser)
  }, [filterValue.filteredUser])

  console.log(filterAudience ,"filterAudience")

  return (

    <>
      <div className=" bg-[#003162] text-white p-[8px_13px] sm:p-[8px_8px] xs:p-[8px_3px] lg:p-2 xl:p-2 flex justify-between items-center cursor-pointer">
        <h1 className="4xl:text-[22px] text-[16px] sm:text-[11px] xs:text-[11px] font-medium">
          Filters
        </h1>
        <h1 className="4xl:text-[14px] text-[11px] sm:text-[10px] xs:text-[9px] font-normal" onClick={() => handleClear()}>
          Clear all
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="">
          {
            columnHeader?.map((header, i) => (
              <div className="">
                <Accordion
                  title={header.columnName}>
                  <ul className="pl-2 3xl:pl-4 4xl:pl-4">
                    {
                      filterAudience?.slice(0, filterMore).map((v) => {
                        if (!arr.includes(v[header.columnName])) {
                          arr.push(v[header.columnName])
                          return (
                            <li className="pb-3 pl-[11px] items-center flex">
                              <div className="flex">
                                <input type='checkbox' className="mr-[10px] cursor-pointer" name="boxes" value={v[header.columnName]} onClick={(e) => handleChange(e, header.columnName, v[header.columnName])} />
                              </div>
                              <div className="break-all text-[12px] 3xl:text-[14px] 4xl:text-[16px]">
                                {v[header.columnName]}
                              </div>

                            </li>
                          )
                        }
                      })
                    }
                  </ul>
                  {
                    filterAudience?.length > 10
                      ? <div className="flex justify-end">  <p className=" w-70 p-[4px] text-xs font-400 text-theme-color rounded-lg ... " onClick={() => showMore()}>Show more</p>  {/* <button className=" w-50 h-4 bg-theme-color text-white rounded-lg ... " onClick={() => showLess()}>Show Less</button> */}</div> : <></>
                  }
                </Accordion>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
export default Filter;
