// import React from 'react';
// import { useContext } from 'react';
// import { DataContext } from '../../../context/data/DataState';

// const DeleteUserModal = () => {
//   const {deletMemberPopUp , 
//     setDeleteMemberPopUp} = useContext(DataContext)
//   return (
//     <div>
  
//     <img src="https://cdn-icons-png.flaticon.com/512/3687/3687412.png" class="w-10" alt=""/>
//     <div class="flex flex-col pb-4">
//     <span class="font-semibold text-lg pb-1">Delete user?</span>
//     <span class="text-gray-300 text-md">Are you sure you want to delete this user? This action cannot be undone.</span>
//     </div>
//     <div class="buttons gap-4 w-full">
//       <button class="rounded border p-[.4rem_1rem]" onClick={()=>{setDeleteMemberPopUp(false)}}>Cancel</button>
//       <button class="bg-red-500 rounded-md text-white p-[.4rem_1rem]">Delete</button>
//       </div>
//         </div>
     
//   )
// }

// export default DeleteUserModal

import React from "react";
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import { useNavigate } from "react-router-dom";
import del from "../../../assets/images/delete_user.svg"

const DeleteUserModal = () => {
  const {deletMemberPopUp , 
    setDeleteMemberPopUp , DeleteSubUSer} = useContext(DataContext)
    const [openPopGroup, setOpenPopGroup] = useState(true);

  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto" onClick={()=>{setDeleteMemberPopUp(false)}}>
            <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[400px] overflow-hidden p-6 rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="">
  
     <img src={del} class="w-[48px] pb-4" alt=""/>
     <div class="flex flex-col pb-2">
     <span className="font-bold text-[18px] pb-1">Delete user?</span>
     <span className="text-[#475467] text-[14px]">Are you sure you want to delete this user? This action cannot be undone.</span>
     </div>
     <div class="buttons flex gap-3 mt-6 w-full">
       <button className="rounded-md border border-[#D0D5DD] font-medium flex-1 p-[.4rem_1rem]" onClick={()=>{setDeleteMemberPopUp(false)}}>Cancel</button>
       <button className="bg-[#D92D20] flex-1 rounded-md font-medium text-white p-[.4rem_1rem]" onClick={()=>{DeleteSubUSer(); setDeleteMemberPopUp(false)}}>Delete</button>
       </div>
         </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default DeleteUserModal;