const apiEndPoints = {
  LOGIN: 'user/login',
  REGISTER: 'user/register',
  VERIFYOTP: 'user/verifyOTP',
  SENDALLINFO: 'user/updateById/',
  FORGET_PASS: 'user/forgetPassword',
  FORGET_OTP: 'user/verifyOTP',
  UPDATE_PASSWORD: 'user/updatePassword',
  RESEND_OTP: 'user/resendOtp',
  ADD_CONTACT: 'manageContact/addContact',
  GET_CONTACT: 'manageContact/getcontact/',
  NEW_GROUP: 'group/addgroup',
  GET_GROUP: 'group/getgroup/findAll/',
  DELETE_GROUP: 'group/deletegroup/',
  UPDATE_GROUP: 'group/updategroup/',
  ADD_COLUMN: 'rules/addRules',
  ADD_GROUP_AUDIENCE: 'groupdata/add-data-group',
  GET_SPECIFIC_DATA: 'groupdata/getdatagroup/',
  GET_SPECIFIC_COLUMN: 'rules/getRulesByGroupID/',
  UPLOAD_EXCEL: 'manageContact/addContact/',
  UPDATE_EXCEL: 'manageContact/updateExcelFile/',
  GET_AUDIENCE_DATA: 'manageContact/getmanageContact/findAll/',
  GET_FILTER_DATA: 'manageContact/innerFilter/',
  Data_Type: 'manageContact/checktype',
  UPDATE_COLUMN: 'rules/updateRule/',
  VERIFY_SENDER_EMAIL: "verifyid/sendotp",
  VERIFY_SENDER_EMAIL_OTP: "verifyid/verifyotp",
  VERIFY_RESEND_EMAIL_OTP: "verifyid/resendotp",
  GET_EMAIL_SENDER_ID: "verifyid/getemail/",
  VERIFY_DLT_SMS: 'verifyid/dltsms',
  GET_SMS_DLT_ID: 'verifyid/getdltsms/',
  VERIFY_DLT_WHATSAPP: 'verifyid/dltsms',
  GET_WHATSAPP_DLT_ID: 'verifyid/getdltwhatapp/',
  CREATE_CATEGORY: 'category/createCategories',
  GET_CATEGORY: 'category/getCategories/',
  CREATE_IVR: 'managetemplate/addIvr',
  Get_IVR: 'managetemplate/getIvrTemplate/allCategoryTemplet/',
  DELETE_IVR: 'managetemplate/deleteIvrtemplate/',
  UPDATE_TEMPLATE_IVR: 'managetemplate/updatetemplateIvr/',
  CREATE_TEMPLATE: 'managetemplate/addtemplate',
  GET_TEMPLATE: 'managetemplate/gettemplate/allCategoryTemplet/',
  UPDATE_CATEGORY: 'category/updateCategories/',
  CREATE_MESSAGE_TEMPLATE: 'managetemplate/addMessageTemplate',
  GET_MESSAGE_TEMPLATE: 'managetemplate/getMessageTemplate/allCategoryTemplet/',
  DELETE_Message_Template: 'managetemplate/deleteMessageTemplate/',
  GET_EMAIL_TEMPLATE: 'managetemplate/getEmailTemplate/allCategoryTemplet/',
  GET_WHATSAPP_TEMPLATE: 'managetemplate/getWhatsappTemplate/allCategoryTemplet/',
  DELETE_EMAIL_TEMPLATE: 'managetemplate/deleteEmailTemplate/',
  DELETE_WHATSAPP_TEMPLATE: 'managetemplate/deleteWhatsappTemplate/',
  APPROVED_ALL_EMAIL: 'verifyid/getapprovedemail/',
  SEND_DIRECT_EMAIL: 'createcampaign/sendemailcampaign',
  SCHEDULE_EMAIL: 'createcampaign/scheduleemailcampaign/'
}
export default apiEndPoints;