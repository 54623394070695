import React, { useEffect } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import back from '../../assets/images/back.svg';
import { DataContext } from "../../context/data/DataState";

const FinalGo = () => {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/group-dashboard")
    };
    useEffect(() => {

    })
    return (
        <div className="grid grid-cols-2 w-full lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 lg:gap-24  lg:place-content-start place-content-center gap-0 max-h-screen">
            <div className="flex items-center lg:justify-center relative ">
                <div className="md:pl-[20px]">
                <div className="">
              {/* <Link to='/' ><img src={back} className='w-[23px] h-[20px] absolute lg:top-[-25%] lg:left-[20%] xl:left-[5%] left-[10%] 4xl:left-[8%] top-[22%]'></img> </Link> */}
              </div>
                    {/* <Link to='/business-address' ><img src={back} width='58px' height='58px' className='mt-40 ml-20'></img> </Link> */}
<></>
                    <div className="lg:ml-16 xl:ml-6 2xl:ml-12 xl:w-4/5 3xl:ml-20 4xl:ml-24 sm:p-[33px] xs:p-[22px]">
                        <h1 className="text-theme-color lg:text-[28px] xl:text-[26px] mt-4 font-bold text-[24px] 3xl:text-[27px] 4xl:text-[39px]">
                            You're on your way!
                        </h1>
                        <p className="mt-3 3xl:text-[17px] xs:text-[16px] xl:ml-0 lg:ml-0 4xl:text-[22px] font-medium">
                            Let's get started creating emails that'll help you sell more stuff.</p>
                            <button type='button' onClick={(e) => { handleSubmit(e) }} className="flex  lg:mr-auto lg:ml-0 mt-8 4xl:mt-9 text-[15px] 4xl:text-[23px] sm:text-[13px] xs:text-[12px] font-semibold p-[.6rem_4rem] sm:p-[4px_40px]  xs:p-[4px_30px] 3xl:p-[.65rem_5rem] 4xl:p-[1rem_8.3rem] disabled:opacity-40 bg-theme-color text-white rounded-xl 4xl:rounded-2xl ...">Let's go</button>
                    </div>
                   
                </div>
            </div>
            <div className=" h-screen sm:hidden xs:hidden">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full"></div>
      </div>
        </div>
    )
}
export default FinalGo;