import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/data/DataState";
import SideNavBar from "../SideNavBar/SideNavBar";
import AuroLogo from "../Logo/AuroLogo";
import nooData from '../../assets/images/No data.gif';
const CampaignGroupModal = () => {

  const groupInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { groupListState, getColumn, groupList,
    setGroupId, getAudienceData, sendEmailData, setSendEmailData } = useContext(DataContext);
  const navigate = useNavigate()

  const handleViewGroup = (id, specficGroupName) => {
    console.log(id, 'id')
    setGroupId(id);
    getColumn(id);
    getAudienceData(id);
    setTimeout(() => {
      navigate(`/select-audience/${id}/${specficGroupName}`)
    }, 1000);
    setSendEmailData({ ...sendEmailData, groupAudience: specficGroupName });
  };

  console.log(groupListState, "groupList");
  useEffect(() => {
    groupList()
  }, [])
  return (
    <div className="w-full flex bg-white h-screen">
      <div className=" bg-theme-color h-screen">

      </div>
      <div className="w-full mb-12 h-screen overflow-y-scroll overflow-x-scroll">
        <div className="ml-4 mt-4 mb-10 flex justify-between items-center 4xl:pr-8 pr-4">
          <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium">Audience</h1>
          <AuroLogo/>
          {/* <p className={`3xl:text-[22px] 4xl:text-[30px] text-[#777676] text-base 4xl:mt-6 "mb-[16px]`}>{groupInfo?.name}</p> */}
          {/* <h1 className="mt-[32px] lg:mt-4 font-medium text-[24px] 4xl:text-[40px] 3xl:text-[36px] text-theme-color">Groups</h1> */}
        </div>
        <>{
          groupListState.map((item) => (
            <div className="pr-12 pl-4 my-3">
              <div className="border-t-2 lg:py-1 xl:py-1 py-3">
                <div className="flex justify-between ">
                  <div className="">
                    <h1 className="4xl:text-[30px] 2xl:text-[1.4rem] xl:text-[1.3rem] lg:text-[1.3rem] 3xl:text-[20px] font-medium">{item.groupName}</h1>
                  </div>
                  <div className="flex items-center">
                    <button type='button' className="p-[.45rem_1.2rem] lg:text-[12px] xl:text-[12px]  3xl:text-[18px] font-medium 4xl:text-[24px] bg-theme-color text-white rounded-lg ... " onClick={() => { { handleViewGroup(item._id, item.groupName) } }}>
                      View Group
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        </>
      </div>
    </div>

  )
}
export default CampaignGroupModal;