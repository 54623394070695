import "../src/index.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Registration from "./component/Registration/Registration";
import Login from "./component/Login/Login";
import LandingPage from "./component/LandingPage/LandingPage";
import BussinessInfo from "./component/Registration/BusinessInfo";
import BusinessAdd from "./component/Registration/BusinessAdd";
import FinalGo from "./component/Registration/FinalGo";
import PlanSetup from "./component/Registration/PlanSetup";
import UpgradePlan from "./component/Registration/UpgradePlan";
import EmailVerify from "./component/ForgotPassword/EmailVerification";
import OtpVerify from "./component/ForgotPassword/OtpVerification";
import ResetPassword from "./component/ForgotPassword/ResetPassword";
import UserVerify from "./component/Registration/UserVerification";
import { DataContext, DataProvider } from "./context/data/DataState";
import GroupDashboard from "./component/Audience/Group/GroupDashboard";
import CreateGroup from "./component/Audience/Group/CreateGroup";
import AudienceTable from "./component/Audience/AudienceDashboard/AudienceTable";
import VerifiedId from "./component/Templates/Verification/VerifyId";
import CategoryList from "./component/Templates/Categories/CategoryList";
import UpdateGroup from "./component/Audience/Group/UpdateGroup";
import CreateCategory from "./component/Templates/Categories/CreateCategory";
import CreateTemplate from "./component/Templates/TemplateCategory/CreateTemplate";
import IvrTemplateList from "./component/Templates/TemplateCategory/IvrTemplateList";
import IvrCreateTemplate from "./component/Templates/TemplateCategory/IvrCreateTemplate";
import MessageCreateTemplate from "./component/Templates/TemplateCategory/MessageCreateTemplate";
import IvrUpdateTemplate from "./component/Templates/TemplateCategory/IvrUpdateTemplate";
import EmailTemplateList from "./component/Templates/TemplateCategory/EmailTemplateList";
import WhatsappTemplateList from "./component/Templates/TemplateCategory/WhatsappTemplateList";
import MessageTemplateList from "./component/Templates/TemplateCategory/MessageTemplateList";
import UpdateTemplates from "./component/Templates/TemplateCategory/UpdateTemplates";
import UpdateCategory from "./component/Templates/Categories/UpdateCategory";
import CreateCampaign from "./component/Campaigns/CreateCampaign";
import SendEmail from "./component/Campaigns/SendEmail";
import SelectAudience from "./component/Campaigns/SelectAudience";
import SendMessage from "./component/Campaigns/SendMessage";
import SendIvr from "./component/Campaigns/SendIvr";
import CampaignGroupModal from "./component/Campaigns/CampaignGroupList";
import CampaignCategory from "./component/Campaigns/CampaignCategory";
import SelectTemplate from "./component/Campaigns/SelectTemplateList";
import HistoryCampaign from "./component/Campaigns/HistoryCampaign";
import SideNavBar from "./component/SideNavBar/SideNavBar";
import TermsCondition from "./component/Terms&Condition/TermsCondition";
import Profile from "./component/Profile/Profile";
import Reports from "./component/Reports/Reports";
import ScheduledMeeting from "./component/Campaigns/ScheduledMeeting";
import MembersList from "./component/Administration/MembersList";
import EditAccess from "./component/Administration/EditAccess";
import TransactionsHistory from "./component/Profile/TransactionsHistory";
import Drafts from "./component/Campaigns/DraftsCampaigns";
import {FiMenu} from 'react-icons/fi'
import { useContext, useState } from "react";
import SubUserLogin from "./component/Login/SubUserLogin";



function App() {

  return (
    <>
      <BrowserRouter>
        <DataProvider>
          <div className="w-full flex bg-white h-screen relative">
           
           
              <SideNavBar />

            <Routes>
            <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/subuser-login" element={<SubUserLogin />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/user-email-verification" element={<UserVerify />}/>
              <Route path="/select-plan" element={<PlanSetup />} />
              <Route path="/info" element={<BussinessInfo />} />
              <Route path="/business-address" element={<BusinessAdd />} />
              <Route path="/final-step" element={<FinalGo />} />
              <Route path="/upgrade-plan" element={<UpgradePlan />} />
              <Route path="/forgot-password" element={<EmailVerify />} />
              <Route path="/verify-otp" element={<OtpVerify />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/group-dashboard" element={<GroupDashboard />} />
              <Route path="/group-creation" element={<CreateGroup />} />
              
              <Route
                path={"/Update-group-creation"}
                element={<UpdateGroup />}
              />
              <Route
                path={`/audience-table/:id/:specficGroupName`}
                element={<AudienceTable />}
              />
              <Route path="/verified-sender-id" element={<VerifiedId />} />
              <Route path="/categories" element={<CategoryList />} />
              <Route path="/create-categories" element={<CreateCategory />} />
              <Route
                path="/email-template-list/:id"
                element={<EmailTemplateList />}
              />
              <Route
                path="/create-template/:id"
                element={<CreateTemplate />}
              />
              <Route
                path="/ivr-create-list/:id"
                element={<IvrCreateTemplate />}
              />
              <Route
                path={`/ivr-template-list/:id`}
                element={<IvrTemplateList />}
              />
              <Route
                path={`/ivr-update-template/:id`}
                element={<IvrUpdateTemplate />}
              />
              <Route
                path={`/update-template/:id`}
                element={<UpdateTemplates />}
              />
              <Route
                path="/whatsapp-template-list/:id"
                element={<WhatsappTemplateList />}
              />
              <Route
                path="/message-create-template/:id"
                element={<MessageCreateTemplate />}
              />
              <Route
                path="/message-template-list/:id"
                element={<MessageTemplateList />}
              />
              <Route path="/update-categories" element={<UpdateCategory />} />
              <Route path="/create-campaign" element={<CreateCampaign />} />
              <Route path={`/send-email`} element={<SendEmail />} />
              <Route path="/send-ivr" element={<SendIvr />} />
              <Route
                path={`/select-audience/:id/:specficGroupName`}
                element={<SelectAudience />}
              />
              <Route path="/send-message" element={<SendMessage />} />
              <Route path="/select-group" element={<CampaignGroupModal />} />
              <Route
                path="/campaign-category"
                element={<CampaignCategory />}
              />
              <Route
                path={`/select-template/:id`}
                element={<SelectTemplate />}
              />
              <Route path="/history-campaign" element={<HistoryCampaign />} />
              <Route path="/terms" element={<TermsCondition />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/members-list" element={<MembersList />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/scheduled-meeting" element={<ScheduledMeeting />} />
              <Route path="/edit-member-access" element={<EditAccess />} />
              <Route path="/drafts" element={<Drafts/>} />
              <Route path="/transactions" element={<TransactionsHistory/>} />
            </Routes>
            </div>
        </DataProvider>
      </BrowserRouter>
    </>
  )
}

export default App;
