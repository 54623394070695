import React, { useContext, useEffect, useMemo, useRef } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { AiOutlineRight } from 'react-icons/ai';
import { useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DataContext } from "../../../context/data/DataState";
import Files from 'react-files';
import Parser from 'html-react-parser';
import { useLocation } from "react-router-dom";
import SucessTempModal from "./TemplateModal/SucessTemplateModal";
import ImageResize from 'quill-image-resize-module-react';
import AuroLogo from "../../Logo/AuroLogo";

function    UpdateTemplates() {
    const { state } = useLocation();
    // console.log(state?.item, state?.item?._id, state?.item?.templateName)
    const { columnHeader, groupListState, templates, setTemplates, groupList, getColumn, smsTemplateCreate, whatappTemplatesCreate, updateEmailTemplates, updateWhatsappTemplates, updateIvrTempPopup } = useContext(DataContext)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedValueOpen, setSelectedValueOpen] = useState(false);
    const [stateBody, setStateBody] = useState(state?.item?.content);
    const quillRef = useRef();
    Quill.register('modules/imageResize', ImageResize);
    const getdata = (id) => {
        getColumn(id)
    }
    const [emailUpdateState, setEmailUpdateState] = useState({
        emailTemplateName: state?.item?.templateName,
        emailTemplateSubject: state?.item?.subject,
        // ivrTemplateContent: state.item.content
      });
    const SelectedValueOpen = (id, groupname,) => {
        setTemplates({ ...templates, groupname: groupname })
        getdata(id)
        setSelectedValueOpen(true)
    }
    const handleSelectedValue = (item) => {
        setTemplates({ ...templates, subgroupdata: item })
        console.log(item)
        setDropdownOpen(false);
        setSelectedValueOpen(false)
    };
    const sendTemplates = () => {
        console.log(state.item.templateName)
        // if (state.item.templateName == 'Email') {
            setTemplates({ ...templates, subject: "" })
            setStateBody("")
            updateEmailTemplates(emailUpdateState.emailTemplateSubject, stateBody, state.item._id,emailUpdateState.emailTemplateName)
        // } else if (state.item.templateName == 'whatsapp') {
        //     setTemplates({ ...templates, subject: "" })
        //     setStateBody("")
        //     updateWhatsappTemplates(templates.subject, stateBody, state.item._id,templates.templateName)
        // }
    }
    function previewFile(element) {
        var file = element;
        var reader = new FileReader();
        reader.onloadend = function () {
            //console.log('RESULT', reader.result)
        }
        reader.readAsDataURL(file);
        return imageHandler(reader.result)
    }
    const imageHandler = (e) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
            const file = input.files[0];
            if (/^image\//.test(file.type)) {
                const formData = new FormData();
                formData.append("image", file);
                const res = await previewFile(file); // upload data into server or aws or cloudinary
                //console.log(res)
                //const url = res;
                //console.log(url)
                editor.insertEmbed(editor.getSelection(), "image", e);
            } else {
                alert('You could only upload images.');
            }
        };
    }
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['image', "link",],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ]
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
          }
    }), [])
    useEffect(() => {
        setTemplates({ ...templates, subgroupdata: "" })
    }, [templates.groupname])

    useEffect(() => {
        groupList();
    }, [])
    useEffect(() => {
        console.log(templates.subgroupdata, templates.groupname)
        console.log(templates)
        if (templates.groupname != "" && templates.subgroupdata != "") {
            // setStateBody((prevState) => `${prevState}${templates.groupname}/${templates.subgroupdata}`)
            setStateBody((prevState) => `${prevState}{'${templates.subgroupdata}'}`)
        }
    }, [templates.subgroupdata])
    return (
        <>
            <div className="w-full flex bg-white h-screen">
                <div className=" bg-theme-color h-screen">
                    
                </div>
                <div className="w-full mb-12 h-screen overflow-x-scroll">
                    {
                        updateIvrTempPopup === true ? <SucessTempModal></SucessTempModal> : <></>
                    }
                    <div className="pl-4 4xl:pr-6 pr-4 mt-4">
                    <h1 className=" 4xl:text-[24px] 4xl:mb-[14px] text-[18px] font-medium pb-1">Template name</h1>
                    <div className="flex justify-between">
                        <div className=" flex xs:flex-col sm:flex-col md:flex-col items-center lg:gap-4">
                        <input onChange={(e) => setEmailUpdateState({ ...emailUpdateState, emailTemplateName: e.target.value })} value={emailUpdateState.emailTemplateName} type='text' placeholder="Type name here" className="lg:w-full placeholder:text-left placeholder:text-[14px] 4xl:placeholder:text-[18px] md:w-3/5 sm:3/5 xs:w-3/5  p-[.4rem_1rem] rounded-md border-black border flex justify-center font-normal items-center bg-[#FAFAFA] text-[14px] 4xl:text-[18px]" />
                        <div className="flex">
                            {/* <h1 className="flex justify-center font-semibold text-[18px] 4xl:text-[24px] items-center rounded-lg px-[16px] py-[8px] border border-[black]">{state.item.templateName}</h1> */}
                            <div className="ml-4 lg:ml-0" onMouseLeave={() => { setDropdownOpen(false); setSelectedValueOpen(false) }}>
                                <button className="inline-flex justify-center items-center gap-x-1.5 bg-theme-color p-[.55rem_1.2rem] text-[12px] 4xl:text-[16px] font-normal rounded-md text-white" onMouseEnter={() => setDropdownOpen(true)}>
                                    Insert system variable
                                </button>
                                {
                                    dropdownOpen && (
                                        <>
                                            <div className="absolute z-10 w-[215px] origin-top-right 3xl:w-[170px] mt-[0.5px] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                                                {groupListState.map((option) => (
                                                    <div key={option._id} className="text-[18px] pb-[10px] pl-[10px]">
                                                        <div className="flex cursor-pointer items-center justify-between px-2
                                                        " onClick={(e) => { SelectedValueOpen(option._id, option.groupName) }}>
                                                            <span className="">{option.groupName}</span> <span className="text-black"><AiOutlineRight /> </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    selectedValueOpen === true ? <> <div className="absolute z-10 ml-[215px] 3xl:ml-[170px] w-[171px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                                        {
                                            columnHeader.map((item) => (
                                                <div className="text-[18px] pb-[10px] pl-[10px]">
                                                    <div onClick={() => handleSelectedValue(item.columnName)}>
                                                        {item.columnName}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div></> : <></>
                                }
                            </div>
                            <div className="ml-4">
                                <button className="inline-flex justify-center items-center gap-x-1.5 rounded-md bg-theme-color p-[.55rem_1.2rem] text-[12px] 4xl:text-[16px] font-normal  text-white">
                                    Insert user variable
                                </button>
                            </div>
                            </div>
                            </div>
                            <AuroLogo/>
                            </div>
                  
                        <div className="w-full border-[#161616] h-[85vh] border rounded-lg mt-3 shadow-edit-shadow">
                        <div className="h-full flex flex-col">
                            <div className="px-[10px] pt-[20px]">
                                <div className="border-b">
                                    <h1 className="text-[25px] font-semibold">Subject</h1>
                                    <textarea type="text" onChange={(e) => setEmailUpdateState({ ...emailUpdateState, emailTemplateSubject: e.target.value })} value={emailUpdateState.emailTemplateSubject} name="subject" rows="2" cols="50" placeholder="What's the subject line for this template" className="w-full text-[16px] 4xl:text-[22px] mt-2 font-normal italic outline-0"></textarea>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className="px-[10px] h-full relative">
                                <ReactQuill
                                    value={stateBody}
                                    onChange={(e) => { setStateBody(e) }}
                                    modules={modules}
                                    ref={quillRef}
                                />
                            </div>
                            {
                                templates.image.length > 0
                                    ? <div className='files-gallery'>
                                        {templates.image.map((file, index) => (<>
                                            <div key={index}>
                                                <img src={`${file.preview.url}`} alt="Girl in a jacket" width="100" height="100" />
                                            </div>
                                        </>
                                        )
                                        )}
                                    </div> : ""}
                            <div className="flex mt-auto pb-4">

                                <button onClick={() => { sendTemplates() }}  type='button' className="ml-[10px] border-1 border-theme-color p-[.45rem_2rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg ... " >Update Template</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateTemplates