import React from "react";
import aurScholar from '../../assets/images/auroLogo.svg';

const AuroLogo = ()=>{
  return (
    <>
    <img className="w-[90px] 4xl:w-[120px] 3xl:w-[120px]" src={aurScholar} ></img>
    </>
  )
}
export default AuroLogo;