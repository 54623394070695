import React from "react";
import { useState, Fragment, useRef } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import GroupHeader from "./GroupHeader";
import bin from '../../../assets/images/bin.svg';
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import { Dialog, Transition } from '@headlessui/react';
import danger from '../../../assets/images/danger.svg';
import GroupSucessModal from "../../Modal/GroupSucessmodal";
import ApiErrorModal from "../../Modal/ApiErrorModal";
import ColumnSucessModal from "../../Modal/ColumnSucessModal";

const CreateGroup = () => {
  const { AddGroup, createColumn, showGroupModal, setShowGroupModal, showColumnModal, dataType,
    setshowColumnModal, apiError, setApiError, apiColumnError,
    setApiColumnError } = useContext(DataContext)
  const [groupState, setGroupState] = useState({
    group: ''
  });
  const [columnList, setColumnList] = useState(
    [{
      columnName: "",
      dataType: "",
      validation: "",
      maximumLength: "",
      required: "false",
      unique: "false",
    }]
  );
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  };

  const handleAddColumn = () => {
    setValidationTypeState()
    setColumnList([...columnList, {
      columnName: "",
      dataType: "",
      validation: "",
      maximumLength: "",
      required: "false",
      unique: "false"
    }])
  };

  const [columnDeleteIndex, setColumnDeleteIndex] = useState();
  const handleRemoveColumn = () => {
    const list = [...columnList]
    list.splice(columnDeleteIndex, 1)
    setColumnList(list)
  };

  const buttonDisabled = (groupState.group !== '');

  const handleCreateGroup = () => {
    setValidationTypeState()
    setShowGroupModal(true)
    AddGroup(groupState.group, setGroupState);
  };

  const handleSaveColumn = () => {
    setTimeout(() => (
      createColumn(columnList)
    ), 1000)

  };

  // const handleCreatingColumn = (e, index) => {
  //   const { name, value, checked } = e.target
  //   console.log(name , value)
  //   const list = [...columnList]
  //   if (name === 'required' || name === 'unique') {
  //     list[index][name] = checked;
  //   }
  //   else {
  //     list[index][name] = value;
  //   }
  //   setColumnList(list)
  // };

  const handleCreatingColumn = (e, index) => {
    const { name, value, checked } = e.target
    const list = [...columnList]

    if (name === 'required' || name === 'unique') {
      list[index][name] = checked;
    }
    else {
      list[index][name] = value;
      if (name === 'dataType') {
        list[index].validation = '';
      }
    }

    setColumnList(list)
  };


  const groupInfo = JSON.parse(localStorage.getItem('groupInfo'));
  const [openPopDelete, setOpenPopDelete] = useState(true);
  const [columnLoader, setColumnLoader] = useState(false);
  const cancelButtonRef = useRef(null);
  const deleteButtonRef = useRef(null);

  // const handleDataType = (e) => {
  //   dataType(e.target.value)
  // };

  const [validationTypeState, setValidationTypeState] = useState()

  const handleChecking = (e, index) => {
    console.log(columnList[index].dataType, 'dataType')
    if (columnList[index].dataType === 'string') {
      setValidationTypeState(['string', 'email'])
    }
    else if (columnList[index].dataType === 'number') {
      setValidationTypeState(['number', 'mobilenumber'])
    }
    else if(columnList[index].dataType === 'dob'){
      setValidationTypeState(['dob'])
    }
    else if (columnList[index].dataType === "") {
      setValidationTypeState()
    }
  };
  console.log(validationTypeState, "validationTypeState")

  return (
    <>
      {
        showColumnModal ? <ColumnSucessModal></ColumnSucessModal> : <></>
      }
      {
        showGroupModal ? <GroupSucessModal></GroupSucessModal> : <></>
      }
      {
        apiColumnError ? <ApiErrorModal></ApiErrorModal> : <></>
      }
      {
        columnLoader ? <>  <Transition.Root show={openPopDelete} as={Fragment} >
          <Dialog as="div" className="relative z-10" initialFocus={deleteButtonRef} onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative flex flex-col transform w-[600px] h-[400px] rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-2 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="m-auto w-56 flex h-28 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                          <img className="w-[120px]" src={danger} ></img>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <div className="mt-6">
                            <p className="text-[18px] 4xl:text-[24px] text-gray-500">
                              Are you sure, you want to delete this column?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => { setOpenPopDelete(true); setColumnLoader(false); handleRemoveColumn() }}
                        ref={deleteButtonRef}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => { setOpenPopDelete(true); setColumnLoader(false); }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root> </> : <></>
      }
      <div className="w-full flex bg-white h-screen">
        <div className="bg-theme-color h-screen">

        </div>
        <div className="w-full mb-12 h-screen overflow-y-scroll">
          <GroupHeader />
          <div className="px-4">
            {
              groupInfo?.userId === undefined ? <>
                <div className="mt-4 pb-2 4xl:text-[28px] lg:text-[22px] xl:text-[22px] 2xl:text-[24px]">
                  Group Name
                </div>
                <div className="flex gap-4">
                  <input type="text" placeholder="Enter group name" name="group" value={groupState.group} onChange={(e) => setGroupState({ ...groupState, group: e.target.value })} className="3xl:h-[46px] lg:w-1/3 lg:text-[12px]   xl:text-[12px] xl:w-1/3 md:w-2/3 sm:w-2/3 xs:w-2/3 w-1/4 rounded-lg 4xl:h-[55px] 2xl:text-[14px] border border-slate-300 rouded-lg placeholder-slate-400 xs:placeholder:text-[10px] sm:placeholder:text-[10px] md:placeholder:text-[10px] lg:p-[.3rem_1rem] md:p-[.2rem_0.75rem] sm:p-[.2rem_0.75rem] xs:p-[.2rem_0.75rem] p-[.3rem_1rem]" />
                  <button type='button' disabled={!buttonDisabled} className="text-[10px] xl:text-[12px] 2xl:text-[14px] lg:text-[12px] 3xl:text-[17px] 4xl:text-[24px] font-normal p-[.45rem_2rem] md:p-[.2rem_0.75rem] sm:p-[.2rem_0.75rem] xs:p-[.1rem_0.3rem] border-1 border-theme-color bg-theme-color text-white disabled:opacity-40 rounded-lg ... " onClick={() => { handleCreateGroup() }}>
                    Create Group
                  </button>
                </div>
              </> :
                <div>
                  <h1 className="text-[22px] 4xl:text-[28px] lg:pt-4 xl:pt-6 pt-8 text-theme-color ">{groupInfo?.groupName} <small className="text-[12px] text-black">(Group Name)</small></h1>

                </div>
            }
          </div>

          <div className={`px-4 mt-3 ml ${groupInfo?.userId === undefined ? 'opacity-20' : 'opacity-100'}`}>
            <div className="text-[16px] 4xl:text-[28px] lg:text-[18px] xl:text-[18px] 2xl:text-[22px] mt-6 mb-2">
              Column Names
            </div>
            <div>
              <form className="pb-4">
                {
                  columnList?.map((item, index) => (
                    <>
                      <div key={index}>
                        <div className="flex items-center xs:flex-wrap sm:flex-wrap md:flex-wrap gap-1 4xl:gap-4">
                          <input type="text" placeholder="Enter a Column Name" name="columnName" value={columnList[index].columnName} className="w-[20%] lg:h-[32px] md:h-[32px] sm:h-[28px] xs:h-[25px]  xl:h-[32px] h-[41px] lg:w-4/12 xl:w-3/12 xs:w-5/12 sm:w-5/12 lg:text-[11px] xl:text-[11px]  text-sm border border-slate-300 placeholder-slate-400  lg:p-2 xl:p-2 p-2  xs:placeholder:text-[10px] sm:placeholder:text-[10px] md:placeholder:text-[10px]" onChange={(e) => handleCreatingColumn(e, index)} />
                          <div className="flex items-center ml-2 lg:w-5/12 xl:3/12">
                            <label for="dataColumnList"></label>
                            <select name="dataType" id="dataType" className="xs:text-[10px] sm:text-[10px] md:text-[10px] lg:text-[11px] bg-white xl:text-[11px] h-[41px] md:h-[32px] sm:h-[28px] xs:h-[25px] lg:h-[32px] xl:h-[32px] w-full border pl-1" onChange={(e) => { handleCreatingColumn(e, index) }}>
                              <option value="Choose data type"  >Choose data type</option>
                              <option value="string">String</option>
                              <option value="number">Numeric</option>
                              <option value="dob">DOB</option>
                            </select>
                          </div>

                          <div className="flex items-center ml-2 lg:w-2/12 xl:2/12">
                            <label for="validateColumnList"></label>
                            <select name="validation" id="validation" value={columnList[index].validation} className="w-[160px] xs:text-[10px] sm:text-[10px] md:text-[10px]  lg:w-full bg-white xl:w-full lg:h-[32px] xl:h-[32px] md:h-[32px] sm:h-[28px] xs:h-[25px] lg:text-[11px] xl:text-[11px] h-[41px] border pl-1" onClick={(e) => { handleChecking(e, index) }}
                              onChange={(e) => { handleCreatingColumn(e, index) }}>
                              <option value="Validate field " className="xs:text-[12px] sm:text-[12px] md:text-[12px]" >
                                {columnList[index].validation === '' ? <>Validate Field</> :
                                  columnList[index].validation === 'string' ? 'Name' : columnList[index].validation}
                              </option>
                              {
                                validationTypeState === undefined ?
                                  <option className="xs:text-[12px] sm:text-[12px] md:text-[12px]">Choose your data Type first</option>
                                  :
                                  validationTypeState?.map((item) => (
                                    <option value={item}>{item === 'string' ? 'Name' : item}</option>
                                  ))
                              }
                            </select>
                          </div>

                          <input type="text" placeholder="Enter maximum length" value={columnList[index].maximumLength} name="maximumLength" className="ml-2 lg:h-[32px] xl:h-[32px] md:h-[32px] sm:h-[28px] xs:h-[25px] lg:w-3/12 xl:w-3/12 xs:w-5/12 sm:w-5/12 lg:text-[11px] xl:text-[11px] text-sm w-[20%] h-[35px] border
               border-slate-300 placeholder-slate-400 p-[8px] xs:placeholder:text-[10px] sm:placeholder:text-[10px] md:placeholder:text-[10px]" onChange={(e) => handleCreatingColumn(e, index)} />
                          <div className="ml-4 lg:ml-2 xl:ml-2">

                            <small className="text-theme-color lg:text-[11px] xl:text-[11px]">
                              Required
                            </small>
                            <div className=" flex gap-1">
                              <input type="checkbox" id="required" name="required" className="lg:h-[10px] xl:h-[10px] cursor-pointer" value={columnList[index].required} onChange={(e) => handleCreatingColumn(e, index)} /> <small className="lg:text-[8px] xl:text-[8px]">
                                Yes
                              </small>

                              {/* <input type="checkbox" id="required" name="required" value="false" onChange={(e) => handleCreatingColumn(e, index)} /> <small>
                                No
                              </small> */}
                            </div>
                          </div>
                          <div className="ml-4 lg:ml-2 xl:ml-2">
                            <small className="text-theme-color lg:text-[11px] xl:text-[11px]">
                              Unique
                            </small>
                            <div className="flex gap-1">
                              <input type="checkbox" id="unique" name="unique" className="lg:h-[10px] xl:h-[10px] cursor-pointer" value={columnList[index].unique} onChange={(e) => handleCreatingColumn(e, index)} /> <small className="lg:text-[8px] xl:text-[8px]">
                                Yes
                              </small>

                              {/* <input type="checkbox" id="unique" name="unique" value='false' onChange={(e) => handleCreatingColumn(e, index)} /> <small>
                                No
                              </small> */}
                            </div>
                          </div>
                          {
                            columnList.length > 1 && (<img src={bin} className="cursor-pointer w-[24px] ml-[15px] xl:ml-4 lg:ml-4" onClick={() => { setColumnLoader(true); setColumnDeleteIndex(index) }}></img>)
                          }
                        </div>

                        <br></br>
                      </div>
                      {
                        columnList.length - 1 === index && (
                          <button type='button' className="md:p-[.4rem_0.75rem] sm:p-[.4rem_0.75rem] xs:p-[.4rem_0.75rem] mt-6 p-[.45rem_2.4rem] lg:px-8 xl:px-8 text-[15px] 3xl:text-[17px] 4xl:text-[24px] font-normal xs:text-[10px] sm:text-[10px] md:text-[10px] bg-white text-theme-color border-1 border-theme-color rounded-lg ... " onClick={() => handleAddColumn()}>
                            Add column
                          </button>)
                      }
                    </>
                  ))
                }
                <button type='button' className="md:p-[.4rem_0.75rem] sm:p-[.4rem_0.75rem] xs:p-[.4rem_0.75rem] ml-4 text-[15px] xs:text-[10px] sm:text-[10px] md:text-[10px] 3xl:text-[17px] 4xl:text-[24px] font-normal p-[.45rem_2.4rem] bg-theme-color text-white rounded-lg ... " onClick={() => { handleSaveColumn() }}>
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreateGroup;