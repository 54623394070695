import React, { useContext, useState } from "react";
import back from '../../assets/images/back.svg';
import { useNavigate, Link } from "react-router-dom";
import businessicon from '../../assets/images/buisnessicon.svg';
import phoneicon from '../../assets/images/phoneicon.svg';
import swal from "sweetalert";
import { DataContext } from "../../context/data/DataState";
const BussinessInfo = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [gstError, setGstError] = useState(false);
  const { businessInfo, setBusinessInfo } = useContext(DataContext)
  const buttonDisabled = (businessInfo.businessName !== '' && businessInfo.authperson !== '' && businessInfo.phoneNumber !== '');

  console.log(businessInfo , "businessInfo")
  const validationGst = (businessInfogst) => {
    var gstReg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    if (businessInfogst.match(gstReg)) {
      return setGstError(false)
    }
    else if (businessInfogst == '') {
      return setGstError(false)
    }
    else {
      return setGstError(true)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (businessInfo.businessName === '' || businessInfo.authperson === '' || businessInfo.phoneNumber === '') {
      swal('please enter all details')
    }
    else if (error === true) {
      // swal('please enter valid Phone Number')
      setError(true)

    }
    else if (gstError === true) {
      setGstError(true)
    }
    else {
      navigate("/business-address")
    }

  };

  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setError(false)
    }
    else if (phoneData == '') {
      return setError(false)
    }
    else {
      return setError(true)

    }
  }
  return (
    <div className="grid grid-cols-2 sm:grid-cols-1  xs:grid-cols-1   w-full lg:gap-24 lg:place-content-start  place-content-center gap-0 max-h-screen">
      
      <div className="grid lg:justify-center sm:justify-center  xs:justify-center justify-start  w-full m-auto">
        <div className="flex justify-center items-center mx-0 3xl:mx-8 4xl:mx-16 ">
          <div className="text-center flex flex-col items-start lg:items-center">
          <div className="back">
              {/* <Link to='/info' ><img src={back} className='w-[23px] h-[20px] absolute lg:top-[25%] 4xl:left-[7%] 3xl:left-[6%] xl:left-[3%] lg:left-[18%] left-[5%] top-[15%]'></img> </Link> */}
            </div>
            <p className="text-theme-color lg:pl-0  lg:text-[28px] xl:pl-8 xl:text-[22px] mt-4 3xl:ml-12 4xl:ml-0 lg:ml-7 md:ml-[1.5rem]  sm:ml-[-40px] font-bold text-[24px] 3xl:text-[27px] 4xl:text-[39px]  ">
              Tell us a bit more about you/business
            </p>
            <form onSubmit={(e) => handleSubmit(e)} className="w-[85%] xl:w-[90%]">
              <div className="relative mt-1 flex w-4/5 m-auto">
                <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                  <span className="text-gray-500 sm:text-sm"><img src={businessicon} width='17px' /></span>
                </div>
                <input type="text" placeholder="Business Name *" className="mt-4 block w-4/5 m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[35px] 3xl:h-[41px] 4xl:h-[55px]  
                         "  value={businessInfo.businessName} onChange={(e) => setBusinessInfo({ ...businessInfo, businessName: e.target.value })} />
              </div>
              <input type="text" placeholder="GST No. (optional)" minLength={12} maxLength={15}
                className="mt-4 block w-4/5 m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400 3xl:h-[41px] 4xl:h-[55px]
                         "value={businessInfo.gst} onChange={(e) => setBusinessInfo({ ...businessInfo, gst: e.target.value })} onKeyUp={() => validationGst(businessInfo.gst)} />
              <div className="text-left w-4/5 d-flex m-auto">{gstError ? <span className="text-red-600 text-[10px] ">Please enter a valid GST number (case senstive).</span> : <></>}</div>

              <input type="text" placeholder="Authorised Person *" className="mt-4 block w-4/5 m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400 3xl:h-[41px] 4xl:h-[55px]
                         "  value={businessInfo.authperson} onChange={(e) => setBusinessInfo({ ...businessInfo, authperson: e.target.value })} />

              {/* <input type="text" placeholder="Field 4" className="mt-4 block w-4/5 m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        invalid:border-pink-500 invalid:text-pink-600
                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                         "value={state.field4} onChange={(e) => setBusinessInfo({ ...state, field4: e.target.value })} /> */}

              <p className=" 3xl:text-[13px] text-[12px] text-left 4xl:text-[18px] w-4/5 mx-auto py-3 font-normal text-[#707070]">You can always change this later in your account settings.</p>
              <div className="relative flex w-4/5 m-auto">
                <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2">
                  <span className="text-gray-500 sm:text-sm"><img src={phoneicon} width='15px' /></span>
                </div>
                <input type="text" placeholder="Phone Number *" className="block w-4/5  3xl:h-[41px] 4xl:h-[55px] m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                        disabled:bg-slate-50 disabled:text-theme-color disabled:border-slate-200 disabled:shadow-none
                        invalid:border-red-500 invalid:text-red-500 flex-auto pl-[35px]
                        focus:invalid:border-red-500 focus:invalid:ring-red-500
                         " maxLength={10} pattern="[0-9]{10}" onKeyUp={() => validatePhoneno(businessInfo.phoneNumber)} value={businessInfo.phoneNumber} onChange={(e) => setBusinessInfo({ ...businessInfo, phoneNumber: e.target.value })} />
              </div>
              <div className="text-left w-4/5 d-flex m-auto">
                {error ? <span className="text-red-600 text-[10px]">Please enter a valid 10 digits Number.</span> : <></>}</div>
              {/* <div className="w-4/5 m-auto flex justify-between">

                <label className="3xl:text-[13px] flex items-baseline gap-1 mt-2 4xl:text-[18px] text-[12px] text-[#707070] text-left ">
                  <input type="checkbox" required className="cursor-pointer checked:bg-theme-color ..." value={businessInfo.checkbox} onChange={(e) => setBusinessInfo({ ...businessInfo, checkbox: !businessInfo.checkbox })} /><span className=""> By creating an account, you agree to our <a href="/terms" target="_blank"  className="text-theme-color cursor-pointer font-bold ..." >Terms and Conditions.</a></span> </label>
              </div> */}
              <button type='submit' disabled={!buttonDisabled} className="bg-theme-color text-white 3xl:rounded-2xl 4xl:rouned-2xl mt-6 4xl:mt-9 text-[14px] xl:rounded-md 3xl:text-[18px] rounded-md 4xl:text-[23px] font-bold p-[.7rem_3rem] 3xl:p-[.7rem_7rem] disabled:opacity-40  4xl:p-[14px_113px] ...">Next</button>
              <br />
            </form>
          </div>
        </div>
      </div>
      <div className=" h-screen sm:hidden xs:hidden">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full"></div>
      </div>
    </div>
  )
}
export default BussinessInfo;