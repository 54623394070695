import React from "react";
import { useNavigate } from "react-router-dom";

const GroupNew = () => {
  const navigate = useNavigate()
  const handleCreateGroup = () => {
    localStorage.removeItem('groupInfo');
    navigate('/group-creation')
  };
  let userData = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <>

      <div className="p-6 md:p-4 mt-7 pr-6 before:absolute before:content-[''] before:h-[1px] sm:before:w-[87%] xs:before:w-[85%] sm:before:top-[-17px] xs:before:mt-[8px] before:w-[93%] 4xl:before:w-[95%] before:bg-[rgba(22,_22,_22,_0.6)] before:top-[-6%] relative">
        <div className="border rounded-md">
          <div className="w-[67%] sm:w-[100%]  xs:w-[100%] m-auto  flex flex-col pb-4 ">
            <div className="p-8  xs:pt-1">
              <h1 className="text-[25px] 4xl:text-[32px] md:text-[25px] sm:text-[20px] xs:text-[19px] font-semibold text-center pb-4 4xl:pb-8 md:pb-2 sm:pb-1">
                How do groups work?
              </h1>
              <p className="text-center text-[16px]  text-[rgba(22,_22,_22,_0.6)] 4xl:text-[24px] leading-7 4xl:leading-10 ">
                Grouping allows you to categorize users based on their unique identity and
                preferences. <br /> Grouping users enables you to easily manage and organize your user base, making it easier to track their behavior and preferences.
              </p>
            </div>
            {

            }
            <div className="text-center pt-4 4xl:pt-6 md:pt-1 sm:pt-1 xs:pt-1 ">
              {
                userData.userType === 'USER' ? <button
                  type='button'
                  className='text-[22px] sm:text-[15px] xs:text-[12px] p-[9px_39px]  4xl:p-[12px_64px] sm:p-[4px_25px] 4xl:text-[32px] md:text-[20px]  bg-theme-color text-white rounded-lg ...'
                  onClick={() => { handleCreateGroup() }}
                >
                  Create Group
                </button> :
                  userData.userType === 'SUBUSER' && userData.permission.createGroup.create === true ?
                    <button
                      type='button'
                      className='text-[22px] sm:text-[15px] xs:text-[12px] p-[9px_39px]  4xl:p-[12px_64px] sm:p-[4px_25px] 4xl:text-[32px] md:text-[20px]  bg-theme-color text-white rounded-lg ...'
                      onClick={() => { handleCreateGroup() }}
                    >
                      Create Group
                    </button> :  <button
                      type='button'
                      className='text-[12px] sm:text-[15px] xs:text-[12px] p-[9px_39px]  4xl:p-[12px_64px] sm:p-[4px_25px] 4xl:text-[32px] md:text-[20px]  bg-theme-color text-white rounded-lg ...'
                    >
                      You don't have permission to create Group. Ask Admin for permission
                    </button>
              }

              {/* {userData.userType === 'USER' && (
                <button
                  type='button'
                  className='text-[22px] sm:text-[15px] xs:text-[12px] p-[9px_39px]  4xl:p-[12px_64px] sm:p-[4px_25px] 4xl:text-[32px] md:text-[20px]  bg-theme-color text-white rounded-lg ...'
                  onClick={() => { handleCreateGroup() }}
                >
                  Create Group
                </button>
              )}

              {userData.userType === 'SUBUSER' && userData.permission.createGroup.create === true && (
                <button
                  type='button'
                  className='text-[22px] sm:text-[15px] xs:text-[12px] p-[9px_39px]  4xl:p-[12px_64px] sm:p-[4px_25px] 4xl:text-[32px] md:text-[20px]  bg-theme-color text-white rounded-lg ...'
                  onClick={() => { handleCreateGroup() }}
                >
                  Create Group
                </button>
              )} */}


            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default GroupNew;