import React from "react";
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useContext } from "react";
import { DataContext } from "../../context/data/DataState";
import sucess from '../../assets/images/correct.svg';
import { useNavigate, useParams } from "react-router-dom";

const EditDataPopup = () => {
  const { editPopup,
    setEditPopUp, editRowState, setEditRowState, UpdateAudienceUser, checkUserUpdate,
    setCheckUSerUpdate, UpdateNotEnterDb, columnHeader } = useContext(DataContext);
  const [openPopGroup, setOpenPopGroup] = useState(true);

  const { id } = useParams();

  const [updatedData, setUpdatedData] = useState(editRowState);


  const handleInputChange = (key, value) => {
    let type = null;
    for (let i = 0; i < columnHeader.length; i++) {
      if (columnHeader[i].columnName == key) {
        type = columnHeader[i].validation;
        // break; // exit loop once type is found
      }
    }
    if (type === 'number') {
      const parsedValue = parseInt(value);
      if (!isNaN(parsedValue)) {
        setUpdatedData((prevState) => ({
          ...prevState,
          [key]: parsedValue
        }));
      } else {
        setUpdatedData((prevState) => ({
          ...prevState,
          [key]: ''
        }));
      }
    } else if (type === 'mobilenumber') {
      const parsedValue = parseInt(value);
      if (!isNaN(parsedValue)) {
        setUpdatedData((prevState) => ({
          ...prevState,
          [key]: parsedValue
        }));
      } else {
        setUpdatedData((prevState) => ({
          ...prevState,
          [key]: ''
        }));
      }
    } else {
      setUpdatedData((prevState) => ({
        ...prevState,
        [key]: value
      }));
    }
  };


  // const handleUpdateInputChange = (key, value) => {
  //   // this function working for Successfull data table row update
  //   let type = null;
  //   for (let i = 0; i < columnHeader.length; i++) {
  //     if (columnHeader[i].columnName === key) {
  //       type = columnHeader[i].validation;
  //       break; // exit loop once type is found
  //     }
  //   }
  //   if (type === "string") {
  //     // if the value contains only alphabetic characters
  //     if (/^[a-zA-Z]*$/.test(value)) {
  //       setUpdatedData((prevState) => ({
  //         ...prevState,
  //         [key]: value
  //       }));
  //     }
  //   } else if (type === "number") {
  //     // if the value contains only numeric characters
  //     if (/^\d+$/.test(value)) {
  //       setUpdatedData((prevState) => ({
  //         ...prevState,
  //         [key]: value
  //       }));
  //     }
  //   } else if (type === "email") {
  //     // if the value is a valid email
  //     if (/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/.test(value)) {
  //       setUpdatedData((prevState) => ({
  //         ...prevState,
  //         [key]: value
  //       }));
  //     }
  //   }
  //   else if (type === "mobilenumber") {
  //     // if the value is a valid phone number
  //     if (/^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value)) {
  //       setUpdatedData((prevState) => ({
  //         ...prevState,
  //         [key]: value
  //       }));
  //     }
  //   } else {
  //     //  when type is not specified, allow any input
  //     setUpdatedData((prevState) => ({
  //       ...prevState,
  //       [key]: value
  //     }));
  //   }
  // };


  const handleUpdateInputChange = (key, value) => {
    let type = null;
    for (let i = 0; i < columnHeader.length; i++) {
      if (columnHeader[i].columnName === key) {
        type = columnHeader[i].validation;
        break;
      }
    }
    if (type === "string") {
      setUpdatedData((prevState) => ({
        ...prevState,
        [key]: value.replace(/[^a-zA-Z ]/g, "") // only allow alphabetic characters and spaces
      }));
    } else if (type === "number") {
      setUpdatedData((prevState) => ({
        ...prevState,
        [key]: value.replace(/[^0-9]/g, "") // only allow numeric characters
      }));
    } else if (type === "email") {
      setUpdatedData((prevState) => ({
        ...prevState,
        [key]: value // allow any input for email
      }));
    } else if (type === "mobilenumber") {
      setUpdatedData((prevState) => ({
        ...prevState,
        [key]: value.replace(/[^0-9]/g, "").slice(0, 10) // allow only digits and take the first 10 characters
      }));
    } else {
      setUpdatedData((prevState) => ({
        ...prevState,
        [key]: value // allow any input when type is not specified
      }));
    }
  };


  const handleUpdateRow = () => {
    {
      checkUserUpdate ? UpdateNotEnterDb(id, updatedData) : UpdateAudienceUser(id, updatedData);
    }
    setEditPopUp(false);
    setCheckUSerUpdate(false);
  }


  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden 3xl:w-[45%] 4xl:w-[45%] w-4/5 rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-2 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className=" text-start flex flex-col gap-3 py-6 px-2">
                          {Object.keys(editRowState).map((key) => (
                            key === 'audienceType' || key === 'groupId' || key === '_id' ? <></> :
                              <div key={key} className='flex'>
                                <div className="w-5/12">
                                  <label htmlFor={key}>{key}:</label>
                                </div>
                                <div className="w-8/12">
                                  <input
                                    id={key}
                                    type="text"
                                    value={updatedData[key]}
                                    onChange={key == "audienceType" ? <></> : key == "_id" ? <></> : key == "groupId" ? <></> :
                                      (e) => { checkUserUpdate ? handleInputChange(key, e.target.value) : handleUpdateInputChange(key, e.target.value) }}
                                    className={`border p-[.35rem_1rem] rounded-md w-full`}
                                    disabled={key == "audienceType" || key == "groupId" || key == "_id"}
                                  />
                                </div>
                              </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 pb-6 sm:flex sm:flex-row-reverse sm:px-6 flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-theme-color px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-theme-color sm:ml-3 sm:w-auto sm:text-sm mr-4"
                      onClick={() => { handleUpdateRow() }}
                    >
                      Update row
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-theme-color px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-theme-color sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => { setEditPopUp(false) }}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default EditDataPopup;