import { MdDeleteForever } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { DataContext } from "../../../context/data/DataState";
import Files from "react-files";
import Parser from "html-react-parser";
import { useLocation, useParams } from "react-router-dom";
// import SucessTempModal from "./TemplateModal/SucessTemplateModal";
import SideNavBar from "../SideNavBar/SideNavBar";
import { IoMdAttach } from "react-icons/io";
import caretBlue from "../../assets/images/caret blue.svg";
import Sendmail from "./CampaignModals/RecurringMail";
import AuroLogo from "../Logo/AuroLogo";

const SendMessage = () => {
  return (
    <>
      <div className="w-full flex bg-white h-screen">
        <span></span>
        <div className=" bg-theme-color h-screen">
          
        </div>
        <div className="w-full mb-12 h-screen overflow-x-scroll">
          <div className="pl-4 pr-12 mt-4">
            <div className=" flex">
            <div className="justify-end items-end flex w-full">
                <button className="inline-flex justify-center items-center mr-4 gap-x-1.5 bg-white p-[.5rem_2.2rem] text-[12px] 4xl:text-[16px] rounded-md text-theme-color border border-theme-color font-medium">
                  Use Template
                </button>
                <AuroLogo/>
              </div>
            </div>
            <div className="w-full border-[#161616] border rounded-lg mt-4 shadow-edit-shadow h-[85vh] flex flex-col">
              <div className="border-b py-2 mx-4 flex justify-between pb-0">
                <div className="">
                  <h1 className="3xl:text:18px 4xl:text-[25px] font-semibold">
                    From
                  </h1>
                  <textarea
                    type="text"
                    name="subject"
                    rows="1"
                    cols="50"
                    placeholder="Who is sending this campaign?"
                    className="w-full text-[14px] 2xl:text-base 3xl:text-base 4xl:text-[20px] mt-2 font- italic outline-0"
                  />
                </div>
                <div className="relative">
                  <button
                    id="dropdownDelayButton"
                    data-dropdown-toggle="dropdownDelay"
                    data-dropdown-delay="500"
                    data-dropdown-trigger="hover"
                    class="text-theme-color border border-theme-color bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center self-start inline-flex mt-3"
                    type="button"
                  >
                    {" "}
                    <svg
                      class="w-4 h-4"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                  <div
                    id="dropdownDelay"
                    class="z-10 hidden border absolute top-full right-0 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
                  >
                    <ul
                      class="bg-white py-1 3xl:py-2 4xl:py-2"
                      aria-labelledby="dropdownDelayButton"
                    >
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          aurobindo@yourmail.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          aurobindo@yourmail.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          aurobindo@yourmail.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          aurobindo@yourmail.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="border-b py-2 mx-4 flex justify-between pb-0">
                <div className="">
                  <h1 className="3xl:text:18px 4xl:text-[25px] font-semibold">
                    To
                  </h1>
                  <textarea
                    type="text"
                    name="subject"
                    rows="1"
                    cols="50"
                    placeholder="Who are you sending this template to?"
                    className="w-full text-[14px] 2xl:text-base 3xl:text-base 4xl:text-[20px] mt-2 font- italic outline-0"
                  />
                </div>
                <button className="bg-theme-color lg:text-[12px] lg:mt-[10px] xl:mt-[10px] mt-1 xl:text-[12px] whitespace-nowrap text self-start 3xl:mt-2 4xl:mt-6 text-base p-[.4rem_2rem] rounded-md text-white">
                  Add Audience
                </button>
              </div>
              <div>
              <textarea name="" id="" cols="30" rows="10" className="w-full p-4 focus-visible:outline-0" placeholder="Dear Name, Lorem ipsume some text"></textarea>
              </div>

              <div className="flex pb-4 mt-auto">
                <button
                  type="button"
                  className="inline-flex ml-5 justify-center items-center gap-x-1.5 bg-theme-color mt-6 text-[12px] 4xl:text-[16px] p-[.55rem_1.2rem] font-normal rounded-lg text-white"
                >
                  Send
                </button>
                <button 
                  type="button"
                  className="inline-flex ml-5 justify-center items-center gap-x-1.5 bg-theme-color mt-6 text-[12px] 4xl:text-[16px] p-[.55rem_1.2rem] font-normal rounded-lg text-white"
                >
                  Schedule
                </button>
              </div>
              <div className="flex"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMessage;
