import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../context/data/DataState";
import Filter from "../Audience/Filter/Filter";
import SideNavBar from "../SideNavBar/SideNavBar";
import AuroLogo from "../Logo/AuroLogo";

const SelectAudience = () => {
  const { id, specficGroupName } = useParams()
  // const [openPopGroup, setOpenPopGroup] = useState(true);
  const [selectedValue, setSelectedValue] = useState({
    selectedUser: [],
  });

  const { columnHeader, getColumn, getAudienceData, tableAudience, loaderAudience, sendEmailData, setSendEmailData, campaignCategoryState, setCampaignCategoryState } = useContext(DataContext);

  const navigate = useNavigate();




  // get table row data
  const tdData = () => {
    const column = Object.keys(tableAudience[0]);
    column.splice(0, 3);
    return tableAudience?.map((data) => {
      return (
        <tr className="">
          <td className="px-6 py-4 whitespace-nowrap 4xl:text-[18px] text-[12px] border-r border-b">
            <input className="cursor-pointer" type="checkbox" value={data} name='boxes' onClick={(e) => { handleChecked(e, data) }} />
          </td>
          {
            column?.map((v) => {
              return <td className="px-6 py-4 whitespace-nowrap 4xl:text-[18px] text-[12px] border-r border-b">{data[v]}</td>
            })
          }
        </tr>
      )
    })
  };

  const groupInfo = JSON.parse(localStorage.getItem('userInfo'));


  const handleAllChecked = (e) => {
    console.log(e.target.checked, 'checked')
    if (e.target.checked) {
      const all = document.getElementsByName('boxes');
      for (var a = 0; a < all.length; a++) {
        all[a].checked = true;
      }
      setSelectedValue({
        selectedUser: tableAudience
      })
    }
    else {
      const all = document.getElementsByName('boxes');
      for (var a = 0; a < all.length; a++) {
        all[a].checked = false;
      }
      setSelectedValue({
        selectedUser: []
      })
    }

  };

  const handleChecked = (e, data) => {
    const { checked } = e.target;
    const { selectedUser } = selectedValue;
    if (checked && data) {
      setSelectedValue({
        selectedUser: [...selectedUser, data],
      })
    }
    else {
      const removeItem = data;
      const updatedValue = {
        ...selectedValue,
        selectedUser: selectedValue.selectedUser.filter((item) => {
          return (
            item.audienceType !== removeItem.audienceType ||
            item._id !== removeItem._id ||
            item.groupId !== removeItem.groupId
          );
        })
      };
      setSelectedValue(updatedValue);
    }
  };


  console.log(selectedValue.selectedUser, 'selectedValue')




  const handleProceed = () => {
    setSendEmailData({
      ...sendEmailData,
      to: selectedValue.selectedUser,
      groupId: id 
    })
    // setSendEmailData({ ...sendEmailData, to: selectedValue.selectedUser });
    // // setCampaignCategoryState({ ...campaignCategoryState, groupId: id });
    navigate('/send-email')
  };

  useEffect(() => {
    getColumn(id);
    getAudienceData(id);
  }, []);

  return (
    <div className="w-full flex bg-white h-screen">
      <div className="bg-theme-color h-screen">
        
      </div>
      <div className="w-full mb-12 h-screen overflow-y-scroll">
        <div className="px-4 pt-4 4xl:pt-0">
          <div className="border-b-2 border-[rgba(22,_22,_22,_0.6)] flex justify-between 4xl:py-4 3xl:py-3 items-center">
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Audience</h1>
            <AuroLogo/>
            {/* <p className="text-[22px] 4xl:text-[30px] mb-[16px] font-medium text-[rgba(22,_22,_22,_0.6)] 4xl:mt-[8px]">{groupInfo?.name} </p>
            <div className="flex justify-between items-center">
              <p className="text-theme-color 4xl:text-[40px] font-semibold 3xl:text-[30px]">{specficGroupName} <small className="4xl:text-[12px] 3xl:text-[10px] text-black">(Group Name)</small></p>
            </div> */}

          </div>
        </div>
        <div className="flex" >
          <div className="mt-[16px] w-[20%] bg-[#F5F5F5]">
            <Filter />
          </div>
          <div className="inline-block align-middle mr-4  w-[75%] flex-1">
            <div className="p-0 mt-[16px] mx-[4px] border-1 border-black min-h-[63vh]">
              <div className="overflow-scroll min-w-[100px] min-h-[68vh] h-full">
                <table className="w-full divide-y divide-gray-200 dark:divide-gray-700" id="table-to-xls">
                  <thead className="" >
                    <tr className="bg-theme-color" >
                      <th scope="col" className="3xl:p-2 lg:p-2 xl:p-2 p-2 lg:text-[15px] xl:text-[15px] 4xl:p-[10px] text-left font-normal text-[14px] 4xl:text-[18px] text-white uppercase">
                        <input type="checkbox" className="cursor-pointer" name="boxes" onClick={(e) => handleAllChecked(e)} />
                        <span className="ml-2">All</span>
                      </th>
                      {
                        columnHeader?.map((header) => (<>
                          <th scope="col" className="3xl:p-2 lg:p-2 xl:p-2 p-2 lg:text-[15px] xl:text-[15px] 4xl:p-[10px] text-left font-normal text-[14px] 4xl:text-[18px] text-white uppercase">{header.columnName}</th>
                        </>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody className="border-b-1">

                    {loaderAudience ? <tr>
                      <td colSpan={columnHeader.length} className="">
                      </td>
                    </tr> : tableAudience?.length !== 0 ? tdData() :
                      <tr>
                        <td className="">
                        </td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4 w-4/5 ml-auto">
          <button className=" mr-4 p-[0.4rem_6rem] lg:text-[12px] xl:text-[12px] font-normal 4xl:text-[24px] text-[18px] border-1 border-theme-color bg-theme-color text-white rounded-lg ..." onClick={() => { handleProceed() }}>Proceed</button>
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}
export default SelectAudience;