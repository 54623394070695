import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { DataContext } from "../../../context/data/DataState";
import SideNavBar from "../../SideNavBar/SideNavBar";
import SucessTempModal from "./TemplateModal/SucessTemplateModal";
import ReactAudioPlayer from 'react-audio-player';

const IvrUpdateTemplate = () => {
  const location = useLocation();
  const { state } = location;
  const { ivrTemplateUpdate, updateIvrTempPopup, } = useContext(DataContext);
  const [ivrUpdateState, setIvrUpdateState] = useState({
    ivrSubject: state.item.subject,
    ivrAudio: state.item.audioFile,
    ivrTempName: state.item.templateName,
  });


  const handleAudio = (event) => {
    if (event.target.files && event.target.files[0]) {
      setIvrUpdateState({ ...ivrUpdateState, ivrAudio: event.target.files[0] });
    }
  };

  const handleIvrTemplate = () => {
    ivrTemplateUpdate(ivrUpdateState, state.item._id)
  };

  return (
    <div className="w-full flex bg-white h-screen">
      <div className="bg-theme-color h-screen">
        
      </div>
      {
        updateIvrTempPopup === true ? <SucessTempModal></SucessTempModal> : <></>
      }
      {

      }
      <div className="w-full mb-12 h-screen overflow-x-scroll">
        <div className="pl-4 pr-12 mt-6">
          <h1 className="text-[13px] 4xl:text-[18px] font-medium">Template name</h1>
          {/* <h1 className="flex min-w-[161px] justify-center font-semibold text-[18px] 4xl:text-[24px] items-center rounded-lg px-[16px] py-[8px] border border-[black]">
            {ivrUpdateState.ivrTempName}
          </h1> */}
          <input value={ivrUpdateState.ivrTempName} className="flex min-w-[161px] justify-center font-semibold text-[18px] 4xl:text-[24px] items-center rounded-lg px-[16px] py-[8px] border border-[black]" onChange={(e) => setIvrUpdateState({ ...ivrUpdateState, ivrTempName: e.target.value })} />
          {/* <div className="w-full h-[128px] border-theme-color border rounded-lg mt-[30px]">
            <div className="px-[22px] pt-[20px]">
              <div className="">
                <h1 className="text-[25px] font-semibold">Subject</h1>
                <textarea type="text" name="ivrSubject" value={ivrUpdateState.ivrSubject} onChange={(e) => setIvrUpdateState({ ...ivrUpdateState, ivrSubject: e.target.value })} rows="2" cols="50" placeholder="What's the subject line for this template" className="w-full text-[20px] mt-2 font-normal outline-0"></textarea>
              </div>
              <div>
              </div>
            </div>
          </div> */}
          <div className="mt-2 ml-2">
            {
              ivrUpdateState.ivrAudio.name === undefined ?
                <div className="mt-3">
                  <ReactAudioPlayer
                    src={ivrUpdateState.ivrAudio}
                    controls
                  />
                </div>
                :
                <div className="mt-3">
                  <ReactAudioPlayer
                    src={URL.createObjectURL(ivrUpdateState.ivrAudio)}
                    controls
                  />
                </div>
            }
          </div>
          <div className="flex mt-2">
            <div className="mt-[17px] w-[285px] h-[56px] bg-theme-color text-white border-1 border-theme-color flex justify-center items-center cursor-pointer text-[24px] font-medium rounded-lg ... ">
              <label className="cursor-pointer">Upload audio File
                <input className="hidden" type="file" id="upload" name="filename" accept="audio/mp3,audio/*;capture=microphone" onChange={(event) => { handleAudio(event) }}
                ></input>
              </label>
            </div>
            <button type='button' className=" ml-2 mt-[17px] w-[285px] h-[56px] bg-white text-theme-color border-1 border-theme-color flex justify-center items-center cursor-pointer text-[24px] font-medium rounded-lg ... " onClick={() => { handleIvrTemplate() }} >
              Update Template
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}
export default IvrUpdateTemplate;