import React, { useContext } from "react";
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DataContext } from "../../../context/data/DataState";
import settImg from "../../../assets/images/sett_modal.svg";
import work from "../../../assets/images/work.svg";
import calendar from "../../../assets/images/calendar.svg";
import day_night from "../../../assets/images/day_night.svg";
import caret_blue from "../../../assets/images/caret blue.svg";
import keyIcon from "../../../assets/images/keyboard_icon.svg";
import TimeKeeper from "react-timekeeper";
import DatePicker from "react-datepicker";
import pencil from '../../../assets/images/editpencil.svg'
import { MdOutlineModeEditOutline } from 'react-icons/md';


import "react-datepicker/dist/react-datepicker.css";
import "../CampaignModals/timePicker/timepicker.css";
import "./datePicker/datepicker.css"

const ScheduleMail = () => {
  const [openPopGroup, setOpenPopGroup] = useState(true);
  const { scheduleEmailPopup, setscheduleEmailPopup } = useContext(DataContext);
  const [time, setTime] = useState("12:34pm");
  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        // Close the Datepicker
        console.log("Clicked outside of Datepicker");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[150px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full p-[2rem_4rem] sm:max-w-lg">
                  <div className="flex justify-center items-center flex-col">

                    {/* <h1 className="text-[rgba(22,_22,_22,_0.6)] text-3xl text-center pb-8 font-medium">Pick Date and Time</h1>
                    <div className="relative border border-[rgba(22,_22,_22,_0.6)] p-2  rounded-md w-[502px] h-[102px]">
                      <input
                        type="text"
                        name=""
                        id=""
                        className="date_picker"
                      />
                      <img
                        src={caret_blue}
                        alt=""
                        className="absolute w-[15px] right-4 top-1/2 translate-y-[-50%]"
                      />
                    </div> */}

                    {/* time pop up  */}
                    {/* <div className="timepop p-[2rem_1rem] shadow-lg rounded-md">
                        <span className="mr-auto inline-block text-[12px]">Select time</span>
                      <TimeKeeper
                        time={time}
                        onChange={(data) => setTime(data.formatted12)}
                      />
                      <div className="flex justify-between">
                        <img src={keyIcon} alt="" />
                        <div className="flex gap-4 text-theme-color text-[24px] font-semibold">
                          <span>Ok</span>
                          <span>Cancel</span>
                        </div>
                      </div>
                      </div> */}
                    {/* time pop up end  */}

                    {/* date picker  */}
                    <div className=" border-[rgba(22,_22,_22,_0.6)] p-2  rounded-md w-[400px] h-500px">
                    <span className="mr-auto inline-block text-base">Select date</span>
                    <div className="flex text-[43px] py-4 border-b justify-between items-center mb-6">
                      <h1 className="">Tue, Feb 7</h1>
                      <MdOutlineModeEditOutline />
                    </div>
                      <DatePicker selected={startDate}
                       ref={datePickerRef}
                      showYearDropdown
                      dropdownMode="select"
                      inline
                      onChange={(date) => setStartDate(date)} />
                      <div className="flex gap-4 text-theme-color text-[24px] font-semibold justify-end">
                          <span>Ok</span>
                          <span>Cancel</span>
                        </div>
                    </div>
                    {/* date picker end  */}
                    {/* <div className="relative border p-2 mt-6 rounded-md w-[502px] h-[102px] border-[rgba(22,_22,_22,_0.6)]">
                      <input
                        type="text"
                        name=""
                        id=""
                        className="time_picker"
                      />
                      <img
                        src={caret_blue}
                        alt=""
                        className="absolute w-[15px] right-4 top-1/2 translate-y-[-50%]"
                      />
                      </div>
                      <span className="block text-left text-[20px] w-full font-semibold text-[rgba(22,_22,_22,_0.6)]">Indian Standard Time</span>

                      <div className="flex justify-start gap-12 mt-12">
                        <button className="bg-white text-theme text-theme-color p-[.7rem_1.5rem] border 4xl:text-[24px] 3xl:text-[20px] font-medium text-[18px] rounded-lg border-theme-color">
                          Cancel
                        </button>
                        <button className="bg-theme-color rounded-lg text-white p-[.7rem_1.5rem] 4xl:text-[24px] 3xl:text-[20px] font-medium text-[18px]">
                          Schedule Send
                        </button>
                      </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default ScheduleMail;
