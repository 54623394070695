import React from "react";
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from "react";
import sucess from '../../../assets/images/correct.svg';
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/data/DataState";
import ch_temp from '../../../assets/images/Choose-amico.svg'

const UseTempalate = () => {
  const [openPopGroup, setOpenPopGroup] = useState(true);
  const { useTempalatePopup,
    setUseTemplatePopup } = useContext(DataContext);
  const navigate = useNavigate();

  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden w-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  {/* <div className="bg-white px-4 pt-5 pb-2 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start"> */}
                      {/* <div className="m-auto w-56 flex h-28 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                        <img className="w-[120px]" src={sucess} ></img>
                      </div> */}
                      {/* <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-10">
                          <p className="text-sm text-gray-500">
                            hello
                          </p>
                        </div>
                      </div> */}
                    {/* </div>
                  </div> */}
                  <div className="bg-gray-50 p-6">
                    <img src={ch_temp} alt="" className="flex mx-auto w-2/3" />
                    <div className="flex justify-center pt-12 gap-4">
                    <button
                      type="button"
                      className="border-1 border-theme-color p-[.45rem_1.5rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg"
                      onClick={() => {
                        // navigate('/create-campaign')
                        setUseTemplatePopup(false);
                      }}
                    >
                     Custom template
                    </button>
                    <button
                      type="button"
                      className="border-1 border-theme-color p-[.45rem_1.5rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg"
                      onClick={() => {
                        navigate('/campaign-category');
                        setUseTemplatePopup(false);
                        // console.log('hello')
                      }}
                    >
                      Use already created templates
                    </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default UseTempalate;