import React, { useContext, useEffect, useMemo, useRef } from "react";
import { MdDeleteForever } from 'react-icons/md';
import { AiOutlineRight, AiOutlineSave } from 'react-icons/ai';
import { useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DataContext } from "../../../context/data/DataState";
import Files from 'react-files';
import { useLocation, useParams } from "react-router-dom";
import SucessTempModal from "./TemplateModal/SucessTemplateModal";
import ImageResize from 'quill-image-resize-module-react';
import AuroLogo from "../../Logo/AuroLogo";

const CreateTemplate = () => {
    const quillRef = useRef();
    Quill.register('modules/imageResize', ImageResize);
    const { id } = useParams()
    const { state } = useLocation();
    const { columnHeader, groupListState, templates, setTemplates, groupList, getColumn, smsTemplateCreate, whatappTemplatesCreate, ivrStatePopup, successDltTempPop } = useContext(DataContext)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropDownUserVariable, setDropDownUserVariable] = useState(false)
    const [selectedValueOpen, setSelectedValueOpen] = useState(false);
    const [stateBody, setStateBody] = useState('');
    const [userVariable, setUserVariable] = useState('');
    const [showuserVariable, setShowUserVariable] = useState('');
    const [attachmnet, setAttachmnet] = useState([]);


    const buttonDisabled = (templates.subject !== '' && templates.templateName !== '' && stateBody !== '');

    console.log(templates, "templates");

    const getdata = (id) => {
        getColumn(id)
    };

    const SelectedValueOpen = (id, groupname, option) => {
        setTemplates({ ...templates, groupname: groupname })
        getdata(id)
        setSelectedValueOpen(true)
    }
    const handleSelectedValue = (item) => {
        setTemplates({ ...templates, subgroupdata: item })
        setDropdownOpen(false);
        setSelectedValueOpen(false)
    };
    const sendTemplates = () => {
        if (state.item.categoriesType == 'Email') {
            smsTemplateCreate(templates.subject, stateBody, id, templates.templateName, attachmnet)
            setStateBody("")
            setTemplates({ ...templates, subject: "" })
            setTemplates({ ...templates, groupname: "" })
            setTemplates({ ...templates, subgroupdata: "" })
        } else if (state.item.categoriesType == 'Whatsapp') {
            whatappTemplatesCreate(templates.subject, stateBody, id, templates.templateName, attachmnet)
            setStateBody("")
            setTemplates({ ...templates, subject: "" })
            setTemplates({ ...templates, groupname: "" })
            setTemplates({ ...templates, subgroupdata: "" })
        }
    }
    function previewFile(element) {
        var file = element;
        var reader = new FileReader();
        reader.onloadend = function () {
        }
        reader.readAsDataURL(file);
        return imageHandler(reader.result)
    }
    const imageHandler = (e) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
            const file = input.files[0];
            if (/^image\//.test(file.type)) {
                const formData = new FormData();
                formData.append("image", file);
                const res = await previewFile(file); // upload data into server or aws or cloudinary
                editor.insertEmbed(editor.getSelection(), "image", e);
            } else {
                alert('You could only upload images.');
            }
        };
    }
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['image', "link"],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }],
            ]
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), [])
    const handleDocument = (e) => {
        setAttachmnet(e)
    }
    const deleteHandler = (value) => {
        setAttachmnet(attachmnet.filter((e) => e !== value));
    }
    const saveInsertVariable = () => {
        setShowUserVariable(userVariable)
        setDropDownUserVariable(false)
    }
    useEffect(() => {
        setTemplates({ ...templates, subgroupdata: "" })
    }, [templates.groupname])

    useEffect(() => {
        groupList();
    }, [])

    useEffect(() => {
        if (templates.groupname != "" && templates.subgroupdata != "") {
            setStateBody((prevState) => `${prevState}{'${templates.subgroupdata}'}`)
        }
    }, [templates.subgroupdata])

    useEffect(() => {
        setStateBody((prevState) => `${prevState}${showuserVariable}`)
    }, [showuserVariable])


    return (
        <>
            <div className="w-full flex bg-white h-screen">
                <span>{ }</span>
                <div className=" bg-theme-color h-screen">

                </div>
                <div className="w-full mb-12 h-screen overflow-x-scroll">
                    {
                        ivrStatePopup === true ? <SucessTempModal></SucessTempModal> : <></>
                    }
                    {
                        successDltTempPop === true ? <SucessTempModal></SucessTempModal> : <></>
                    }
                    <div className="pl-4 4xl:pr-6 pr-4 mt-4">
                        <h1 className=" 4xl:text-[24px] text-[18px] font-medium">Template name</h1>
                        <div className="flex justify-between">
                            <div className=" flex lg:flex-wrap items-center lg:gap-4">
                                <input onChange={(e) => setTemplates({ ...templates, templateName: e.target.value })} type='text' placeholder="Type name here" className="lg:w-full placeholder:text-left placeholder:text-[14px] 4xl:placeholder:text-[18px] p-[.4rem_1rem] rounded-md border-black border flex justify-center font-normal items-center bg-[#FAFAFA] text-[14px] 4xl:text-[18px]" />
                                <div className="ml-4 lg:ml-0" onMouseLeave={() => { setDropdownOpen(false); setSelectedValueOpen(false) }}>
                                    <button className="inline-flex justify-center items-center gap-x-1.5 bg-theme-color p-[.55rem_1.2rem] text-[12px] 4xl:text-[16px] font-normal rounded-md text-white" onMouseEnter={() => setDropdownOpen(true)}>
                                        Insert system variable
                                    </button>
                                    {
                                        dropdownOpen && (
                                            <>
                                                <div className="absolute z-10 w-[215px] 3xl:w-[170px] origin-top-right mt-[0.5px] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                                                    {groupListState.map((option) => (
                                                        <div key={option._id} className="text-[18px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] xs:text-[10px] pb-[10px] pl-[10px]">
                                                            <div className="flex cursor-pointer items-center justify-between px-2
                                                        " onClick={(e) => { SelectedValueOpen(option._id, option.groupName, option) }}>
                                                                <span className="">{option.groupName}</span> <span className="text-black"><AiOutlineRight /> </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        selectedValueOpen === true ? <> <div className="absolute z-10 ml-[215px] 3xl:ml-[170px] w-[171px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                                            {
                                                columnHeader.map((item) => (
                                                    <div className="text-[18px] pb-[10px] pl-[10px]">
                                                        <div onClick={() => handleSelectedValue(item.columnName)}>
                                                            {item.columnName}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div> </> : <></>
                                    }
                                </div>
                                <div className="ml-4 relative">
                                    <button onClick={() => { setDropDownUserVariable(true) }} className="inline-flex justify-center items-center gap-x-1.5 bg-theme-color text-[12px] 4xl:text-[16px] p-[.55rem_1.2rem] font-normal rounded-lg text-white ">
                                        Insert user variable
                                    </button>
                                    {
                                        dropDownUserVariable ? <div className="border border-slate-300 hover:border-indigo-300 flex justify-between rounded-lg items-center absolute p-2 lg:p-1 md:p-1 sm:p-[2px] top-[47px] left-[-42%] transition-all bg-white">
                                            <input onChange={(e) => setUserVariable(e.target.value)} type="text" placeholder="Insert User Variable" className="text-[13px]"></input>
                                            <button onClick={() => { saveInsertVariable() }} className="inline-flex justify-center items-center gap-x-1.5  bg-theme-color text-[12px] md:text-[10px] 4xl:text-[16px] font-normal rounded-lg text-white p-[.4rem_1rem]">
                                                Save
                                            </button>
                                        </div> : ""
                                    }
                                </div>
                            </div>
                            <AuroLogo />
                        </div>
                        <div className="w-full h-[85vh] border-[#161616] border rounded-lg mt-4 shadow-edit-shadow">
                            <div className="flex flex-col h-full">
                                <div className="px-[10px] pt-[20px]">
                                    <div className="border-b">
                                        <h1 className="text:18px 4xl:text-[25px] font-semibold">Subject </h1>
                                        <textarea type="text" onChange={(e) => setTemplates({ ...templates, subject: e.target.value })} name="subject" rows="2" cols="50" placeholder="What's the subject line for this template" className="w-full text-[15px] 4xl:text-[20px] mt-2  outline-0"></textarea>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="px-[10px] h-full relative">
                                    {
                                        state?.item?.categoriesType == "Email" ? <ReactQuill

                                            value={stateBody}
                                            onChange={(e) => { setStateBody(e) }}
                                            modules={modules}
                                            ref={quillRef}
                                        /> : <div className="h-full">
                                            <textarea type="text" onChange={(e) => { setStateBody(e.target.value) }} name="subject" placeholder="Enter your Whatsapp Message" className="w-full 3xl:h-[415px] 4xl:h-[520px] lg:h-[300px] text-[15px] mt-2 font-normal outline-0 mb-[35px]"></textarea>
                                        </div>
                                    }
                                </div>
                                {
                                    templates.image.length > 0
                                        ? <div className='files-gallery'>
                                            {templates.image.map((file, index) => (<>
                                                <div key={index}>
                                                    <img src={`${file.preview.url}`} alt="Girl in a jacket" width="100" height="100" />
                                                </div>
                                            </>
                                            )
                                            )}
                                        </div> : ""}
                                <div className="flex pb-4">
                                    <Files
                                        className='files-dropzone fileContainer'
                                        onChange={(e) => { handleDocument(e) }}
                                        // accepts={['image/*']}
                                        multiple={true}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        <button type='button' className="ml-[10px] border-1 border-theme-color relative lg:bottom-[20px]  p-[.45rem_2rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg" >+Attachment</button>
                                    </Files>
                                    <button onClick={() => { sendTemplates() }} disabled={!buttonDisabled} type='button' className="ml-[10px] border-1 border-theme-color p-[.45rem_2rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] relative lg:bottom-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg">Save</button>
                                </div>

                                <div className="flex">
                                    {
                                        attachmnet?.map((v, i) => {
                                            return (
                                                <>
                                                    <div className="border border-slate-300 hover:border-indigo-300 flex justify-between p-1 m-1 rounded-lg items-center">
                                                        <label>{v?.name}</label>
                                                        <MdDeleteForever onClick={() => { deleteHandler(v) }} className="w-4 h-4 ml-2" />
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateTemplate;  