import React, { useContext, useState } from "react";
// import { Link } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import emailicon from '../../assets/images/emailicon.svg';
import passcodeicon from '../../assets/images/passcodeicon.svg';
import swal from 'sweetalert';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { DataContext } from "../../context/data/DataState";
import { useEffect } from "react";

const Login = () => {
  const navigate = useNavigate()
  // The useNavigate hook returns a function that lets you navigate programmatically.
  // navigate('/app/report', { state: rowData })

  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const { LoginSubUser, loginError, loginmailError, setLoginError, setLoginmailError, setForgetEmailState, setEmailForgetExist } = useContext(DataContext)
  const [isVisible, setVisible] = useState(false);
  const buttonDisabled = (state.email !== '' && state.password !== '')

  const submit = () => {
    if (state.email === '' && state.password === '') {
      swal('Please fill data');
    }
    else (
      LoginSubUser(state)
    )

  }
  // const navigateToRegistration = () => {
  //   navigate("/registration");
  // }
  const toggle = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    setForgetEmailState('');
    setEmailForgetExist('')
  }, [])
  return (
    <div className="grid grid-cols-2  sm:grid-cols-1 xs:grid-cols-1 lg:gap-0 md:gap-4 lg:place-content-start  place-content-center w-full gap-0 max-h-screen">
      <div className="grid  place-content-center m-auto md:ml-4 sm:mx-5 xs:m-3 xs:pb-20 md:pb-0">
        <div className="text-center flex items-center pt-16 xs:mt-16 justify-center 3xl:py-12 px-4   4xl:py-16  pb-8 lg:w-[450px] xl:w-[450px] 2xl:w-[450px] 3xl:w-[550px] 4xl:w-[674px] h-auto py-8 rounded-2xl shadow-[0_0_50px_#00000016]">
          <div className="text-center px-4 md:px-6 3xl:px-8 4xl:px-12">
            <h1 className="4xl:text-[55px] 3xl:text-[40px] text-[28px] font-bold text-theme-color">
              Login
            </h1>
            <div className="4xl:text-[20px] text-[13px] 3xl:w-[85%] 4xl:w-4/5 m-auto 3xl:py-4 4xl:py-4 py-2 font-normal 3xl:text-[17px]">
              <p className="text-center lg:pb-4">
                Welcome back! login with your data that you entered during registration.
              </p>
            </div>
            <form>
              <div className="relative mt-1 m-auto">
                <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pb-0 3xl:pb-8 4xl:pb-8 3xl:pt-[21px] 4xl:pt-7">
                  <span className="text-gray-500 sm:text-sm"><img src={emailicon} width='18px' /></span>
                </div>
                <input type="text" placeholder="Enter your Email" name="email" className="3xl:h-[40px] 4xl:h-[55px] 3xl:mt-0 4xl:mt-8 2xl:mt-4 block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color flex-auto
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        invalid:border-pink-500 invalid:text-pink-600
                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500 pl-[35px]
                        "
                  value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })}
                />
              </div>
              <div className="m-auto text-left  ">
                <span className="text-red-600 text-[10px]">{loginmailError}</span>
              </div>

              <div className="relative 4xl:mt-[20px] 3xl:mt-[16px] mt-2 flex m-auto">
                <div className="pointer-events-none absolute 3xl:inset-y-0 4xl:inset-y-0 flex items-center pl-2 3xl:pt-[20px] top-[22px] 4xl:pt-4">
                  <span className="text-gray-500 inline-block pb-2 sm:text-sm" onClick={() => toggle()}><img src={passcodeicon} width='15px' /></span>
                </div>

                <input type={isVisible ? "text" : "password"} placeholder="Password" name="password" className="3xl:h-[40px] 4xl:h-[55px] mt-4  block w-full m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color flex-auto
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        invalid:border-pink-500 invalid:text-pink-600
                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500 pl-[35px]
                        "
                  value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })}
                />
                <div className="absolute right-px flex items-center pt-7 pr-3 4xl:top-[11%] ">
                  <span className="cursor-pointer" onClick={() => toggle()}>
                    {isVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  </span>
                </div>
              </div>
              <div>
                <div className="m-auto text-left">
                  <span className="text-red-600 text-[10px]">{loginError} </span>
                </div>
              </div>
              <div className="m-auto flex justify-between items-center 3xl:mt-[28px] 4xl:mt-[32px] mt-1 4xl:w-full">
                {/* <span className="3xl:text-[13px] text-[10px] 4xl:text-[18px] text-gray-400 flex items-center"><input type="checkbox" className="checked:bg-theme-color ..." required />&nbsp;Remember me</span> */}

                <Link to='/forgot-password'><span className="text-theme-color underline text-[11px] 3xl:text-[14px] 4xl:text-[18px] font-medium cursor-pointer ...">
                  Forgot Password
                </span></Link>

                <button type='button' disabled={!buttonDisabled} className="text-[15px] 4xl:text-[18px] font-semibold 3xl:p-[.7rem_5rem] 4xl:p-[.8rem_5rem] disabled:opacity-40 bg-theme-color text-white rounded-lg 4xl:rounded-2xl p-[7px_58px] ... " onClick={() => submit()}>Login</button>
              </div>
            </form>
            <div>
                    <p className="mt-4 4xl:mt-8 text-[14px] 4xl:text-[18px]">Back to <Link to="/login" className="text-theme-color font-medium">Admin Login</Link></p>
            </div>
            {/* <div className="3xl:mt-[24px] 4xl:mt-[24px] mt-3 4xl:text-[22px] 3xl:text-[17px] text-[13px]">
              <span>
                Don't have an account?
              </span>&nbsp;
              <span className="text-theme-color cursor-pointer font-bold ..." onClick={() => { navigateToRegistration() }}>
                Register
              </span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="w-full h-screen sm:hidden xs:hidden">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full "></div>
      </div>
    </div>
  )
}
export default Login;