import React, { useContext, useState } from "react";
import back from "../../assets/images/back.svg";
import { useNavigate, Link } from "react-router-dom";
import homeicon from "../../assets/images/homeicon.svg";
import { DataContext } from "../../context/data/DataState";
import { useEffect } from "react";

const BusinessAdd = () => {
  const navigate = useNavigate();
  const { businessInfo, setBusinessInfo, SendAllInfo, stateArr, setStateArr, indiaStateApi, indiaDistrictApi, stateDistrict, setStateDistrict } =
    useContext(DataContext);
  const [zipError, setZipError] = useState(false);
  const validationZip = (businessInfoZip) => {
    var zipReg = /^[1-9][0-9]{5}$/;
    if (businessInfoZip.match(zipReg)) {
      return setZipError(false);
    } else if (businessInfoZip == "") {
      return setZipError(false);
    } else {
      return setZipError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (zipError === true) {
      setZipError(true);
    } else {
      SendAllInfo(businessInfo);
      //  navigate("/select-plan")
    }
  };
  console.log(businessInfo, "buisness ");
  const buttonDisabled =
    businessInfo.address !== "" &&
    businessInfo.city !== "" &&
    businessInfo.buisnessState !== "" &&
    businessInfo.zip !== "" &&
    businessInfo.country !== "";



  const handleDistrict = (e) => {
    const [enName, id] = e.target.value.split('|');
    setBusinessInfo({
      ...businessInfo,
      buisnessState: enName,
    });
    indiaDistrictApi(id)
  };

  useEffect(() => {
    indiaStateApi()
  }, []);

  return (
    <div className="grid grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 lg:gap-12 w-full lg:place-content-start place-content-center gap-0 max-h-screen relative">
      <div className="grid place-content-center  xs:pb-10 md:pb-0">
        <div className="4xl:px-24 4xl:py-8 flex items-center justify-center">
          <div className="text-left flex items-center xs:py-8 lg:w-4/5 xl:w-[90%] m-auto lg:py-8 3xl:w-4/5 max-w-[670px] 3xl:py-20 md:h-auto md:py-8 md:px-4 sm:px-4 xs:px-2 rounded-2xl ">
            <div className="">
              <Link to='/info' ><img src={back} className='w-[23px] h-[20px] absolute top-[15%] md:top-[7%] sm:top-[5%] xs:top-[3%]'></img> </Link>
            </div>
            <div className="flex flex-col ">
              <h1 className="text-theme-color lg:text-[28px] xl:text-[22px] mt-4 font-bold text-[24px] xs:text-[20px] 3xl:text-[27px] 4xl:text-[39px]">
                What's your business address?
              </h1>
              <p className="3xl:text-[13px] text-[12px] mx-auto pt-4 mt-6 xs:pt-1 xs:mt-2 font-medium 3xl:mt-6 4xl:mt-8 4xl:text-[18px]">
                To follow anti-spam low, your address will appear in the footer
                of every email you send with XYZ . Don't have an official
                business address? Learn about alternatives
              </p>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                className=""
              >
                <div className="relative  flex w-full m-auto">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                    <span className="text-gray-500 sm:text-sm">
                      <img
                        src={homeicon}
                        className="pb-[2px] w-[16px] 4xl:w-[22px]"
                      />
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Address line 1 (Street address or post office box) *"
                    className="mt-4 block lg:text-[13px] 4xl:text-[20px] 3xl:h-[41px] 4xl:h-[55px] font-normal  w-10/12 m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600 flex-auto pl-[40px]
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                     "  
                    value={businessInfo.address}
                    onChange={(e) =>
                      setBusinessInfo({
                        ...businessInfo,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="relative mt-1 4xl:mt-[15px] flex w-full px-[80px]4xl:px-[0px]  m-auto">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                    <span className="text-gray-500 sm:text-sm">
                      <img
                        src={homeicon}
                        className="pb-[2px] w-[16px] 4xl:w-[22px]"
                      />
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Address line 2 (optional)"
                    className="mt-[11px] lg:text-[13px] 4xl:text-[20px] font-normal 3xl:h-[41px] 4xl:h-[55px]  block w-10/12 m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600 flex-auto pl-[40px]
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                     "
                    value={businessInfo.address2}
                    onChange={(e) =>
                      setBusinessInfo({
                        ...businessInfo,
                        address2: e.target.value,
                      })
                    }
                  />
                </div>


                <div className="w-full flex justify-between">
                  <div className="w-[48%]">
                    <select
                      defaultValue={businessInfo.buisnessState}
                      onChange={(e) =>
                        handleDistrict(e)
                      }
                      className="lg:text-[13px] 4xl:text-[20px]  font-normal h-[35px] 4xl:h-[55px]  mt-4 block w-full m-auto p-[2px] bg-white border 
                border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400 
                    focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 text-slate-400 
                     "
                    >
                      <option
                        value={""}
                        selected
                        disabled
                        hidden
                        className="text-white"
                      >
                        State/Province/Region{" "}
                      </option>
                      {
                        stateArr.map((item) => (
                          <option key={item.en_name} value={`${item.en_name}|${item.id}`}>{item.en_name}
                          </option>
                        ))
                      }
                    </select>
                  </div>

                  {/* <div className="w-[48%] ">
                    <input
                      type="text"
                      placeholder="City"
                      className="mt-4 lg:text-[13px] 4xl:text-[20px] font-normal  3xl:h-[41px] 4xl:h-[55px]  block w-full m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                     "
                      value={businessInfo.city}
                      onChange={(e) =>
                        setBusinessInfo({
                          ...businessInfo,
                          city: e.target.value,
                        })
                      }
                    />
                  </div> */}


                  <div className="w-[48%]">
                    <select
                      defaultValue={businessInfo.city}
                      onChange={(e) =>
                        setBusinessInfo({
                          ...businessInfo,
                          city: e.target.value,
                        })
                      }
                      className="lg:text-[13px] 4xl:text-[20px]  font-normal h-[35px] 4xl:h-[55px]  mt-4 block w-full m-auto p-[2px] bg-white border 
                border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400 
                    focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 text-slate-400 
                     "
                    >
                      <option
                        value={""}
                        selected
                        disabled
                        hidden
                        className="text-white"
                      >
                        District{" "}
                      </option>
                      {
                        stateDistrict.map((item) => (
                          <option key={item.en_name} value={`${item.en_name}`}>{item.en_name}
                          </option>
                        ))
                      }
                    </select>
                  </div>


                </div>


                <div className="w-full flex justify-between">
                  <div className="w-[48%]">
                    <input
                      type="text"
                      placeholder="Zip/Postal code *"
                      className="mt-4 3xl:h-[41px] 4xl:h-[55px]  lg:text-[13px] 4xl:text-[20px] font-normal block w-full  m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                     "
                      maxLength={6}
                      value={businessInfo.zip}
                      onChange={(e) => {
                        setBusinessInfo({
                          ...businessInfo,
                          zip: e.target.value,
                        });
                      }}
                      onKeyUp={() => validationZip(businessInfo.zip)}
                    />
                  </div>
                  <div className="w-[48%]">
                    <input
                      type="text"
                      placeholder="Country"
                      className="mt-4 3xl:h-[41px] 4xl:h-[55px]  lg:text-[13px] 4xl:text-[20px] font-normal  w-full block m-auto px-3 py-2 bg-white border border-[#3E8BE2] rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                     "
                      value={businessInfo.country}
                      readOnly
                      disabled
                    //   onChange={(e) => { setBusinessInfo({ ...businessInfo, country: e.target.value }) }}
                    />
                  </div>
                </div>
                <div className="w-4/5 text-left m-auto">
                  {zipError ? (
                    <span className="text-red-600 text-[10px] ">
                      Postal Code is Invalid.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>

                <button
                  type="submit"
                  disabled={!buttonDisabled}
                  className="flex m-auto mt-8 4xl:mt-9 text-[15px] xs:text-[14px] 4xl:text-[23px] font-semibold p-[.6rem_4rem] xs:p-[5px_50px] 3xl:p-[.65rem_5rem] 4xl:p-[1rem_8.3rem] disabled:opacity-40 bg-theme-color text-white rounded-xl 4xl:rounded-2xl ..."
                >
                  Next
                </button>
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className=" h-screen sm:hidden xs:hidden">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full"></div>
      </div>
    </div>
  );
};
export default BusinessAdd;