import React from "react";
import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DataContext } from "../../../context/data/DataState";
import SideNavBar from "../../SideNavBar/SideNavBar";
import SucessTempModal from "./TemplateModal/SucessTemplateModal";
import ReactAudioPlayer from 'react-audio-player';

const IvrCreateTemplate = () => {
  const { id } = useParams()
  const { ivrCreateState, setIvrCreateState, ivrTemplateCreate, ivrStatePopup } = useContext(DataContext);

  const handleAudio = (event) => {
    if (event.target.files && event.target.files[0]) {
      setIvrCreateState({ ...ivrCreateState, ivrAudio: event.target.files[0] });
    }
  };

  const handleIvrTemplate = () => {
    ivrTemplateCreate(id, ivrCreateState)
  };

  console.log(ivrCreateState, 'ivrCreateState')

  return (
    <div className="w-full flex bg-white h-screen">
      <div className="bg-theme-color h-screen">
        
      </div>
      {
        ivrStatePopup === true ? <SucessTempModal></SucessTempModal> : <></>
      } 
      <div className="w-full mb-12 h-screen overflow-x-scroll">
        <div className="pl-4 pr-12 mt-6">
          <h1 className="text-[13px] 4xl:text-[18px] font-medium mb-2">Template name</h1>
          <input type='text' placeholder="Type name here" className="placeholder:text-left placeholder:text-[14px] 4xl:placeholder:text-[18px] rounded-lg border-black border flex justify-center font-semibold items-center bg-[#FAFAFA] text-[14px] p-[.5rem_1rem] 4xl:text-[18px]" onChange={(e) => setIvrCreateState({ ...ivrCreateState, ivrTempName: e.target.value })} />
          {/* <div className="w-full h-[128px] border-[rgba(22,_22,_22,_0.6)] border shadow-edit-shadow rounded-lg mt-4">
            <div className="px-[22px] pt-[20px]">
              <div className="">
                <h1 className="text-[25px] font-semibold">Subject</h1>
                <textarea type="text" name="ivrSubject" value={ivrCreateState.ivrSubject} onChange={(e) => setIvrCreateState({ ...ivrCreateState, ivrSubject: e.target.value })} rows="2" cols="50" placeholder="What's the subject line for this template"
                  className="w-full italic font-normal outline-0 text-[16px] 4xl:text-[22px]"></textarea>
              </div>
              <div>
              </div>
            </div>
          </div> */}
          <div className="mt-2 ml-2">
            {
              ivrCreateState.ivrAudio.name === undefined ? <>
              </> :
                <div className="mt-3">
                  <ReactAudioPlayer
                    src={URL.createObjectURL(ivrCreateState.ivrAudio)}
                    controls
                  />
                  {/* <audio controls>
                    <source src={URL.createObjectURL(ivrCreateState.ivrAudio)} />
                  </audio> */}
                </div>
            }
          </div>
          <div className="flex">
            <div className="mt-[17px] p-[.55rem_1.2rem] bg-theme-color text-white border-1 border-theme-color flex justify-center items-center cursor-pointer text-[18px] sm:text-[13px] xs:text-[11px] 4xl:text-[24px] font-normal rounded-md ... ">
              <label className="cursor-pointer">Upload audio File
                <input className="hidden" type="file" id="upload" name="filename" accept="audio/mp3,audio/*;capture=microphone" onChange={(event) => { handleAudio(event) }}
                ></input>
              </label>
            </div>
            <button type='button' className=" ml-2 mt-[17px] p-[.55rem_1.2rem] xs:p-[.55rem_0.9rem]  bg-white text-theme-color border-1 border-theme-color flex justify-center items-center cursor-pointer text-[18px] sm:text-[13px] xs:text-[11px] 4xl:text-[24px] font-normal rounded-md ... " onClick={() => { handleIvrTemplate() }} >
              Save Template
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}
export default IvrCreateTemplate;