import React, { useContext, useState } from 'react';
import paidPlan from '../../assets/images/paid_plan.svg'
import back from '../../assets/images/back.svg';
import activemark from '../../assets/images/tickmark.svg';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { DataContext } from '../../context/data/DataState'
const PlanSetup = () => {
    const { checkoutHandler } = useContext(DataContext);
    const navigate = useNavigate();

    const [state, setState] = useState([
        {
            "plantitle": "Bronze",
            "planprice": 10,
            "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
        },
        {
            "plantitle": "Silver",
            "planprice": 20,
            "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
        },
        {
            "plantitle": "Gold",
            "planprice": 30,
            "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
        },
        {
            "plantitle": "Premium",
            "planprice": 40,
            "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
        }
    ]

    );

    const handleSubmit = (e, price) => {
        e.preventDefault()
        checkoutHandler(price)
    }
    return (
        <div className='w-full'>
            <div className='4xl:h-[297px] 3xl:h-[154px] h-[110px] w-full bg-no-repeat bg-cover bg-paidPlan'>
        <div className='flex justify-center items-center h-full'>
          {/* <Link to='/group-dashboard' className='w-[42%]' ><img src={back} className='w-14 pt-24' ></img></Link> */}
          <h1 className='3xl:text-40px text-[30px] text-white font-bold 4xl:text-[55px]'>Select your plan</h1>
        </div>
      </div>
            <div className='grid grid-cols-4  xl:px-4 2xl:px-6 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 sm:px-12 xs:px-12 xs:gap-[3rem] sm:gap-[3rem] lg:gap-16 lg:px-8 md:px-8 md:gap-[2rem] 3xl:gap-x-0 gap-4 4xl:gap-[14rem] pt-16 3xl:px-12 3xl:place-items-center 4xl:place-items-center 4xl:px-36 pb-8'>
                {
                    state.map((item) => (
                        <div className='border border-theme-color rounded-2xl relative 4xl:max-w-[1800px] 3xl:w-64 4xl:w-[22rem]  flex flex-col text-center'>
                            <div className='body flex-auto text-center'>
                                <h2 className='text-theme-color uppercase pt-6 font-bold text-[25px] 4xl:text-[40px]'>{item.plantitle}</h2>
                                <span className='font-bold text-[40px] py-4 inline-block 4xl:text-[49px]'>&#x24;{item.planprice}</span>

                                {/* <span onClick={checkoutHandler(item.planprice)} className='font-bold text-2xl mt-2'>&#x24;{item.planprice}</span> */}
                                <span>{item.planactive === 'true' ? <h1 className='text-theme-color'>{item.planexp}</h1> : <></>}</span>
                                <p className='font-normal text-[.75rem] 4xl:text-[1rem] p-2 text-center'>{item.plandesc}</p>
                            </div>
                            <div className='footer'>
                                <button type='button' className="mt-6 mb-2 p-[5px_40px] text-[16px] 4xl:text-[21px] w-[90%] 4xl:p-[7px_60px] bg-theme-color text-white rounded-lg ..." onClick={(e) => handleSubmit(e, item.planprice)}>{item.planprice}</button>
                            </div>
                        </div>
                    )
                    )
                }
            </div>
        </div>
    )
}
export default PlanSetup;