import React from "react";
import { useParams, } from 'react-router-dom';
import { useState } from "react";
import { } from 'react-icons/ai';
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import { useEffect } from "react";
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import Filter from "../Filter/Filter";
import ErrorTableModal from "../../Modal/ErrorTableModal";
import { HiOutlineCloudDownload } from 'react-icons/hi';
import ApiErrorModal from "../../Modal/ApiErrorModal";
import { RiPencilFill } from 'react-icons/ri';
import EditDataPopup from "../../Modal/EditDataPopup";
import AuroLogo from "../../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";
import { useMediaQuery } from 'react-responsive'

const AudienceTable = () => {

  const { id, specficGroupName } = useParams()

  const { columnHeader, getColumn, uploadExcel, getAudienceData, tableAudience, loaderAudience, tableWrongAudience, errorTablPop, updateExcel, apiError, editPopup,
    setEditPopUp, setEditRowState, getNotEnterData, ActiveInactive, groupListState,
    setCheckUSerUpdate, isVisibleSb, setIsVisibleSb } = useContext(DataContext);
  const [handleExcelUpload, setHandleExcelUpload] = useState(false);

  const [toggleState, setToggleState] = useState('')

  const handleEdit = (data) => {
    setEditPopUp(true)
    setEditRowState(data)
  };


  const handleUpdate = (data) => {
    setCheckUSerUpdate(true);
    setEditPopUp(true)
    setEditRowState(data)
  };

  const handleActiveInactive = (user_id, type) => {
    setToggleState((prevState) => {
      const newState = type === 'Active' ? 'In-Active' : 'Active';
      console.log(newState, 'toggleState');
      ActiveInactive(user_id, newState, id); // Pass the updated state directly to the function
      return newState;
    });
  };


  // get table row data
  const tdData = () => {
    const column = Object.keys(tableAudience[0]);
    column.splice(0, 3);
    return tableAudience?.map((data) => {
      return (
        <tr className="">
          {
            column?.map((v) => {
              if (v === '_id') {
                return <></>
              }
              else if (v === 'groupId') {
                return <></>
              }
              else if (v === 'audienceType') {
                return <></>
              }
              else {
                return <td className="px-6 py-2 whitespace-nowrap 4xl:text-[18px] text-[12px] border-r border-b ">{data[v]}</td>
              }


            })
          }
          {/* {
            column?.map((v) => {
              return <td className="px-6 py-4 whitespace-nowrap 4xl:text-[18px] text-[12px] border-r border-b">{data[v]}</td>
            })
          } */}
          <td className="px-6 py-2 whitespace-nowrap 4xl:text-[18px] text-[12px] border-r border-b">
            <div className="flex items-center justify-evenly">
              <span>
                <label
                  class="flex items-center cursor-pointer"
                >
                  <div class="relative" title="Active">
                    <input type="checkbox" checked={data.audienceType === "Active" ? true : false} class="sr-only" onClick={() => { handleActiveInactive(data._id, data.audienceType) }} />
                    <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
              <span className=" text-[#16161699] text-theme-color cursor-pointer ml-1 inline-block text-[13px] 4xl:text-[18px] border p-[10px] bg-theme-color text-[#fff] rounded" onClick={() => { handleEdit(data, column) }} title="Edit">
                <RiPencilFill /></span> &nbsp; &nbsp;
            </div>

          </td>
        </tr>
      )
    })
  };

  const groupInfo = JSON.parse(localStorage.getItem('userInfo'));

  const tdWrongData = () => {
    const column = Object.keys(tableWrongAudience[0]);
    return tableWrongAudience?.map((data) => {
      return (
        <tr>
          {
            column?.map((v) => {
              if (v === '_id') {
                return <></>
              }
              else if (v === 'groupId') {
                return <></>
              }
              else if (v === 'audienceType') {
                return <></>
              }
              else {
                return <td className="px-6 py-4 whitespace-nowrap 4xl:text-[18px] text-[12px] border-r border-b ">{data[v]}</td>
              }


            })
          }
          <td className="px-6 py-2 whitespace-nowrap 4xl:text-[18px] text-[12px] border-r border-b">
            <span className=" text-[#16161699] text-theme-color cursor-pointer ml-1 inline-block text-[13px] 4xl:text-[18px]" onClick={() => { handleUpdate(data) }}>
              <RiPencilFill /></span>
          </td>
        </tr>
      )
    })
  };
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });

  const handleFileUpdate = (e, id) => {
    updateExcel(e.target.files[0], id)
  };
  const handleFileOverride = (e, id) => {
    uploadExcel(e.target.files[0], id)
  };

  useEffect(() => {
    getColumn(id);
    getAudienceData(id);
    getNotEnterData(id);
  }, []);

  return (
    <>
      {
        errorTablPop ? <ErrorTableModal></ErrorTableModal> : <></>
      }

      {
        apiError ? <ApiErrorModal></ApiErrorModal> : <></>
      }

      {editPopup ? <EditDataPopup /> : <></>}

      <div className={`w-full flex bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
        <div className="bg-theme-color h-screen">

        </div>
        <div className="w-full mb-12 h-screen overflow-y-scroll">
          <div className="px-4  3xl:pt-1 sm:pt-6 4xl:pt-0">
            <div className="border-b-2 border-[rgba(22,_22,_22,_0.6)]">
              {/* <div className="flex justify-between">
              <h1 className="3xl:text-[48px] 4xl:text-[64px] lg:h-auto xl:h-auto lg:text-[2.3rem] xl:text-[2.3rem] 2xl:text-[2.5rem] font-semibold">Audience</h1>
              <AuroLogo />
              </div> */}
              {/* <p className="3xl:text-[22px] 4xl:text-[30px] mb-[16px] text-[18px] lg:mb-2 xl:mb-2 font-medium text-[rgba(22,_22,_22,_0.6)] 4xl:mt-0">{groupInfo?.name} </p> */}
              <div className="hidden justify-between items-center xs:flex sm:flex md:flex">
                <FiMenu className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`} onClick={() => { setIsVisibleSb(!isVisibleSb) }} />
                <AuroLogo /> 
              </div>
              <div className="flex justify-between items-center xs:ml-auto sm:ml-auto ">
                {/* <p className="text-theme-color 4xl:text-[40px] text-[22px] font-semibold 3xl:text-[30px]">{specficGroupName} <small className="4xl:text-[12px] 3xl:text-[10px] text-black text-[8px]">(Group Name)</small></p> */}
                {/* <div className="flex justify-center lg:gap-2 gap-4 my-4 w-3/4 lg:w-full ml-auto"> */}
                <div className="flex lg:gap-2 gap-4 my-4 w-3/4 xs:w-auto sm:w-auto lg:w-auto ">
                  <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="p-[0.45rem_1.5rem] md:p-[9px_20px] sm:p-[6px_15px] xs:p-[6px_12px] lg:text-[12px] xl:text-[12px] md:text-[15px] sm:text-[12px] xs:text-[10px] 3xl:text-[14px] font-normal 4xl:text-[24px] text-[18px] border-1 border-theme-color bg-theme-color text-white rounded-lg ..."
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download Excel" />
                  {
                    tableWrongAudience?.length > 0 ?
                      <div className="flex justify-around gap-4 lg:gap-2 sm:flex sm:flex-row-reverse sm:px-6">
                        <label className=" 4xl:text-[24px] p-[0.45rem_1.5rem] flex justify-center items-center lg:text-[12px] xl:text-[12px] 3xl:text-[14px] font-normal bg-white text-theme-color border-1 border-theme-color rounded-lg text-center cursor-pointer" onClick={() => setHandleExcelUpload(false)}> Update Existing data
                          <input type="file" id="upload" name="filename" className="hidden" onChange={(e) => {
                            handleFileUpdate(e, id)
                          }}></input>
                        </label>
                        <label className="lg:text-[12px] xl:text-[12px] 3xl:text-[18px] font-normal 4xl:text-[24px] p-[0.45rem_1.5rem] flex justify-center items-center  bg-white text-theme-color border-1 border-theme-color rounded-lg text-center cursor-pointer" onClick={() => setHandleExcelUpload(false)}> Override Existing data
                          <input type="file" id="uploadExcel" name="filename" className="hidden" onChange={(e) => {
                            handleFileOverride(e, id);
                          }}></input>
                        </label>
                      </div>
                      :
                      <label className="p-[.45rem_2.4rem] md:p-[9px_20px] sm:p-[6px_15px] xs:p-[6px_12px] md:text-[15px] sm:text-[12px] xs:text-[10px] lg:text-[12px] xl:text-[12px] 4xl:text-[24px] text-[18px] bg-white text-theme-color border-1 border-theme-color rounded-lg text-center cursor-pointer font-normal"> Upload Excel
                        <input type="file" id="uploadExcel" name="filename" className="hidden" onChange={(e) => {
                          handleFileOverride(e, id)
                        }}></input>
                      </label>
                  }
                </div>
                <div className="block xs:hidden sm:hidden">
                  <AuroLogo className ="" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex" >
            <div className="mt-[16px] w-[30%] max-w-[250px] bg-[#F5F5F5]">
              <Filter />
            </div>
            <div className="inline-block align-middle mr-4  w-[70%] flex-1">
              <div className="p-0 mt-[16px] mx-[4px] border-1 border-black min-h-[63vh]">
                <div className="overflow-scroll min-w-[100px] min-h-[63vh] h-full">
                  <table className="w-full divide-y divide-gray-200 dark:divide-gray-700" id="table-to-xls">
                    <thead className="" >
                      <tr className="bg-theme-color" >
                        {
                          columnHeader?.map((header) => (
                            <th scope="col" className="3xl:p-2 whitespace-nowrap lg:p-2 xl:p-2 p-2 lg:text-[15px] xl:text-[15px] 4xl:p-[10px] text-left font-normal text-[14px] 4xl:text-[18px] text-white text-center uppercase">{header.columnName}</th>
                          ))
                        }
                        <th scope="col" className="3xl:p-2 lg:p-2 xl:p-2 p-2 lg:text-[15px] xl:text-[15px] 4xl:p-[10px] text-left font-normal text-[14px] 4xl:text-[18px] text-white uppercase">
                          <span className=""></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-b-1">
                      {loaderAudience ? <tr>
                        <td colSpan={columnHeader.length} className="">
                        </td>
                      </tr> : tableAudience?.length != 0 ? tdData() :
                        <tr>
                          <td className="">
                          </td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



          {

            tableWrongAudience?.length > 0 ?
              <>
                <div className="flex px-8 justify-start mb-1 3xl:pt-4 4xl:pt-7 border-t-2 border-[rgba(22, 22, 22, 0.6)]">
                  <p className="4xl:text-[40px] pt-4 text-[#CC0000] 3xl:text-[30px] font-medium">Unsuccessful data: </p>
                </div>
                <div className="flex px-8 justify-between mb-1 ">

                  <p className="py-4 font-normal 3xl:text-[14px] 4xl:text-[24px] text-[#241C15] italic">Note: Please go to the excel file and correct the following rows as per the error mesage shown in front of every row  and try uploading the same excel sheet again.</p>
                  <div className="px-8 flex justify-end text-[24px] ml-[20px]">
                    <ReactHtmlTableToExcel
                      id="test-table-xls-button"
                      className=" mr-4"
                      table="errortable-to-xls"
                      filename="errortable-to-xls"
                      sheet="tablexls"
                      buttonText={<HiOutlineCloudDownload />}
                    /></div>
                </div>
                <div className="inline-block align-middle w-[100%] mb-3">
                  <div className="p-0 mt-[12px] ml-4 mx-[4px] border-1 border-black mr-4">
                    <div className="overflow-scroll min-h-[69vh] h-full min-w-[100px]">
                      <table className=" min-w-full divide-y divide-gray-200 dark:divide-gray-700" id="errortable-to-xls">
                        <thead className="" >
                          <tr className="bg-[#CC0000]" >
                            {
                              columnHeader?.map((header) => (
                                <th scope="col" className="3xl:p-2 whitespace-nowrap lg:p-2 xl:p-2 p-2 lg:text-[15px] xl:text-[15px] 4xl:p-[10px] text-left font-normal text-[14px] 4xl:text-[18px] text-white text-center uppercase">{header.columnName}</th>
                              ))
                            }
                            <th scope="col" className="3xl:p-2 lg:p-2 xl:p-2 p-2 lg:text-[15px] xl:text-[15px] 4xl:p-[10px] text-left font-normal text-[14px] 4xl:text-[18px] text-white uppercase">
                              <span className=""></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="border-b-1">
                          {loaderAudience ? <tr>
                            <td colSpan={columnHeader.length}>
                            </td>
                          </tr> : tableWrongAudience?.length != 0 ? tdWrongData() :
                            <tr>
                              <td colSpan={columnHeader.length}>
                              </td>
                            </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </> : <></>
          }
        </div>
        <div>
        </div>
      </div>
    </>
  )
}
export default AudienceTable;