import React from "react";
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import profile from '../../../assets/images/default.png';
import camera from "../../../assets/images/Camera.svg";

const AddMemberModal = () => {
  const { addMemeberPopUp,
    setAddMemberPopUp, CreateSubUSer } = useContext(DataContext);
  const [openPopGroup, setOpenPopGroup] = useState(true);
  const [addMemberState, setAddMemberState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    image: ''
  });
  const handleAddMember = () => {
    CreateSubUSer(addMemberState)
  };

  console.log(addMemberState.image , 'addMemberState')
  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px]  xs:mx-auto sm:mx-[50px] md:mx-[100px] mt-16 md:mt-8 lg:mt-10 xl:mt-10 justify-center p-4 xl:p-0 text-center  sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden xl:w-[40%] 2xl:w-[35%] 3xl:w-[35%] 4xl:w-[35%] w-4/5 rounded-lg bg-blue text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-8 pt-5 pb-2 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="rounded-xl m-auto flex flex-col items-center">
                        <div className="relative">
                          <img
                            src={addMemberState.image === '' ?
                              profile : URL.createObjectURL(addMemberState.image)
                            }
                            alt=""
                            className="drop-shadow-myp_filter border-[6px] w-[90px] xs:w-[60px] sm:w-[60px] md:w-[60px] lg:w-[60px] xl:w-[70px] 2xl:w-[90px] 3xl:w-[112px] 4xl:w-[140px] rounded-full border-[#4D86BF]"
                          />

                          <div class="image-upload absolute bg-theme-color right-0 bottom-0 p-2 rounded-full">
                            <label for="file-input">
                              <img className="cursor-pointer" src={camera} alt="" />
                            </label>
                            <input id="file-input" className="hidden" type="file" onChange={(e) => setAddMemberState({ ...addMemberState, image: e.target.files[0] })} />
                          </div>
                        </div>
                        <form action="" className="w-full">
                          <div class="relative pb-6 2xl:pb-2 xl:pb-3 lg:pb-2 md:pb-2 sm:pb-1  xs:pb-1">
                            <label
                              for="first_name"
                              class="leading-4 sm:leading-2 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              id="firstName"
                              name="firstName"
                              className="w-full bg-transparent rounded-md border border-gray-500 focus:border-theme-color xs:text-sm text-base outline-none text-gray-700 py-1 px-3 leading-8 xs:leading-3 sm:leading-4 3xl:leading-4 transition-colors duration-200 ease-in-out"
                              onChange={(e) => setAddMemberState({ ...addMemberState, firstName: e.target.value })}
                            />
                          </div>
                          <div className="relative pb-6 2xl:pb-2 xl:pb-3 lg:pb-2 md:pb-2 sm:pb-1 xs:pb-1">
                            <label
                              for="lastName"
                              className="leading-4 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="lastName"
                              name="lastName"
                              className="w-full bg-transparent rounded-md border border-gray-500 focus:border-theme-color xs:text-sm text-base outline-none text-gray-700 py-1 px-3 leading-8 xs:leading-3 sm:leading-4 3xl:leading-4 transition-colors duration-200 ease-in-out"
                              onChange={(e) => setAddMemberState({ ...addMemberState, lastName: e.target.value })}
                            />
                          </div>
                          <div className="relative pb-6 2xl:pb-2 xl:pb-3 lg:pb-2 md:pb-2 sm:pb-1 xs:pb-1">
                            <label
                              for="email"
                              className="leading-4 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                            >
                              Email id
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className="w-full bg-transparent rounded-md border border-gray-500 focus:border-theme-color xs:text-sm  text-base outline-none text-gray-700 py-1 px-3 leading-8 xs:leading-3 sm:leading-4 3xl:leading-4 transition-colors duration-200 ease-in-out"
                              onChange={(e) => setAddMemberState({ ...addMemberState, email: e.target.value })}
                            />
                          </div>
                          <div className="relative pb-6 2xl:pb-2 xl:pb-3 lg:pb-2 md:pb-2 sm:pb-1 xs:pb-1">
                            <label
                              for="phone"
                              className="leading-4 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                            >
                              Phone number
                            </label>
                            <input
                              type="tel"
                              id="phone"
                              name="phone"
                              className="w-full bg-transparent rounded-md border border-gray-500 focus:border-theme-color xs:text-sm text-base outline-none text-gray-700 py-1 px-3 leading-8 xs:leading-3 sm:leading-4 3xl:leading-4 transition-colors duration-200 ease-in-out"
                              onChange={(e) => setAddMemberState({ ...addMemberState, phoneNumber: e.target.value })}
                            />
                          </div>
                          <div className="relative pb-6 2xl:pb-2 xl:pb-3 lg:pb-2 md:pb-2 sm:pb-1 xs:pb-1">
                            <label
                              for="password"
                              className="leading-4 4xl:text-[16px] 3xl:text-[14] text-[12px] font-medium "
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              id="password"
                              name="password"
                              placeholder="*************"
                              className="w-full bg-transparent rounded-md border border-gray-500 focus:border-theme-color xs:text-sm text-base outline-none text-gray-700 py-1 px-3 leading-8 xs:leading-3 sm:leading-4 3xl:leading-4 transition-colors duration-200 ease-in-out"
                              onChange={(e) => setAddMemberState({ ...addMemberState, password: e.target.value })}
                            />
                          </div>
                          <div className="buttons flex justify-center gap-4 mt-1 pt-2">
                            <button type="button" className="rounded-lg p-[.6rem_1.5rem] xs:p-[5px_10px] sm:p-[5px_10px] lg:p-[5px_15px] md:p-[5px_15px] bg-theme-color text-white xs:text-[11px] sm:text-[11px] md:text-[14px] lg:-text-[12px] xl:text-[13px] 4xl:text-[24px] 3xl:text-[18px] text-[14px]" onClick={() => { handleAddMember() }}>Add Members</button>
                            <button className="rounded-lg p-[.6rem_1.5rem] xs:p-[5px_10px] sm:p-[5px_10px] lg:p-[5px_15px] md:p-[5px_15px] bg-white  text-theme-color xs:text-[11px] sm:text-[11px] md:text-[11px] lg:-text-[12px] xl:text-[13px]  4xl:text-[24px] border border-theme-color 3xl:text-[18px] text-[14px]" onClick={() => { setAddMemberPopUp(false) }}>Cancel</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6">
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default AddMemberModal;
