import React, { useState, createContext, useEffect } from "react";
import apiEndPoints from "../../api/apiEndPoints";
import { json, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
	const BASE_URL = "https://commapi.auroscholar.com/api/";
	const navigate = useNavigate();
	const [loginError, setLoginError] = useState("");
	const [options, setOptions] = useState([]);
	const [loginmailError, setLoginmailError] = useState("");
	const [emailExist, setEmailExist] = useState("");
	const [emailForgetExist, setEmailForgetExist] = useState("");
	const [isVisibleSb, setIsVisibleSb] = useState(true);
	const [otpError, setOtpError] = useState("");
	const [passValidateError, setPassValidateError] = useState("");
	const [forgetEmailState, setForgetEmailState] = useState({
		email: "",
	});
	const [logoutState, setLogoutState] = useState(false);
	const [resetPassError, setResetPassError] = useState("");
	const [businessInfo, setBusinessInfo] = useState({
		businessName: "",
		gst: "",
		authperson: "",
		phoneNumber: "",
		checkbox: false,
		address: "",
		address2: "",
		city: "",
		buisnessState: "",
		zip: "",
		country: "India",
	});
	const [templates, setTemplates] = useState({
		subject: "",
		groupname: "",
		subgroupdata: "",
		image: [],
		attachment: [],
		templateName: "",
	});
	const [deleteGroupId, setDeleteGroupId] = useState();
	const [groupListState, setGroupListState] = useState([]);
	const [columnHeader, setColumnHeader] = useState([]);
	const [tableAudience, setTableAudience] = useState([]);
	const [tableWrongAudience, setTableWrongAudience] = useState([]);
	const [loaderAudience, setLoaderAudience] = useState(false);
	const [filterAudience, setfilterAudience] = useState([]);
	const [groupId, setGroupId] = useState();
	const [showGroupModal, setShowGroupModal] = useState(false);
	const [showColumnModal, setshowColumnModal] = useState(false);
	const [filterData, setFilterData] = useState([]);
	const [deleteModal, setDeleteModal] = useState(false);
	const [errorTablPop, setErrorTablePop] = useState(false);
	const [apiError, setApiError] = useState(false);
	const [apiColumnError, setApiColumnError] = useState(false);
	const [updateGroupNameModal, setUpdateGroupNameModal] = useState(false);
	const [updateColumnModal, setUpdateColumnModal] = useState(false);
	const [senderverifyEmail, setSenderEmailVerify] = useState("");
	const [verifyEmailPopUp, setVerifyEmailPopUp] = useState(false);
	const [verifyEmailPopUpError, setVerifyEmailPopUpError] = useState("");
	const [getVerifyEmail, setGetVerifyEmail] = useState([]);
	const [senderSmsVerify, setSenderSmsVerify] = useState("");
	const [getVerifySms, setGetVerifySms] = useState([]);
	const [senderWhatsappVerify, setSenderWhatsappVerify] = useState("");
	const [getVerifyWhatsApp, setGetVerifyWhatsApp] = useState([]);
	const [categorytypeState, setCategoryTypeState] = useState("Email");
	const [createCategoryState, setCreateCategoryState] = useState({
		categoryName: "",
		categoryImage: [],
	});
	const [getCategoryState, setGetCategoryState] = useState([]);
	const [dltSuccessPopup, setDltSuccessPopup] = useState(false);
	const [dltSmsPopup, setDltSmsPopup] = useState(false);
	const [dltMessageResponse, setDltMessageResponse] = useState();
	const [dltEmailPopUp, setDltEmailPopup] = useState(false);
	const [categorySucessPopup, setCategorySucessPopup] = useState(false);
	const [ivrCreateState, setIvrCreateState] = useState({
		ivrTempName: "",
		ivrSubject: "",
		ivrAudio: [],
	});
	const [ivrStatePopup, createIvrStatePopup] = useState(false);
	const [getStateIvrTemplate, setGetStateIvrTemplate] = useState([]);
	const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
	const [deleteIvrTempId, setDeleteIvrTempId] = useState({
		templateId: "",
		category: "",
	});
	const [updateIvrTempPopup, setUpdateIvrTempPopup] = useState(false);
	const [saveCategoryNav, setSaveCategoryNav] = useState(false);
	const [getStateEmailTemplate, setGetStateEmailTemplate] = useState([]);
	const [getStateWhatsappTemplate, setGetStateWhatsappTemplate] = useState([]);
	const [getStateMessageTemplate, setGetStateMessageTemplate] = useState([]);
	const [categoryUpdatePopup, setCategoryUpdatePopup] = useState(false);
	const [successDltTempPop, setSuccesDltTempPop] = useState(false);
	const [recurringMailPopup, setRecurringMailPopup] = useState(false);
	const [scheduleEmailPopup, setscheduleEmailPopup] = useState(false);
	const [pickDateTime, setPickDateTime] = useState(false);
	const [calenderPopUp, setCalenderPopUp] = useState(false);
	const [clockPopUp, setClockPopUp] = useState(false);
	const [scheduleRecurringPopUp, setScheduleRecurringPopUp] = useState(false);
	const [approvedEmail, setApprovedEmail] = useState([]);
	const [sendEmailData, setSendEmailData] = useState({
		from: "",
		groupAudience: "",
		to: "",
		subject: "",
		body: "",
		groupId: "",
		startDate: "",
		endDate: "",
		every: "",
		time: "",
		categoriesType: "",
		daysofweek: {
			sunday: false,
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
		},
	});
	const [emaiSentPopup, setEmailSentPopup] = useState(false);
	const [emailSchedulePopup, setEmailSchedulePopup] = useState(false);
	const [useTempalatePopup, setUseTemplatePopup] = useState(false);
	const [campaignCategoryState, setCampaignCategoryState] = useState({
		categoryType: "",
		groupId: "",
	});
	const [editPopup, setEditPopUp] = useState(false);
	const [editRowState, setEditRowState] = useState([]);
	const [selectTempState, setSelectTempState] = useState([]);
	const [checkUserUpdate, setCheckUSerUpdate] = useState(false);
	const [historyCamapaignState, setHistoryCampaignState] = useState([]);
	const [stateArr, setStateArr] = useState([]);
	const [stateDistrict, setStateDistrict] = useState([]);
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState();
	const [checkLogin, setCheckLogin] = useState(false);
	const [scheduledMeetingState, setscheduledMeetingState] = useState([]);
	const [copyGroupPopUp, setCopyGroupPopUp] = useState(false);
	const [myProfile, setMyProfile] = useState();
	const [addMemeberPopUp, setAddMemberPopUp] = useState(false);
	const [deletMemberPopUp, setDeleteMemberPopUp] = useState(false);
	const [getsubUserState, setGetSubUserState] = useState([]);
	const [deleteSubuserId, setDeleteSubUserId] = useState("");
	const [emailCategoryList, setEmailCategoryList] = useState([]);
	const [smsCategoryList, setSmsCategoryList] = useState([]);
	const [whatsappCategoryList, setWhatsappCategoryList] = useState([]);
	const [ivrCategoryList, setIvrCategoryList] = useState([]);
	const [getemailManageTemp, setGetEmailManageTemp] = useState([]);

	//                                              payment integration
	const toastSuccess = (e) => toast.success(e);
	const toastError = (e) => toast.error(e);

	//                                              payment integration
	const checkoutHandler = async (amount) => {
		const {
			data: { key },
		} = await axios.get(`${BASE_URL}payment/getkey`);

		const {
			data: { order },
		} = await axios.post(`${BASE_URL}payment/checkout`, {
			amount,
		});
		const options = {
			key,
			amount: order.amount,
			currency: "INR",
			name: "Communication Portal",
			description: "Testing in communication portal payment",
			image:
				"https://egsgroup.files.wordpress.com/2017/02/payment-successful.png",
			order_id: order.id,
			callback_url: `${BASE_URL}payment/paymentverification`,
			prefill: {
				name: "Gaurav Kumar",
				email: "gaurav.kumar@example.com",
				contact: "9999999999",
			},
			notes: {
				address: "Razorpay Corporate Office",
			},
			theme: {
				color: "#121212",
			},
		};
		const razor = new window.Razorpay(options);
		razor.open();
	};

	//                                                  Login API
	const Login = (state) => {
		setLoginError("");
		setLoginmailError("");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: state.email,
			password: state.password,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.LOGIN}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					localStorage.setItem(
						"userInfo",
						JSON.stringify(result.result.result1)
					);
					localStorage.setItem("token", JSON.stringify(result.result.token));
					navigate("/group-dashboard");
					setCheckLogin(true);
				} else if (result.status_code === 401 && result.success === false) {
					setLoginError(result.message);
				} else if (result.status_code === 404 && result.success === false) {
					setLoginmailError(result.message);
				} else if (result.status_code === 400) {
					setLoginmailError(result.result.error);
				}
			})
			.catch((error) => console.log("error", error));
	};

	// Login As SubUser

	const LoginSubUser = (state) => {
		setLoginError("");
		setLoginmailError("");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			emailId: state.email,
			password: state.password,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch("http://15.206.80.114:8000/api/subUser/login", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					localStorage.setItem(
						"userInfo",
						JSON.stringify(result.result.result)
					);
					localStorage.setItem("token", JSON.stringify(result.result.token));
					navigate("/group-dashboard");
					setCheckLogin(true);
				} else if (result.status_code === 401 && result.success === false) {
					setLoginError(result.message);
				} else if (result.status_code === 404 && result.success === false) {
					setLoginmailError(result.message);
				} else if (result.status_code === 400) {
					setLoginmailError(result.result.error);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                    Sign-Up

	const Signup = (state) => {
		setOtpError("");
		setPassValidateError("");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			name: state.name,
			email: state.email,
			password: state.password,
			confirmPassword: state.cpassword,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.REGISTER}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 201) {
					localStorage.setItem("userInfo", JSON.stringify(result.result.res1));
					localStorage.setItem("token", JSON.stringify(result.result.token));
					navigate("/user-email-verification");
				} else if (result.status_code === 400) {
					setPassValidateError(result.result.error);
				} else if (result.status_code === 208) {
					setEmailExist(result.message);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Verify OTP
	const userVerification = (state) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: userData.email,
			otp: state,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.VERIFYOTP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					navigate("/info");
				} else if (result.message === "Email already verify") {
					navigate("/info");
				} else if (result.status_code === 403) {
					setOtpError(result.message);
				} else if (result.status_code === 404) {
					setOtpError(result.message);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Resend OTP User-Verify
	const reSendOtp = () => {
		setOtpError("");
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: userData.email,
		});

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.RESEND_OTP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					toastSuccess("OTP sent successfully");
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Business Details
	const SendAllInfo = (state) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			businessName: state.businessName,
			phoneNumber: state.phoneNumber,
			addressLine1: state.address,
			addressLine2: state.address2,
			postalCode: state.zip,
			authorization: state.authperson,
			city: state.city,
			state: state.buisnessState,
			country: state.country,
			gstNumber: state.gst,
		});

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.SENDALLINFO}${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					navigate("/select-plan");
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Forget Email Verify
	const forgetEmail = (state) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: state.email,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.FORGET_PASS}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					navigate("/verify-otp");
				} else if (result.status_code === 404) {
					setEmailForgetExist(result.message);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Forget Otp Verify
	const forgetOtp = (finalValue, forgetEmailState) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: forgetEmailState,
			otp: finalValue,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.FORGET_OTP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					navigate("/reset-password");
				} else if (result.status_code === 403) {
					setOtpError(result.message);
				}
			})
			.catch((error) => console.log("error", error));
	};
	//                                                  India State Fetch
	const indiaStateApi = () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch("http://15.206.80.114:8000/api/user/countryState", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setStateArr(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                  India District Fetch
	const indiaDistrictApi = (id) => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/user/stateDistrict/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setStateDistrict(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Forget Resend OTP
	const reForgetOtp = (forgetEmailState) => {
		setOtpError("");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: forgetEmailState,
		});

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.RESEND_OTP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					toastSuccess("OTP sent successfully");
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Forget Update Password

	const forgetUpdatePassword = (state, forgetEmailState) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: forgetEmailState,
			newPassword: state.newPassword,
			confirmNewPassword: state.cNewPassword,
		});

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.UPDATE_PASSWORD}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					navigate("/");
				} else {
					setResetPassError(result.result.error);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Add Group
	const AddGroup = (name, setGroupState) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("token", userToken);

		var raw = JSON.stringify({
			userId: userData._id,
			groupName: name,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.NEW_GROUP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 201) {
					localStorage.setItem("groupInfo", JSON.stringify(result.result));
					setGroupState({
						group: "",
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Update Group
	const UpdateGroupName = (name, id) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			groupName: name,
		});

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.UPDATE_GROUP}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setUpdateGroupNameModal(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Group Delete

	const groupDelete = (id) => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		var raw = "";
		var requestOptions = {
			method: "DELETE",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.DELETE_GROUP}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					groupList();
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Group List

	const groupList = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.GET_GROUP}${userData?._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200 && result.message === "Success") {
					setGroupListState(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Create Column

	const createColumn = (state) => {
		const groupInfo = JSON.parse(localStorage.getItem("groupInfo"));
		const finalData = state.map((e) => ({
			...e,
			groupId: groupInfo._id,
		}));
		for (let i = 0; i < finalData.length; i++) {
			if (finalData[i].maximumLength === "") {
				finalData[i].maximumLength = "20";
			}
		}
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify(finalData);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.ADD_COLUMN}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201 && result.success === true) {
					setshowColumnModal(true);
					localStorage.removeItem("groupInfo");
				} else if (result.status_code === 500 && result.success === false) {
					setApiColumnError(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Update Column

	const updateColumn = (state, id) => {
		const finalData = state.map((e) => ({
			...e,
			groupId: id,
		}));
		for (let i = 0; i < finalData.length; i++) {
			if (finalData[i].maximumLength === "") {
				finalData[i].maximumLength = "20";
			}
		}
		console.log(finalData, "finalData");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify(finalData);

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.UPDATE_COLUMN}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setUpdateColumnModal(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Get Column

	const getColumn = (id) => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_SPECIFIC_COLUMN}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true && result.status_code === 200) {
					setColumnHeader(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Upload Excel File

	const uploadExcel = (data, id) => {
		console.log(data, id);
		var formdata = new FormData();
		formdata.append("file", data, "file");
		formdata.append("groupId", id);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.UPLOAD_EXCEL}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if ((result.success === 200, result.status_code === 200)) {
					getAudienceData(id);
					getNotEnterData(id);
					// { result.result[0].arrayNotEnterInDb.length > 0 ? setErrorTablePop(true) : <></> }
				} else if (result.status_code === 403) {
					// alert(result.message)
					setApiError(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                  Get All Active Data
	const getAudienceData = (id) => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_AUDIENCE_DATA}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setTableAudience(result.result);
					setfilterAudience(result.result);
				} else if (result.status_code === 208) {
					setLoaderAudience(false);
					setfilterAudience([]);
					setTableAudience([]);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Get Not enter in Db Data

	const getNotEnterData = (id) => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/manageContact/getExcelNotEnterDb/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setTableWrongAudience(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Update Excel File

	const updateExcel = (data, id) => {
		var formdata = new FormData();
		formdata.append("file", data, "file");
		formdata.append("groupId", id);

		var requestOptions = {
			method: "PUT",
			body: formdata,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.UPDATE_EXCEL}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getAudienceData(id);
					getNotEnterData(id);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Specific Filter

	const filterSpecific = (state, id) => {
		setLoaderAudience(true);
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify(state);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_FILTER_DATA}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setTableAudience(result.result);
					setTimeout(() => setLoaderAudience(false));
				} else if (result.status_code === 404) {
					setTableAudience("");
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 email-user-verify-id

	const verifySenderEmail = (email) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: email,
			userId: userData._id,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.VERIFY_SENDER_EMAIL}`, requestOptions)
			.then((response) => response.json())
			.then((result) => console.log(result))
			.catch((error) => console.log("error", error));
	};

	//                                                 email-user-Otp-Verify

	const otpVerifyUserEmail = (email, otp) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: email,
			otp: otp,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.VERIFY_SENDER_EMAIL_OTP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setVerifyEmailPopUp(false);
					setDltEmailPopup(true);
					getEmailSenderId();
					setSenderEmailVerify("");
				} else if (result.status_code === 403) {
					setVerifyEmailPopUpError(result.message);
				} else if (result.status_code === 208) {
					setVerifyEmailPopUpError(result.message);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 email-user-Otp-Resend
	const ResendOtpVerifyUserEmail = (email) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: email,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.VERIFY_RESEND_EMAIL_OTP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => console.log(result))
			.catch((error) => console.log("error", error));
	};

	//                                                 Get-email-sender-id

	const getEmailSenderId = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.GET_EMAIL_SENDER_ID}${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setGetVerifyEmail(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                 Sms-dlt-verify-id
	const verifyDltSms = (sms) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			dlttype: "SMS",
			Id: sms,
			userId: userData._id,
			businessName: userData.businessName,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.VERIFY_DLT_SMS}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setSenderSmsVerify("");
					setDltMessageResponse(result.message);
					setDltSmsPopup(true);
					getSmsDltId();
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                get-Sms-dlt-verify-id

	const getSmsDltId = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.GET_SMS_DLT_ID}${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setGetVerifySms(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                WhatsApp-dlt-verify-id

	const verifyDltWhatsApp = (whatsapp) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("token", userToken);

		var raw = JSON.stringify({
			dlttype: "WHATSAPP",
			Id: whatsapp,
			userId: userData._id,
			businessName: userData.businessName,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.VERIFY_DLT_WHATSAPP}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setDltMessageResponse(result.message);
					setSenderWhatsappVerify("");
					setDltSuccessPopup(true);
					getWhatsAppDltId();
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                get-WhatsApp-dlt-verify-id

	const getWhatsAppDltId = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.GET_WHATSAPP_DLT_ID}${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setGetVerifyWhatsApp(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                createCategory
	const createCategory = (state) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		var formdata = new FormData();
		formdata.append("categoryName", state.categoryName);
		formdata.append("image", state.categoryImage);
		formdata.append("userId", userData._id);
		formdata.append("categoriesType", categorytypeState);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.CREATE_CATEGORY}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setCreateCategoryState({
						categoryName: "",
						categoryImage: [],
					});
					setCategorySucessPopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	};
	//                                                createCategory and Nav

	const createNavCategory = (state) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		var formdata = new FormData();
		formdata.append("categoryName", state.categoryName);
		formdata.append("image", state.categoryImage);
		formdata.append("userId", userData._id);
		formdata.append("categoriesType", categorytypeState);

		var requestOptions = {
			method: "POST",
			body: formdata,
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.CREATE_CATEGORY}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					if (categorytypeState === "Email") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`create-template/${result.result._id}`);
					} else if (categorytypeState === "Message") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`message-create-template/${result.result._id}`);
					} else if (categorytypeState === "Whatsapp") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`create-template/${result.result._id}`);
					} else if (categorytypeState === "IVR") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`ivr-create-list/${result.result._id}`);
					}
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Update Category
	const updateCategory = (state, id) => {
		var formdata = new FormData();
		formdata.append("categoryName", state.categoryName);
		formdata.append("image", state.categoryImage);
		formdata.append("categoriesType", categorytypeState);

		var requestOptions = {
			method: "PUT",
			body: formdata,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.UPDATE_CATEGORY}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setCreateCategoryState({
						categoryName: "",
						categoryImage: [],
					});
					setCategoryUpdatePopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//
	//                                                update and Nav

	const updateNavCategory = (state, id) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var formdata = new FormData();
		formdata.append("categoryName", state.categoryName);
		formdata.append("image", state.categoryImage);
		formdata.append("categoriesType", categorytypeState);

		var requestOptions = {
			method: "PUT",
			body: formdata,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.UPDATE_CATEGORY}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					if (categorytypeState === "Email") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`create-template/${result.result._id}`);
					} else if (categorytypeState === "Message") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`message-create-template/${result.result._id}`);
					} else if (categorytypeState === "Whatsapp") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`create-template/${result.result._id}`);
					} else if (categorytypeState === "IVR") {
						setCreateCategoryState({
							categoryName: "",
							categoryImage: [],
						});
						navigate(`ivr-create-list/${result.result._id}`);
					}
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                GetCategory

	const getCategory = (data) => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);

		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_CATEGORY}${data}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setGetCategoryState(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getEmailCategory = () => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);

		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_CATEGORY}${"Email"}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setEmailCategoryList(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getSmsCategory = () => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);

		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_CATEGORY}${"Message"}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setSmsCategoryList(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getWhatsAppCategory = () => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);

		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.GET_CATEGORY}${"Whatsapp"}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setWhatsappCategoryList(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getIvrCategory = () => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);

		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_CATEGORY}${"IVR"}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setIvrCategoryList(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Create IVR Template
	const ivrTemplateCreate = (id, state) => {
		var formdata = new FormData();
		formdata.append("categoryId", id);
		formdata.append("subject", state.ivrSubject);
		formdata.append("audioFile", state.ivrAudio);
		formdata.append("templateName", state.ivrTempName);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.CREATE_IVR}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					createIvrStatePopup(true);
					setIvrCreateState({
						ivrTempName: "",
						ivrSubject: "",
						ivrAudio: [],
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Get IVR Template

	const getIvrTemplate = (id) => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.Get_IVR}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setGetStateIvrTemplate(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	// Add email templates
	function smsTemplateCreate(
		subject,
		content,
		categoryId,
		templateName,
		attachmnet
	) {
		console.log(subject, content, categoryId, templateName, attachmnet);
		const userToken = JSON.parse(localStorage.getItem("token"));
		var formdata = new FormData();
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		formdata.append("subject", subject);
		formdata.append("content", content);
		formdata.append("categoryId", categoryId);
		formdata.append("templateName", templateName);
		for (let i = 0; i < attachmnet.length; i++) {
			console.log(attachmnet[i]);
			formdata.append("attachment", attachmnet[i]);
		}
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(
			"http://15.206.80.114:8000/api/managetemplate/addEmailTemplate",
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					createIvrStatePopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	}

	function whatappTemplatesCreate(
		subject,
		content,
		categoryId,
		templateName,
		attachmnet
	) {
		console.log(subject, content, categoryId, templateName, attachmnet);
		var formdata = new FormData();
		formdata.append("subject", subject);
		formdata.append("categoryId", categoryId);
		formdata.append("templateName", templateName);
		formdata.append("content", content);
		for (let i = 0; i < attachmnet.length; i++) {
			console.log(attachmnet[i]);
			formdata.append("attachment", attachmnet[i]);
		}
		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(
			"http://15.206.80.114:8000/api/managetemplate/addWhatsappTemplate",
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					console.log("popUp");
					setSuccesDltTempPop(true);
				}
			})
			.catch((error) => console.log("error", error));
	}

	//                                                Update IVR Template
	const ivrTemplateUpdate = (state, id) => {
		console.log(state, id);
		var formdata = new FormData();
		formdata.append("audioFile", state.ivrAudio);
		formdata.append("subject", state.ivrSubject);
		formdata.append("templateName", state.ivrTempName);

		var requestOptions = {
			method: "PUT",
			body: formdata,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.UPDATE_TEMPLATE_IVR}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setUpdateIvrTempPopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Get Email Template

	const getEmailTemplate = (id) => {
		console.log(id, "data state");

		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.GET_EMAIL_TEMPLATE}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success === true) {
					console.log(result.result);
					setGetStateEmailTemplate(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Get WhatsApp Template

	const getWhatsappTemplate = (id) => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.GET_WHATSAPP_TEMPLATE}${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setGetStateWhatsappTemplate(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Create Message Template
	const createMessageTemplate = (data, id) => {
		console.log(data.subject, id);
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			subject: data.subject,
			categoryId: id,
			templateName: data.templateName,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(`${BASE_URL}${apiEndPoints.CREATE_MESSAGE_TEMPLATE}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					console.log("popUp");
					setSuccesDltTempPop(true);
					setTemplates({
						subject: "",
						groupname: "",
						subgroupdata: "",
						image: [],
						attachment: [],
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Get Message Template

	const getMessageTemplate = (id) => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.GET_MESSAGE_TEMPLATE}${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setGetStateMessageTemplate(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Delete IVR Template
	const deleteIvrTemplate = (id, categoryId) => {
		var requestOptions = {
			method: "DELETE",
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.DELETE_IVR}${id}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getIvrTemplate(categoryId);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Delete Message Template

	const deleteMessageTemplate = (id, categoryId) => {
		console.log("Message Template Deleted");
		var requestOptions = {
			method: "DELETE",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.DELETE_Message_Template}${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getMessageTemplate(categoryId);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Delete Whatsapp Template

	const deleteWhatsappTemplate = (id, categoryId) => {
		console.log("Whatsapp Template Deleted");
		var requestOptions = {
			method: "DELETE",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.DELETE_WHATSAPP_TEMPLATE}${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getWhatsappTemplate(categoryId);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                Delete Email Template

	const deleteEmailTemplate = (id, categoryId) => {
		console.log("EMail Template Deleted");
		var requestOptions = {
			method: "DELETE",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.DELETE_EMAIL_TEMPLATE}${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getEmailTemplate(categoryId);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                                update Email templates

	function updateEmailTemplates(subject, content, id, templateName) {
		console.log(subject, content, id, templateName, "result");
		var formdata = new FormData();
		formdata.append("subject", subject);
		formdata.append("content", content);
		formdata.append("templateName", templateName);

		var requestOptions = {
			method: "PUT",
			body: formdata,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/managetemplate/updateEmailTemplate/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setUpdateIvrTempPopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	}

	//update whatsapp templates

	function updateWhatsappTemplates(subject, content, id, templateName) {
		var formdata = new FormData();
		formdata.append("content", content);
		formdata.append("subject", subject);
		formdata.append("templateName", templateName);

		var requestOptions = {
			method: "PUT",
			body: formdata,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/managetemplate/updateWhatsappTemplate/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setUpdateIvrTempPopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	}

	//                                        Get approved All email
	const getAllApprovedEmail = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			`${BASE_URL}${apiEndPoints.APPROVED_ALL_EMAIL}${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setApprovedEmail(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                      Send  email Without Scheduling

	const directSendEmail = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		let userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		var formdata = new FormData();
		formdata.append("userId", userData._id);
		formdata.append("groupId", "642d5186ef112f16d8c0e5d7");
		formdata.append("from", sendEmailData.from);
		formdata.append("categoriesType", "Email");
		formdata.append("subject", sendEmailData.subject);
		formdata.append("content", sendEmailData.body);
		formdata.append("to", JSON.stringify(sendEmailData.to));
		formdata.append("groupName", sendEmailData.groupAudience);
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.SEND_DIRECT_EMAIL}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setSendEmailData({
						from: "",
						groupAudience: "",
						to: "",
						subject: "",
						body: "",
						groupId: "",
						startDate: "",
						endDate: "",
						every: "",
						time: "",
						categoriesType: "",
						daysofweek: {
							sunday: false,
							monday: false,
							tuesday: false,
							wednesday: false,
							thursday: false,
							friday: false,
							saturday: false,
						},
					});
					setEmailSentPopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//                                      Send  email With Scheduling

	const ScheduleEmail = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		let userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		var formdata = new FormData();
		formdata.append("userId", userData._id);
		formdata.append("groupId", "642d5186ef112f16d8c0e5d7");
		formdata.append("from", sendEmailData.from);
		formdata.append("categoriesType", "Email");
		formdata.append("subject", sendEmailData.subject);
		formdata.append("content", sendEmailData.body);
		formdata.append("to", JSON.stringify(sendEmailData.to));
		formdata.append("startDate", sendEmailData.startDate);
		formdata.append("endDate", sendEmailData.endDate);
		formdata.append("every", sendEmailData.every);
		formdata.append("time", sendEmailData.time);
		formdata.append("daysofweek", JSON.stringify(sendEmailData.daysofweek));
		formdata.append("groupName", sendEmailData.groupAudience);

		var requestOptions = {
			method: "POST",
			body: formdata,
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(`${BASE_URL}${apiEndPoints.SCHEDULE_EMAIL}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setSendEmailData({
						from: "",
						groupAudience: "",
						to: "",
						subject: "",
						body: "",
						groupId: "",
						startDate: "",
						endDate: "",
						every: "",
						time: "",
						categoriesType: "",
						daysofweek: {
							sunday: false,
							monday: false,
							tuesday: false,
							wednesday: false,
							thursday: false,
							friday: false,
							saturday: false,
						},
					});
					setEmailSchedulePopup(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const GetSelectTemplate = (id) => {
		console.log(id, "idddd");
		var raw = "";

		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/managetemplate/getEmailTemplate/allCategoryTemplet/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setSelectTempState(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const UpdateAudienceUser = (id, updateData) => {
		delete updateData.audienceType;
		console.log(updateData, "updatedata");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify(updateData);
		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/manageContact/updateSinglePeople/${updateData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getAudienceData(id);
					getNotEnterData(id);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const UpdateNotEnterDb = (id, updateData) => {
		delete updateData.audienceType;
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify(updateData);

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/manageContact/updateNotEnterDbSinglePeople/${updateData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getAudienceData(id);
					getNotEnterData(id);
				} else {
					alert(result.message);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const ActiveInactive = (user_id, state, id) => {
		console.log(user_id, state, id);
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			audienceType: state,
		});

		var requestOptions = {
			method: "DELETE",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/manageContact/deleteAudience/SetStatus/${user_id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getAudienceData(id);
				}
			})
			.catch((error) => console.log("error", error));
	};

	// History Campaign

	const HistoryCampaigns = () => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var raw = "";

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/createcampaign/getsentschedules`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setHistoryCampaignState(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	// Delete History Campaign
	const DeleteSentEmail = (id) => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "DELETE",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/createcampaign/deletesent/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					HistoryCampaigns();
				}
			})
			.catch((error) => console.log("error", error));
	};

	// Delete Schedule Campaign
	const DeleteScheduleEmail = (id) => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "DELETE",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/createcampaign/deleteschedule/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					ScheduledMeetings();
				}
			})
			.catch((error) => console.log("error", error));
	};

	const ScheduledMeetings = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/createcampaign/getpendingschedules/${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					// setHistoryCampaignState(result.result);
					setscheduledMeetingState(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	//  Copy All data with table data
	const CopyAllData = (id) => {
		var requestOptions = {
			method: "POST",
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/group/copyWithData/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setCopyGroupPopUp(false);
					groupList();
				}
			})
			.catch((error) => console.log("error", error));
	};

	// Copy data without table data only row field

	const CopyData = (id) => {
		var raw = "";

		var requestOptions = {
			method: "POST",
			body: raw,
			redirect: "follow",
		};
		fetch(
			`http://15.206.80.114:8000/api/group/copyWithoutData/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setCopyGroupPopUp(false);
					groupList();
				}
			})
			.catch((error) => console.log("error", error));
	};

	// Get My profile Data

	const GetMyProfile = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/user/getUser/${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setMyProfile(result.result);
					localStorage.setItem("userInfo", JSON.stringify(result.result));
				}
			})
			.catch((error) => console.log("error", error));
	};

	//  Post My Profile Data

	const UpdateMyProfile = (state) => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var formdata = new FormData();
		formdata.append("name", state.name);
		formdata.append("phoneNumber", state.phoneNumber);
		formdata.append("profile", state.profile);
		formdata.append("businessName", state.businessName);
		formdata.append("authorization", state.authorization);
		formdata.append("gstNumber", state.gstNumber);
		formdata.append("dateofbirth", state.dateofbirth);

		var requestOptions = {
			method: "PUT",
			body: formdata,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/user/updateById/${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setMyProfile(result.result);
					GetMyProfile();
				}
			})
			.catch((error) => console.log("error", error));
	};

	const CreateSubUSer = (state) => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);

		var formdata = new FormData();
		formdata.append("firstName", state.firstName);
		formdata.append("lastName", state.lastName);
		formdata.append("image", state.image);
		formdata.append("emailId", state.email);
		formdata.append("phoneNumber", state.phoneNumber);
		formdata.append("password", state.password);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch("http://15.206.80.114:8000/api/subUser/createSubUser", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setAddMemberPopUp(false);
					getSubUser();
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getSubUser = () => {
		let userData = JSON.parse(localStorage.getItem("userInfo"));
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/subUser/getSubUser/findAll/${userData._id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					setGetSubUserState(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const DeleteSubUSer = () => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			status: "Active",
		});

		var requestOptions = {
			method: "DELETE",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/subUser/blockOrUnblock/${deleteSubuserId}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 200) {
					getSubUser();
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getManageTempAdmin = (value) => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			categories: value,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"http://15.206.80.114:8000/api/managetemplate/getEmailTemplates",
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.status_code === 201) {
					setGetEmailManageTemp(result.result);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const givePermission = (id, permission) => {
		const userToken = JSON.parse(localStorage.getItem("token"));
		var myHeaders = new Headers();
		myHeaders.append("token", userToken);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			permission,
		});

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`http://15.206.80.114:8000/api/subUser/updateSubUserPermission/${id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => console.log(result))
			.catch((error) => console.log("error", error));
	};

	useEffect(() => {
		if (
			window.location.pathname == "/" ||
			window.location.pathname == "/login" ||
			window.location.pathname == "/registration" ||
			window.location.pathname == "/subuser-login"
		) {
			localStorage.removeItem("userInfo");
		} else if (
			window.location.pathname == "/reset-password" ||
			window.location.pathname == "/terms" ||
			window.location.pathname == "/upgrade-plan" ||
			window.location.pathname == "/user-email-verification" ||
			window.location.pathname == "/verify-otp" ||
			window.location.pathname == "/select-plan" ||
			window.location.pathname == "/info" ||
			window.location.pathname == "/final-step" ||
			window.location.pathname == "/forgot-password" ||
			window.location.pathname == "/business-address"
		) {
			setCheckLogin(false);
		} else {
			setCheckLogin(true);
		}
	}, [window.location.pathname]);

	return (
		<DataContext.Provider
			value={{
				updateWhatsappTemplates,
				updateEmailTemplates,
				whatappTemplatesCreate,
				smsTemplateCreate,
				businessInfo,
				SendAllInfo,
				setBusinessInfo,
				Login,
				setLoginError,
				loginError,
				setLoginmailError,
				loginmailError,
				emailExist,
				setResetPassError,
				resetPassError,
				Signup,
				setPassValidateError,
				passValidateError,
				otpError,
				userVerification,
				reSendOtp,
				forgetEmail,
				emailForgetExist,
				setEmailForgetExist,
				forgetEmailState,
				setForgetEmailState,
				forgetOtp,
				forgetUpdatePassword,
				reForgetOtp,
				checkoutHandler,
				showGroupModal,
				setShowGroupModal,
				deleteModal,
				setDeleteModal,
				AddGroup,
				groupList,
				groupListState,
				setGroupListState,
				groupDelete,
				createColumn,
				getColumn,
				columnHeader,
				groupId,
				setGroupId,
				UpdateGroupName,
				uploadExcel,
				getAudienceData,
				tableAudience,
				loaderAudience,
				setLoaderAudience,
				filterAudience,
				setTableAudience,
				tableWrongAudience,
				setTableWrongAudience,
				filterSpecific,
				deleteMessageTemplate,
				filterData,
				setFilterData,
				deleteGroupId,
				setDeleteGroupId,
				showColumnModal,
				setshowColumnModal,
				errorTablPop,
				setErrorTablePop,
				updateExcel,
				logoutState,
				setLogoutState,
				updateColumn,
				apiError,
				setApiError,
				apiColumnError,
				setApiColumnError,
				updateGroupNameModal,
				setUpdateGroupNameModal,
				updateColumnModal,
				setUpdateColumnModal,
				senderverifyEmail,
				setSenderEmailVerify,
				verifyEmailPopUp,
				setVerifyEmailPopUp,
				verifySenderEmail,
				otpVerifyUserEmail,
				ResendOtpVerifyUserEmail,
				verifyEmailPopUpError,
				setVerifyEmailPopUpError,
				getEmailSenderId,
				getVerifyEmail,
				setGetVerifyEmail,
				senderSmsVerify,
				setSenderSmsVerify,
				verifyDltSms,
				getSmsDltId,
				getVerifySms,
				setGetVerifySms,
				senderWhatsappVerify,
				setSenderWhatsappVerify,
				verifyDltWhatsApp,
				getWhatsAppDltId,
				getVerifyWhatsApp,
				setGetVerifyWhatsApp,
				setTemplates,
				templates,
				options,
				setOptions,
				categorytypeState,
				setCategoryTypeState,
				createCategory,
				createCategoryState,
				setCreateCategoryState,
				getCategory,
				getCategoryState,
				setGetCategoryState,
				dltSuccessPopup,
				setDltSuccessPopup,
				dltSmsPopup,
				setDltSmsPopup,
				dltMessageResponse,
				setDltMessageResponse,
				dltEmailPopUp,
				setDltEmailPopup,
				categorySucessPopup,
				setCategorySucessPopup,
				ivrCreateState,
				setIvrCreateState,
				ivrTemplateCreate,
				ivrStatePopup,
				createIvrStatePopup,
				getIvrTemplate,
				getStateIvrTemplate,
				setGetStateIvrTemplate,
				deleteMessageTemplate,
				deleteIvrTemplate,
				deleteTemplateModal,
				setDeleteTemplateModal,
				deleteIvrTempId,
				setDeleteIvrTempId,
				ivrTemplateUpdate,
				updateIvrTempPopup,
				setUpdateIvrTempPopup,
				saveCategoryNav,
				setSaveCategoryNav,
				getEmailTemplate,
				getStateEmailTemplate,
				setGetStateEmailTemplate,
				getWhatsappTemplate,
				getStateWhatsappTemplate,
				setGetStateWhatsappTemplate,
				createMessageTemplate,
				getMessageTemplate,
				getStateMessageTemplate,
				setGetStateMessageTemplate,
				updateCategory,
				categoryUpdatePopup,
				setCategoryUpdatePopup,
				createNavCategory,
				deleteEmailTemplate,
				deleteWhatsappTemplate,
				updateNavCategory,
				successDltTempPop,
				setSuccesDltTempPop,
				indiaStateApi,
				recurringMailPopup,
				setRecurringMailPopup,
				scheduleEmailPopup,
				setscheduleEmailPopup,
				pickDateTime,
				setPickDateTime,
				calenderPopUp,
				setCalenderPopUp,
				clockPopUp,
				setClockPopUp,
				scheduleRecurringPopUp,
				setScheduleRecurringPopUp,
				getAllApprovedEmail,
				approvedEmail,
				setApprovedEmail,
				sendEmailData,
				setSendEmailData,
				directSendEmail,
				ScheduleEmail,
				emaiSentPopup,
				setEmailSentPopup,
				emailSchedulePopup,
				setEmailSchedulePopup,
				useTempalatePopup,
				setUseTemplatePopup,
				campaignCategoryState,
				setCampaignCategoryState,
				GetSelectTemplate,
				editPopup,
				setEditPopUp,
				editRowState,
				setEditRowState,
				selectTempState,
				setSelectTempState,
				getNotEnterData,
				UpdateAudienceUser,
				checkUserUpdate,
				setCheckUSerUpdate,
				UpdateNotEnterDb,
				ActiveInactive,
				HistoryCampaigns,
				historyCamapaignState,
				setHistoryCampaignState,
				stateArr,
				setStateArr,
				indiaDistrictApi,
				stateDistrict,
				setStateDistrict,
				selectedEmailTemplate,
				setSelectedEmailTemplate,
				checkLogin,
				setCheckLogin,
				ScheduledMeetings,
				scheduledMeetingState,
				setscheduledMeetingState,
				copyGroupPopUp,
				setCopyGroupPopUp,
				CopyAllData,
				CopyData,
				myProfile,
				setMyProfile,
				GetMyProfile,
				UpdateMyProfile,
				addMemeberPopUp,
				setAddMemberPopUp,
				deletMemberPopUp,
				setDeleteMemberPopUp,
				CreateSubUSer,
				getSubUser,
				getsubUserState,
				setGetSubUserState,
				deleteSubuserId,
				setDeleteSubUserId,
				DeleteSubUSer,
				DeleteSentEmail,
				DeleteScheduleEmail,
				isVisibleSb,
				setIsVisibleSb,
				getEmailCategory,
				emailCategoryList,
				getSmsCategory,
				smsCategoryList,
				getWhatsAppCategory,
				whatsappCategoryList,
				getIvrCategory,
				ivrCategoryList,
				getManageTempAdmin,
				getemailManageTemp,
				givePermission,
				LoginSubUser,
			}}
		>
			{children}
		</DataContext.Provider>
	);
};
