import { useContext, useEffect, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import SideNavBar from "../SideNavBar/SideNavBar";
import { DataContext } from "../../context/data/DataState";
import RecurringMail from "./CampaignModals/RecurringMail";
import ScheduleMail from "./CampaignModals/ScheduleMail";
import { BsAirplane, BsChevronDown } from 'react-icons/bs';
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PickDateTime from "./CampaignModals/PickDateTime";
import Calender from "./CampaignModals/Calender";
import ClockWatch from "./CampaignModals/ClockWatch";
import ScheduleRecurring from "./CampaignModals/ScheduleRecurring";
import { useRef } from "react";
import ImageResize from 'quill-image-resize-module-react';
import EmailSuccModal from "./CampaignModals/EmailSucess";
import UseTempalate from "./CampaignModals/UseTemplate";
import AuroLogo from "../Logo/AuroLogo";
import { AiOutlineRight } from 'react-icons/ai';

const SendEmail = () => {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownUserVariable, setDropDownUserVariable] = useState(false)
  const [selectedValueOpen, setSelectedValueOpen] = useState(false);
  const [userVariable, setUserVariable] = useState('');
  const [showuserVariable, setShowUserVariable] = useState('');
  const { state } = location;
  const quillRef = useRef();
  Quill.register('modules/imageResize', ImageResize);
  var Size = Quill.import('attributors/style/size');
  Size.whitelist = ["8px", "10px", "11px", "12px", "13px", '14px', "15px", '16px', '18px', "24px", "36px", "48px"];
  Quill.register(Size, true);
  const { recurringMailPopup, scheduleEmailPopup, setRecurringMailPopup, pickDateTime, calenderPopUp, clockPopUp,
    scheduleRecurringPopUp, getAllApprovedEmail, approvedEmail, sendEmailData, setSendEmailData, directSendEmail, emaiSentPopup, emailSchedulePopup, useTempalatePopup, setUseTemplatePopup, selectedEmailTemplate, setSelectedEmailTemplate, groupListState, columnHeader } = useContext(DataContext);

  const [showVerifiedId, setShowVerifiedId] = useState(false);
  const navigate = useNavigate();
  const handleFromMail = (mailFrom) => {
    setSendEmailData({ ...sendEmailData, from: mailFrom.email });
    setShowVerifiedId(false)
  };

  const handleBody = (e) => {
    setSendEmailData({ ...sendEmailData, body: e })
  };



  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', "strike"],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['image', "link"],
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }],
        [{ 'size': ['small', false, 'large', 'huge'] }]
        [{ script: "sub" }, { script: "super" }],
        [{ font: [] }, { 'size': ["8px", "10px", "11px", "12px", "13px", '14px', "15px", '16px', '18px', "24px", "36px", "48px"] }],
      ]
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  }), [])

  useEffect(() => {
    setSendEmailData({
      ...sendEmailData,
      categoriesType: JSON.parse(localStorage.getItem('CategoryType'))
    });
    if (selectedEmailTemplate) {
      setSendEmailData({
        ...sendEmailData,
        body: selectedEmailTemplate?.content,
        subject: selectedEmailTemplate?.subject,
      });

    }
  }, [selectedEmailTemplate]);

  useEffect(() => {
    return () => {
      setSelectedEmailTemplate();
    };

  }, [])


  return (
    <>
      <div className="w-full flex bg-white h-screen">
        <div className="w-full mb-12 h-screen overflow-y-scroll send_mail">
          {recurringMailPopup === true ? <RecurringMail /> : <></>}
          {scheduleEmailPopup === true ? <ScheduleMail /> : <></>}
          {pickDateTime === true ? <PickDateTime /> : <></>}
          {calenderPopUp === true ? <Calender /> : <></>}
          {clockPopUp === true ? <ClockWatch /> : <></>}
          {scheduleRecurringPopUp === true ? <ScheduleRecurring /> : <></>}
          {emaiSentPopup === true ? <EmailSuccModal /> : <></>}
          {emailSchedulePopup === true ? <EmailSuccModal /> : <></>}
          {useTempalatePopup === true ? <UseTempalate /> : <></>}
          {/* {sendEmailData.to === '' ? <></> : <UseTempalate />} */}
          <div className="pl-4 3xl:pr-12 4xl:pr-12 pr-4 mt-4">

            {/* <div className="justify-end items-end flex w-full">
              <button className="inline-flex justify-center items-center gap-x-1.5 bg-white p-[.5rem_2.2rem] text-[12px] 4xl:text-[16px] mr-4 rounded-md text-theme-color border border-theme-color font-medium"
                onClick={() => { navigate("/campaign-category") }}
              >
                Use Template
              </button>
              <AuroLogo />
            </div> */}


            <div className="flex justify-between">
              <div className=" flex lg:flex-wrap items-center lg:gap-4">
                <div className="ml-4 lg:ml-0" onMouseLeave={() => { setDropdownOpen(false); setSelectedValueOpen(false) }}>
                  <button className="inline-flex justify-center items-center gap-x-1.5 bg-theme-color p-[.55rem_1.2rem] text-[12px] 4xl:text-[16px] font-normal rounded-md text-white" onMouseEnter={() => setDropdownOpen(true)}>
                    Insert system variable
                  </button>
                  {
                    dropdownOpen && (
                      <>
                        <div className="absolute z-10 w-[215px] 3xl:w-[170px] origin-top-right mt-[0.5px] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                          {/* {groupListState.map((option) => (
                            <div key={option._id} className="text-[18px] pb-[10px] pl-[10px]">
                              <div className="flex cursor-pointer items-center justify-between px-2
                                                        " onClick={(e) => { console.log('selectedValue') }}>
                                <span className="">{option.groupName}</span> <span className="text-black"><AiOutlineRight /> </span>
                              </div>
                            </div>
                          ))} */}
                          <span className="p-2">Work in Progress</span>
                        </div>
                      </>
                    )
                  }
                  {
                    selectedValueOpen === true ? <> <div className="absolute z-10 ml-[215px] 3xl:ml-[170px] w-[171px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                      {
                        columnHeader.map((item) => (
                          <div className="text-[18px] pb-[10px] pl-[10px]">
                            <div onClick={() => console.log('handling')}>
                              {item.columnName}
                            </div>
                          </div>
                        ))
                      }
                    </div> </> : <></>
                  }
                </div>
                <div className="ml-4 relative " >
                  <button
                    //  onMouseEnter={() => { setDropDownUserVariable(true) }} onMouseLeave={() => { setDropDownUserVariable(true) }}
                    className="inline-flex justify-center items-center gap-x-1.5 bg-theme-color text-[12px] 4xl:text-[16px] p-[.55rem_1.2rem] font-normal rounded-lg text-white ">
                    Insert user variable
                  </button>
                  {
                    dropDownUserVariable ? <div className="border border-slate-300 hover:border-indigo-300 flex justify-between rounded-lg items-center absolute p-2 top-[47px] left-[-42%] transition-all bg-white">
                      <input onChange={(e) => console.log('uservariable')} type="text" placeholder="Insert User Variable"></input>
                      <button onClick={() => { console.log('saveinsert') }} className="inline-flex justify-center items-center gap-x-1.5 bg-theme-color text-[12px]  4xl:text-[16px] font-normal rounded-lg text-white p-[.4rem_1rem]">
                        Save
                      </button>
                    </div> : ""
                  }
                </div>
              </div>
              <div className="flex items-center">
                <button className="inline-flex justify-center items-center gap-x-1.5 bg-white p-[.5rem_2.2rem] text-[12px] 4xl:text-[16px] mr-4 rounded-md text-theme-color border border-theme-color font-medium"
                  onClick={() => { navigate("/campaign-category") }}
                >
                  Use Template
                </button>
                <AuroLogo />
              </div>
            </div>

            {console.log(approvedEmail.length)}
            <div className="w-full border-[#161616] border h-[85vh] rounded-lg mt-4 shadow-edit-shadow">
              <div className="h-full flex flex-col">
                <div className="border-b py-2 mx-4 flex justify-between pb-0 items-center">
                  <div className="w-full">
                    <h1 className="3xl:text:18px 4xl:text-[22px] font-semibold ">
                      From
                    </h1>
                    <input
                      value={sendEmailData?.from}
                      className="w-full py-2 text-[14px] 2xl:text-base 3xl:text-base 4xl:text-[18px]"
                      style={{ pointerEvents: 'auto' }}
                    />

                  </div>


                  <div className="border justify-center cursor-pointer w-[30px] h-[30px] 3xl:w-[50px] 3xl:h-[50px] 4xl:w-[50px] 4xl:h-[50px] flex items-center rounded-lg border-theme-color" onClick={() => { { setShowVerifiedId(!showVerifiedId); getAllApprovedEmail() } }}>
                    <span className="items-center text-theme-color" ><BsChevronDown className="w-[12px] 3xl:w-auto 4xl:w-auto" /></span>
                  </div>
                </div>
                <div className="flex justify-end" onMouseLeave={() => { setShowVerifiedId(false) }}>
                  {
                    showVerifiedId && (
                      <>
                        <div className="absolute z-10 mt-[-12px] mr-[15px] origin-top-right mt-[0.5px] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                          {
                            approvedEmail.map((item) => (
                              <h1 className="px-[26px] pb-[18px] 3xl:text-[18px] 4xl:text-[20px] text-[1rem] cursor-pointer" onClick={() => { handleFromMail(item) }}>{item.email}</h1>
                            ))
                          }
                        </div>
                      </>
                    )
                  }
                </div>

                <div className="border-b py-2 mx-4 flex justify-between pb-0">
                  <div className="">
                    <h1 className="3xl:text:18px 4xl:text-[22px] font-semibold">
                      To
                    </h1>
                    <input
                      type="text"
                      name="groupAudience"
                      // value={sendEmailData?.groupAudience}
                      value={sendEmailData?.groupAudience === '' ? sendEmailData?.to : sendEmailData?.groupAudience}
                      rows="1"
                      cols="50"
                      placeholder="Who are you sending this template to?"
                      className="w-[890px] text-[14px] 2xl:text-base 3xl:text-base 4xl:text-[16px] mt-2 font- italic outline-0"
                      style={{ pointerEvents: 'auto' }}
                      onChange={(e) => setSendEmailData({ ...sendEmailData, to: e.target.value })}
                      onKeyDown={(e) => {
                        if ((e.key === "Backspace" || e.key === "Delete") && sendEmailData?.groupAudience) {
                          setSendEmailData({ ...sendEmailData, groupAudience: "", to: "" })
                        }
                      }}
                    />

                  </div>
                  <button className="bg-theme-color lg:text-[12px] mt-1 lg:mt-2 xl:text-[12px] whitespace-nowrap text self-start 4xl:mt-[12px] 3xl:mt-[7px] text-base p-[.45rem_2rem] rounded-md text-white" onClick={() => { navigate('/select-group') }}>
                    Add Audience
                  </button>
                </div>
                <div className="mx-4 pt-[20px]">
                  <div className="border-b">
                    <h1 className="3xl:text:18px 4xl:text-[22px] font-semibold">
                      Subject
                    </h1>
                    <textarea
                      type="text"
                      name="subject"
                      rows="1"
                      value={sendEmailData.subject}
                      cols="50"
                      placeholder="What's the subject line for this template"
                      className="w-full text-[14px] 2xl:text-base 3xl:text-base 4xl:text-[16px] mt-2 outline-0"
                      onChange={(e) => setSendEmailData({ ...sendEmailData, subject: e.target.value })}
                    ></textarea>
                  </div>
                  <div></div>
                </div>
                <div className="mx-4 h-full relative">
                  <ReactQuill
                    className="4xl:h-[420px_!important]"
                    value={sendEmailData.body}
                    onChange={(e) => { handleBody(e) }}
                    modules={modules}
                    ref={quillRef} />
                </div>

                <div className="flex pb-4 gap-2 mt-auto">
                  <button
                    type="button"
                    className="ml-4 border-1 border-theme-color p-[.45rem_2rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg"
                    onClick={() => { directSendEmail() }} >
                    Send
                  </button>
                  <button
                    type="button"
                    className="ml-4 border-1 border-theme-color p-[.45rem_2rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg"
                    onClick={() => { setRecurringMailPopup(true) }}
                  >
                    Schedule
                  </button>
                  <button
                    type="button"
                    className="ml-4 border-1 border-theme-color p-[.45rem_2rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg"
                  >
                    Save Draft
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default SendEmail;
