import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import usericon from '../../assets/images/usericon.svg';
import emailicon from '../../assets/images/emailicon.svg';
import passcodeicon from '../../assets/images/passcodeicon.svg';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import swal from "sweetalert";
import { DataContext } from "../../context/data/DataState";

const Registration = () => {
  const { Signup, passValidateError, emailExist, setPassValidateError } = useContext(DataContext);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [cpassError, setCpassError] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [confirmisVisible, setConfirmisVisible] = useState(false)
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    cpassword: ''

  })

  const buttonDisabled = (state.name !== '' && state.email !== '' && state.password !== '' && state.cpassword !== '');

  const validationMail = (maildata) => {
    var emailreg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (maildata.match(emailreg)) {
      return setError(false)
    }
    else if (maildata == '') {
      return setError(false)
    }
    else {
      return setError(true)
    }
  };
  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle2 = () => {
    setConfirmisVisible(!confirmisVisible)
  };

  const validationPassword = (passdata) => {
    setPassValidateError("")
    // var passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    var passregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    if (passdata.match(passregex))
      return setPassError(false)
    else if
      (passdata === '') {
      return setPassError(false)
    }
    else {
      return setPassError(true)
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.name === '' || state.email === '' || state.password === '' || state.cpassword === '') {
      swal({
        text: "Please fill data",
      });
    }
    else if (state.password !== state.cpassword) {
      setCpassError(true)
    }
    else if (error === true) {
      error(true)
    }
    else if (passError === true) {
      setPassError(true)
    }
    else (
      Signup(state)
    )
  }
  return (

    <div className="grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 w-full lg:gap-8 lg:place-content-start  place-content-center gap-0 max-h-screen">
      <div className="grid place-content-center   xs:pb-0  md:pb-0">
        <div className="4xl:px-24 4xl:py-8 flex items-center justify-center  xs:mb-1 ">
          <div className="text-center px-4 flex 4xl:px-12 3xl:px-12 2xl:px-8 flex-col items-center pt-12 xs:pt-5 sm:pt-4 sm:py-4 xs:h-[95vh] pb-6 md:w-[356px] sm:w-[90vw] sm:h-[93vh] 2xl:w-[450px] 3xl:w-[550px] 4xl:w-[674px]  lg:ml-8 md:py-8 rounded-2xl  shadow-[0_0_15px_#c1d6ff] xs:mx-4">
            <h1 className="4xl:text-[55px] 3xl:text-[40px] text-[28px] md:text-[24px] font-bold text-theme-color">
              Register
            </h1>
            <p className="3xl:text-[17px] 4xl:text-[20px] text-[14px] lg:w-full w-full py-4 2xl:py-[0px_16px]">
              Welcome back! login with your data that you entered during registration.
            </p>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="relative mt-1 flex m-auto">
                <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                  <span className="w-4/5 d-flex m-auto text-gray-500 sm:text-sm"><img src={usericon} width='18px' /></span>
                </div>
                <input type="text" placeholder="Enter your name *" className="w-4/5 mt-4 block 3xl:h-[35px] 4xl:h-[55px] m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto
                           focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                           disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none pl-[40px]
                           "
                  value={state.name} onChange={(e) => setState({ ...state, name: e.target.value })}
                />
              </div>
              <div>
                <div className="relative mt-1 flex m-auto">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                    <span className="text-gray-500 sm:text-sm"><img src={emailicon} width='18px' /></span>
                  </div>
                  <input type="text" placeholder="Enter your Email *" className="mt-4 block 3xl:h-[35px] 4xl:h-[55px] w-4/5 m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto
                           focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color
                           disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none pl-[40px]
                           "
                    value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} onKeyUp={() => validationMail(state.email)}
                  />
                </div>
                <div className="text-left d-flex m-auto">
                  <span className="w-4/5 d-flex m-auto text-red-600 text-[10px] ">{emailExist}</span>
                </div>
                <div className="text-left d-flex m-auto">
                  {error ? <span className="text-red-600 text-[10px]">Please enter a valid email address.</span> : <></>}
                </div>
              </div>
              <div>

                <div className="relative mt-1 flex m-auto">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                    <span className="text-gray-500 sm:text-sm"><img src={passcodeicon} width='15px' /></span>
                  </div>
                  <input type={isVisible ? "text" : "password"} placeholder="Password *" className="mt-4 block 3xl:h-[35px] 4xl:h-[55px] w-4/5 m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto
                           focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color pl-[40px]
                           disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                           "
                    value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })}
                    onKeyUp={() => validationPassword(state.password)} />
                  <div className="absolute flex items-center top-2/4 right-4">
                    <span className="cursor-pointer" onClick={() => toggle()}>
                      {isVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                    </span>
                  </div>
                </div>
                <div className="text-left d-flex m-auto">
                  {passError ? <span className="w-4/5 d-flex m-auto text-red-600 text-[10px] ">Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.</span> : <></>}
                </div>
                <div className="text-left d-flex m-auto">
                  <span className="w-4/5 d-flex m-auto text-red-600 text-[10px] ">{passValidateError}</span>
                </div>
              </div>
              <div>

                <div className="relative mt-1 flex m-auto">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                    <span className="text-gray-500 sm:text-sm"><img src={passcodeicon} width='15px' /></span>
                  </div>
                  <input type={confirmisVisible ? "text" : "password"} placeholder="Confirm new password *" className="mt-4 block 3xl:h-[35px] 4xl:h-[55px] w-4/5 m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto
                           focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color pl-[40px]
                           disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none 
                           "
                    value={state.cpassword} onChange={(e) => setState({ ...state, cpassword: e.target.value })}
                  />
                  <div className="absolute flex items-center top-2/4 right-4">
                    <span className="cursor-pointer" onClick={() => toggle2()}>
                      {confirmisVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                    </span>
                  </div>
                </div>
                <div className="text-left d-flex m-auto">
                  {cpassError ? <span className="w-4/5 d-flex m-auto text-red-600 text-[10px] ">Password and confirm password must be same.</span> : <></>}
                </div>
              </div>


              <div className="m-auto flex justify-between items-start mt-2">
                <label htmlFor="">
                  <input type="checkbox" required className="checked:bg-theme-color cursor-pointer 3xl:scale-125 4xl:scale-125 ..." />
                  <span className="4xl:text-[18px] pl-2 3xl:text-[13px] text-[11px] w-full">By creating an account, you agree to our <a href="/terms" target="_blank" className="text-theme-color cursor-pointer ..." >Terms and Conditions</a>  </span>
                </label>
              </div>

              <button type='submit' disabled={!buttonDisabled} className="mt-6 4xl:mt-9 text-[15px] 4xl:text-[23px] font-semibold p-[.7rem_5rem] 4xl:p-[1rem_8.3rem] disabled:opacity-40 bg-theme-color text-white rounded-xl 4xl:rounded-2xl ...">Sign up</button>
              <br />
            </form>
            <div className="3xl:mt-[24px] 4xl:mt-[24px] mt-3 4xl:text-[22px] 3xl:text-[17px] text-[13px]">
              <span className="">
                Already have an account?
              </span>&nbsp;
              <span className=" text-theme-color cursor-pointer font-bold ..." ><Link to="/login"> Login</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-screen sm:hidden xs:hidden">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full"></div>
      </div>
    </div>
  )
}
export default Registration;