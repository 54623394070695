import React, { useContext, useEffect } from "react";
import { useState } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { RiPencilFill } from 'react-icons/ri';
import bin from '../../../assets/images/bin.svg';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../../context/data/DataState";
import DeleteTemplateModal from "./TemplateModal/DeleteModal";
import noTemplateData from '../../../assets/images/notemplate.svg';
import htmlReactParser from 'html-react-parser';
import AuroLogo from "../../Logo/AuroLogo";

const WhatsappTemplateList = () => {
    const { id } = useParams();
    console.log(id)
    const location = useLocation();
    const { state } = location;
    const { getWhatsappTemplate, getStateWhatsappTemplate, setDeleteTemplateModal, setDeleteIvrTempId, deleteTemplateModal } = useContext(DataContext);
    const navigate = useNavigate();
    const type = "Whatsapp";
    const handleDeleteIvr = (id) => {
        setDeleteTemplateModal(true)
        setDeleteIvrTempId({ templateId: id, category: type });
    };

    const handlenavigate = (categoryId, item) => {
        // console.log(categoryId)
        // navigate(`/create-template/${categoryId}`)

        navigate(`/create-template/${categoryId}`, {
            state: {
                item // Whatapp
            }
        })
    };
    const handleUpdateTemplate = (categoryId, item, categoriesType) => {
        navigate(`/update-template/${categoryId}`, {
            state: {
                item,
                categoriesType //Email
            }
        })
    }
    useEffect(() => {
        getWhatsappTemplate(id)
    }, []);

    return (
        <>
            <div className="w-full flex bg-white h-screen">
                <div className=" bg-theme-color h-screen">
                    
                </div>
                <div className="w-full mb-12 h-screen overflow-x-scroll">
                    {deleteTemplateModal === true ? <DeleteTemplateModal></DeleteTemplateModal> : <></>}
                    <div className="pl-4 4xl:pr-6 pr-4 flex justify-between lg:items-center mt-4 mb-4 items-center">
                        <div className="flex w-full justify-between items-center">
                        <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium">Template List</h1>
                        <button type='button' className="p-[.45rem_1.5rem] mr-4 text-[14px] 3xl:text-[18px] 4xl:text-[24px] font-medium bg-white border border-theme-color text-theme-color rounded-lg ... " onClick={() => { handlenavigate(state.item._id, state.item) }}>
                            Create Templates
                        </button>
                        </div>
                        <AuroLogo/>
                    </div>
                    {/* <div className="px-8 min-h-[80vh]">
                        {
                            getStateWhatsappTemplate.length === 0 ? <img src={noTemplateData} className="h-[50vh] m-auto"></img> :
                                getStateWhatsappTemplate.map((item) => (
                                    <div className="mb-[32px]">
                                        <div className="flex justify-between items-end">
                                            <div>
                                                <h1 className="text-[36px] font-medium text-theme-color">{`Aurobindo`}<span className="text-[14px] font-normal text-[#16161699] ml-2">{`(Created on ${(item.createdAt).slice(0, 10)})`}</span></h1>
                                                <p className="w-[1204px] text-[24px] opacity-60 leading-9 text-[#161616] font-normal">{item.content}</p>
                                            </div>
                                            <div className="">
                                                <p className={`w-[155px] h-[40px] ${`Pending` === 'APPROVED' ? 'text-[#3C7D40]' : 'text-[#944FEB]'} ${`Pending` === 'APPROVED' ? 'bg-[#E9F4EB]' : 'bg-[#F1EAFB]'}  font-normal 3xl:text-[18px] 4xl:text-[24px] bg-[#E9F4EB] rounded-lg flex justify-center items-center px-[16px] py-[8px]`}>Pending</p>
                                                <div className="flex justify-end mt-2">
                                                    <span className="cursor-pointer text-[28px] text-theme-color"></span> <span><img src={bin} width='24px' className="cursor-pointer ml-[37px]" onClick={() => { handleDeleteIvr(item._id, item.categoryId) }}></img></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div> */}
                    <div className={`${getStateWhatsappTemplate.length === 0 ? "pl-4 pt-6 pr-12 min-h-[80vh] flex" : "pl-4 pt-6 pr-12"}`}>
                        {
                            getStateWhatsappTemplate.length === 0 ? <img src={noTemplateData} className="h-[50vh] m-auto"></img> :
                                getStateWhatsappTemplate.map((item) => (

                                    <div className="mb-[32px]">
                                        <div className="flex justify-between items-end">
                                            <div className="w-4/5">
                                                <h1 className="3xl:text-[27px] 2xl:text-[22px] font-semibold 4xl:text-[36px] text-theme-color">{item.templateName}<span className="3xl:text-[10px] 2xl:text-[8px] text-[5px] 4xl:text-[14px] font-normal text-[#16161699] ml-2">{`(Created on ${(item.createdAt).slice(0, 10)})`}</span></h1>
                                                <p className="3xl:text-[18px] 2xl:text-[16px] text-[12px] 4xl:text-[24px] mt-2 opacity-60 leading-9 text-[#161616] font-normal 3xl:leading-[27px] break-words">{htmlReactParser(`${item.subject}`)}</p>
                                            </div>
                                            <div className="">
                                                {/* <p className={` ${`Pending` === 'APPROVED' ? 'text-[#3C7D40]' : 'text-[#944FEB]'} ${`Pending` === 'APPROVED' ? 'bg-[#E9F4EB]' : 'bg-[#F1EAFB]'}  font-normal 3xl:text-[18px] 4xl:text-[24px] bg-[#E9F4EB] rounded-lg flex justify-center items-center p-[6px_12px] 4xl:p-[8px_16px`}>Pending</p> */}
                                                <div className="flex justify-end mt-2">
                                                    <span className="cursor-pointer text-[28px] text-theme-color" onClick={() => handleUpdateTemplate(state.item._id, item, state.item.categoriesType)}></span> <span><img src={bin} width='24px' className="cursor-pointer ml-[37px]" onClick={() => { handleDeleteIvr(item._id, item.categoryId) }} ></img></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatsappTemplateList;