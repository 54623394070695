import React, { createContext, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import back from '../../assets/images/back.svg';
import emailicon from '../../assets/images/emailicon.svg';
import { DataContext } from "../../context/data/DataState";

const EmailVerify = () => {
  const { forgetEmail, forgetEmailState, setForgetEmailState, emailForgetExist } = useContext(DataContext)
  const navigate = useNavigate();

  const [errorMail, setErrorMail] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    if (errorMail === true) {
      setErrorMail('please enter valid email')
    }
    else if (forgetEmail.email == '') {
      setErrorMail('please enter email first')
    }
    else {
      forgetEmail(forgetEmailState)
      // navigate("/verify-otp")
    }

  };
  console.log(forgetEmailState, "forgetEmailState")

  const buttonDisabled = (forgetEmailState.email !== '')

  const validateEmail = (maildata) => {
    var emailreg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (maildata.match(emailreg)) {
      return setErrorMail(false)
    }
    else if (maildata == '') {
      return setErrorMail(false)
    }
    else {
      return setErrorMail(true)
    }
  }
  return (
    <div className="w-full grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1  lg:gap-24 lg:place-content-start place-content-center gap-4 3xl:gap-0 h-screen">
      <div className="flex items-center lg:justify-center  relative">
        <div>
          <div className="flex justify-center items-center">
            <div className="ml-8 lg:ml-4 xl:ml-6">
              <Link to='/login' ><img src={back} className='w-[23px] h-[20px] absolute lg:top-[-25%] sm:top-[-33%] xs:top-[-33%] top-[15%]'></img> </Link>
              <h1 className="text-theme-color lg:text-[28px] xl:text-[26px] mt-4 font-bold text-[24px] 3xl:text-[27px] 4xl:text-[39px]">
                Forgot Password
              </h1>
              <p className="mt-3 3xl:text-[17px] xl:pr-20 xl:text-[14px] xl:ml-0 lg:ml-0 4xl:text-[22px] font-medium">
                Enter your e-mail address and we'll send you a link to reset your password.
              </p>
              <form>
                <div className="relative mt-1 flex w-[80%] 3xl:w-[409px]">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                    <span className="text-gray-500 sm:text-sm"><img src={emailicon} width='18px' /></span>
                  </div>
                  <input type="text" placeholder="Enter your Email" name="email" className="mt-4 3xl:h-[41px] 4xl:h-[55px] block m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color flex-auto
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none pl-[35px] 
            "  value={forgetEmailState.email} onChange={(e) => setForgetEmailState({ email: e.target.value })}
                    onKeyUp={() => validateEmail(forgetEmailState.email)}
                  />
                </div>
                <div className="">
                  <span className="text-red-600 text-[10px]">
                    {emailForgetExist}
                  </span>
                </div>
                <small className="text-red-500">{errorMail ? <span>Email must be in the valid form.</span> : <></>}</small>
              </form>
              <button type='button' onClick={(e) => { handleSubmit(e) }} disabled={!buttonDisabled} className="bg-theme-color text-white lg:rounded-xl xl:rounded-xl rounded-2xl mt-8 4xl:mt-9 text-[14px] 3xl:text-[18px] 4xl:text-[23px] font-bold p-[.7rem_5rem] 3xl:p-[.7rem_5rem]  4xl:p-[14px_113px] ...">Verify</button>
            </div>
          </div>
        </div>
      </div>
      <div className=" h-screen sm:hidden xs:hidden">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full"></div>
      </div>
    </div>
  )
}
export default EmailVerify;