import React, { useContext } from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DataContext } from "../../../context/data/DataState";
import settImg from '../../../assets/images/sett_modal.svg'
import work from '../../../assets/images/work.svg'
import calendar from '../../../assets/images/calendar.svg'
import day_night from '../../../assets/images/day_night.svg'

const RecurringMail = () => {
  const [openPopGroup, setOpenPopGroup] = useState(true);
  const { recurringMailPopup, setRecurringMailPopup, setPickDateTime, setScheduleRecurringPopUp } = useContext(DataContext);

  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[150px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative lg:w-4/5 lg:p-8 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full 4xl:p-[4rem_5rem] p-[2rem_4rem] sm:max-w-lg">
                  <div className="flex justify-center items-center">
                    <div className="grid-cols-2 grid place-content-center place-items-start gap-16">
                      <div className="flex justify-center flex-col items-center text-center">
                        <img src={settImg} className="3xl:w-[35px] 4xl:w-[50px] w-[30px] pb-4" alt="" />
                        <p className="4xl:text-[28px] 3xl:text-[16px] text-[16px] pb-2">Tomorrow<br />Morning</p>
                        <span className="text-[rgba(22,_22,_22,_0.6)] 4xl:text-[20px] 3xl:text-[14px] text-[12px]">Feb 8:30 AM</span>
                      </div>
                      <div className="flex justify-center flex-col items-center text-center">
                        <img src={day_night} className="3xl:w-[35px] 4xl:w-[50px] w-[30px] pb-4" alt="" />
                        <p className="4xl:text-[28px] 3xl:text-[16px] text-[16px] pb-2">This<br />Afternoon</p>
                        <span className="text-[rgba(22,_22,_22,_0.6)] 4xl:text-[20px] 3xl:text-[14px] text-[12px]">Feb 8:30 AM</span>
                      </div>{" "}
                      <div className="flex justify-center flex-col items-center text-center">
                        <img src={work} className="3xl:w-[35px] 4xl:w-[50px] w-[30px] pb-4" alt="" />
                        <p className="4xl:text-[28px] 3xl:text-[16px] text-[16px] pb-2">Monday<br />Morning</p>
                        <span className="text-[rgba(22,_22,_22,_0.6)] 4xl:text-[20px] 3xl:text-[14px] text-[12px]">Feb 8:30 AM</span>
                      </div>{" "}
                      <div className="flex justify-center flex-col items-center text-center">
                        <img src={calendar} className="3xl:w-[35px] 4xl:w-[50px] w-[30px] pb-4" alt="" />
                        <p className="4xl:text-[28px] 3xl:text-[16px] text-[18px pb-2]"
                        // onClick={()=>{{setPickDateTime(true) ; setRecurringMailPopup(false)}}}
                        >Pick Date &<br />Time</p>

                      </div>
                    </div>
                  </div>
                  <button className="4xl:m-auto mt-8 mb-4 mx-auto flex rounded-lg 4xl:my-12 4xl:p-[1rem_2rem] lg:my-8 p-[.7rem_1.5rem] bg-theme-color text-white 4xl:text-[24px] 3xl:text-[16px] font-medium text-[18px]" onClick={()=>{setScheduleRecurringPopUp(true); setRecurringMailPopup(false)}}>Recurring Email</button>
                  <span className="m-auto mt-6 block text-center text-[rgba(22,_22,_22,_0.6)] 4xl:text-[20px] 3xl:text-[14px] text-[12px]">All Time are in Indian Standard Time</span>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default RecurringMail;
