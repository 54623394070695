import React from "react";
import { useState } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { RiPencilFill } from 'react-icons/ri';
import bin from '../../../assets/images/bin.svg';
import noTemplateData from '../../../assets/images/notemplate.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import { useEffect } from "react";
import DeleteTemplateModal from "./TemplateModal/DeleteModal";
const MessageTemplateList = () => {
  const location = useLocation();
  const { state } = location;
  const { getMessageTemplate,
    getStateMessageTemplate, setDeleteIvrTempId, setDeleteTemplateModal, deleteTemplateModal ,templates, setTemplates} = useContext(DataContext);
  const navigate = useNavigate();
  const type = "Message";
  useEffect(() => {
    getMessageTemplate(state.item._id)
  }, [])

  const handleDeleteIvr = (id) => {
    setDeleteTemplateModal(true)
    setDeleteIvrTempId({ templateId: id, category: type });
  };

  const handlenavigate = (categoryId) => {
    setTemplates({ ...templates, subject:""})
    navigate(`/message-create-template/${categoryId}`)
  };

  return (
    <>
      <div className="w-full flex bg-white h-screen">
        <div className=" bg-theme-color h-screen">
          
        </div>
        <div className=" w-full mb-12 h-screen overflow-x-scroll">
          {deleteTemplateModal === true ? <DeleteTemplateModal></DeleteTemplateModal> : <></>}
          <div className="pl-4 pr-12 flex justify-between mt-4 lg:items-center items-end mb-4">
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-semibold">Template List</h1>
            <button type='button' className="p-[.45rem_2.4rem] text-[14px] 3xl:text-[18px] 4xl:text-[24px] bg-white text-theme-color font-medium border border-theme-color rounded-lg ... " onClick={() => { handlenavigate(state.item._id) }}>
              Create Templates
            </button>
          </div>
          <div className={`${getStateMessageTemplate.length === 0 ? "pl-4 pr-12 pt-4 min-h-[80vh] mt-4 flex" : "pl-4 pr-12 pt-4"}`}>
            {
              getStateMessageTemplate.length === 0 ? <img src={noTemplateData} className="h-[50vh] m-auto"></img> :
                getStateMessageTemplate.map((item) => (
                  <div className="mb-[32px]">
                    <div className="flex justify-between items-end">
                      <div className="w-4/5">
                        <h1 className="text-[27px] 4xl:text-[36px] font-semibold text-theme-color">{item.templateName}<span className="text-[10px] 4xl:text-[14px] font-normal text-[#16161699] ml-2">{`(Created on ${(item.createdAt).slice(0, 10)})`}</span></h1>
                        <p className="text-[18px] 4xl:text-[24px] opacity-60 leading-9 text-[#161616] break-words font-normal">{`${(item.subject).slice(0,180)}...`}</p>
                      </div>
                      <div className="">
                        {/* <p className={` ${`Pending` === 'APPROVED' ? 'text-[#3C7D40]' : 'text-[#944FEB]'} ${`Pending` === 'APPROVED' ? 'bg-[#E9F4EB]' : 'bg-[#F1EAFB]'}  font-normal 3xl:text-[18px] 4xl:text-[24px] bg-[#E9F4EB] rounded-lg flex justify-center items-center p-[6px_12px] 4xl:p-[8px_16px]`}>Pending</p> */}
                        <div className="flex justify-end mt-2">
                          <span className="cursor-pointer text-[28px] text-theme-color"></span> <span><img src={bin} width='24px' className="cursor-pointer ml-[37px]" onClick={() => { handleDeleteIvr(item._id, type) }}></img></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageTemplateList;