import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import back from '../../assets/images/back.svg';
import emailicon from '../../assets/images/emailicon.svg';
import editpencil from '../../assets/images/editpencil.svg';
import { DataContext } from "../../context/data/DataState";
import { GiClockwork } from 'react-icons/gi';
import { ToastContainer, toast } from 'react-toastify';

const OtpVerify = () => {
  const { forgetOtp, forgetEmailState, reForgetOtp, otpError } = useContext(DataContext)
  const [timer, setTimer] = useState(45)
  const navigate = useNavigate();
  const [otpVerifying, setOtpVerifying] = useState('')
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const handleSubmit = (e) => {
    const data = []
    e.preventDefault();
    data.push(ref1.current.value, ref2.current.value, ref3.current.value, ref4.current.value)
    let finalvalue = data.join("");
    setOtpVerifying(finalvalue);
    forgetOtp(finalvalue, forgetEmailState.email);
  };

  const otpBox1 = () => {
    console.log(ref1.current.value, 'reference')
    if (ref1.current.value) {
      ref2.current.focus()
    }
  }
  const otpBox2 = () => {
    if (ref2.current.value) {
      ref3.current.focus()
    }
    else if (ref2.current.value === "") {
      ref1.current.focus()
    }
  }
  const otpBox3 = () => {
    if (ref3.current.value) {
      ref4.current.focus()
    }
    else if (ref3.current.value === "") {
      ref2.current.focus()
    }
  }
  const otpBox4 = () => {
    if (ref4.current.value === "") {
      ref3.current.focus()
      ref4.current.blur()
    }
  }
  const countDown = (timer) => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
    else {
      clearInterval();
      setTimer('00')
    }
  }
  const clearInputFields = () => {
    ref1.current.value = '';
    ref2.current.value = '';
    ref3.current.value = '';
    ref4.current.value = '';
    ref1.current.focus();
  }
  const reSend = (timer) => {
    reForgetOtp(forgetEmailState.email)
    clearInputFields();
    countDown(timer)
  }
  useEffect(() => {
    countDown(timer)
  }, [countDown, reSend])

  return (
    <>
    <ToastContainer />
    <div className="grid grid-cols-2 w-full lg:grid-cols-2 lg:gap-24 lg:place-content-start place-content-center gap-4 md:gap-0 h-screen relative">
      <div className="grid  justify-center lg:justify-center w-full m-auto ">
        <div className="flex justify-center items-center">
          <div className="3xl:pl-10 sm:pl-[213px] xs:pl-[11rem] pr-4 pb-20 pl-8 4xl:p-24 pt-0">
            <Link to='/forgot-password' ><img src={back}  className=' w-[23px] h-[20px] lg:top-[25%] absolute top-[15%]'></img> </Link>
            <div className="">
              <h1 className="text-theme-color 2xl:text-[24px] text-[20px] 3xl:text-[32px] font-bold 4xl:text-[39px]">
                OTP Verification
              </h1>
              <div className="">
                <p className="3xl:text-[16px] 2xl:text-[14px] text-[14px] 4xl:text-[18px] font-bold py-4">
                  We have sent an OTP to the E-mail ID
                </p>
              </div>
              {/* <small className="flex mt-2">
                {forgetEmailState.email}
                <Link to='/forgot-password'><img className="ml-[10px] cursor-pointer" src={editpencil} width='15px'></img>
                </Link>
              </small> */}
              <form className="flex gap-2 mt-0 md:mt-4">
                <input type="text" name="number" maxLength='1' ref={ref1} onChange={otpBox1} className="w-[65px] h-[65px] xs:w-[50px] xs:h-[50px] 2xl:w-[65px] 2xl:h-[65px] 3xl:w-[74px] 3xl:h-[74px] 4xl:w-[99px] 4xl:h-[99px] bg-gray-100 rounded-2xl 4xl:rounded-3xl mr-2.5 mt-4 block p-3 3xl:text-[27px] 4xl:text-[37px] font-bold text-center border border-none text-sm drop-shadow-[0px_3.75px_3.75px_rgba(0,_0,_0,_0.102)] placeholder-slate-400 focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                />
                <input type="text" name="number" maxLength='1' ref={ref2} onChange={otpBox2}
                  className="w-[65px] h-[65px] xs:w-[50px] xs:h-[50px] 2xl:w-[65px] 2xl:h-[65px] 3xl:w-[74px] 3xl:h-[74px] 4xl:w-[99px] 4xl:h-[99px] bg-gray-100 rounded-2xl 4xl:rounded-3xl mr-2.5 mt-4 block p-3 3xl:text-[27px] 4xl:text-[37px] font-bold text-center border border-none text-sm drop-shadow-[0px_3.75px_3.75px_rgba(0,_0,_0,_0.102)] placeholder-slate-400 focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                />
                <input type="text" name="number" maxLength='1' ref={ref3} onChange={otpBox3} className="w-[65px] h-[65px] xs:w-[50px] xs:h-[50px] 2xl:w-[65px] 2xl:h-[65px] 3xl:w-[74px] 3xl:h-[74px] 4xl:w-[99px] 4xl:h-[99px] bg-gray-100 rounded-2xl 4xl:rounded-3xl mr-2.5 mt-4 block p-3 3xl:text-[27px] 4xl:text-[37px] font-bold text-center border border-none text-sm drop-shadow-[0px_3.75px_3.75px_rgba(0,_0,_0,_0.102)] placeholder-slate-400 focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                />
                <input type="text" name="number" maxLength='1' ref={ref4} onChange={otpBox4} className="w-[65px] h-[65px] xs:w-[50px] xs:h-[50px] 2xl:w-[65px] 2xl:h-[65px] 3xl:w-[74px] 3xl:h-[74px] 4xl:w-[99px] 4xl:h-[99px] bg-gray-100 rounded-2xl 4xl:rounded-3xl mr-2.5 mt-4 block p-3 3xl:text-[27px] 4xl:text-[37px] font-bold text-center border border-none text-sm drop-shadow-[0px_3.75px_3.75px_rgba(0,_0,_0,_0.102)] placeholder-slate-400 focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                />
              </form>
              <div className="flex justify-between mt-2 md:mt-6 text-theme-color">
                <span className="text-[13px] 3xl:text-base 4xl:text-[18px]">
                  00:{timer}
                </span>
                <div className="flex items-center">
                  <span className="text-[13px] 3xl:text-base 4xl:text-[18px]"><GiClockwork /></span> &nbsp;
                  {
                    timer == 0 ? <span className="underline cursor-pointer text-[13px] 4xl:text-[18px] 3xl:text-[13px]" onClick={() => reSend(45)}>Resend OTP</span> :
                      <span className="underline text-slate-500 text-[13px] 3xl:text-base 4xl:text-[18px]">Resend OTP</span>
                  }

                </div>
              </div>
              <span className="text-red-600 text-[10px] ">{otpError} </span>
            </div>
            <button type='button' onClick={(e) => { handleSubmit(e) }} className="bg-theme-color text-white rounded-2xl mt-6 4xl:mt-9 text-[14px] 3xl:text-[18px] 4xl:text-[23px] font-bold p-[.7rem_3rem] 3xl:p-[.7rem_5rem]  4xl:p-[14px_113px] xs:text-[12px] xs:p-[5px_30px] ...">Submit</button>
          </div>
        </div>
      </div>
      <div className=" h-screen sm:hidden xs:hidden">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full"></div>
      </div>
    </div>
    </>
  )
}
export default OtpVerify;