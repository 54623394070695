import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/data/DataState";
import CopyGroupModal from "../../Modal/CopyGroupModal";
import DeleteGroupModal from "../../Modal/DeleteGroupModal";

const GroupList = () => {
  const { groupListState, getColumn,
    setGroupId, getAudienceData, setDeleteModal, deleteModal, setDeleteGroupId, copyGroupPopUp,
    setCopyGroupPopUp } = useContext(DataContext);
  const navigate = useNavigate()
  const handleViewGroup = (id, specficGroupName) => {
    setGroupId(id);
    getColumn(id);
    getAudienceData(id);
    setTimeout(() => {
      navigate(`/audience-table/${id}/${specficGroupName}`)
    }, 1000);

  };
  const handleDeleteGroup = (id) => {
    setDeleteModal(true)
    setDeleteGroupId(id)
  };

  const handleCopyGroup = (id) => {
    setCopyGroupPopUp(true)
    setDeleteGroupId(id)
  };

  useEffect(() => {

  }, [])
  return (
    <>
      {
        deleteModal === true ? <DeleteGroupModal></DeleteGroupModal> : <> </>}
      {
        copyGroupPopUp === true ? <CopyGroupModal ></CopyGroupModal> : <></>}
        <div className="mt-16">
      {
        groupListState.map((item) => (
          <div className="pr-12 3xl:pr-6 pl-4 my-3">
           <div className="border-t-2 3xl:pt-3 4xl:pt-4 pt-2">
              <div className="flex justify-between ">
                <div className="">
                  <h1 className="4xl:text-[24px] 3xl:text-[20px] text-[16px] xs:text-[14px] sm:text-[15px] font-medium">{item.groupName}</h1>
                  <span className="4xl:text-[16px] 3xl:text-[14px] text-[12px] xs:text-[10.75px] sm:text-[11px] cursor-pointer text-theme-color">
                    <Link to='/Update-group-creation' state={{ from: item }}>Edit</Link>
                  </span>
                  <span className=" ml-5 lg:ml-3 xl:ml-3 xs:ml-[8px] sm:ml-[7px] 4xl:text-[16px] 3xl:text-[14px] text-[12px] xs:text-[10.75px] sm:text-[11px] cursor-pointer text-theme-color" onClick={() => { handleCopyGroup(item._id) }} >Copy group</span>
                  <span className=" ml-5 lg:ml-3 xl:ml-3 xs:ml-[8px] sm:ml-[7px]  4xl:text-[16px] 3xl:text-[14px] xs:text-[10.75px] text-[12px] sm:text-[11px] cursor-pointer text-red-600" onClick={() => { handleDeleteGroup(item._id) }} >Delete</span>
                </div>
                <div className="flex items-center">
                  <button type='button' className="p-[.45rem_1.2rem] xs:p-[4px_6px] sm:p-[5px_7px] xs:text-[12px]  sm:text-[12.75px]  xl:text-[12px]  3xl:text-[14px] font-normal 4xl:text-[18px] bg-theme-color text-white rounded-lg ... " onClick={() => { handleViewGroup(item._id, item.groupName) }}>
                    View Group
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      }
      </div>
    </>
  )
}
export default GroupList;