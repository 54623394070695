import React, { useContext, useState } from 'react';
import profileImg from '../../assets/images/default.png';
import { useNavigate } from "react-router-dom";
import AuroLogo from '../Logo/AuroLogo';
import { DataContext } from '../../context/data/DataState';
import AddMemberModal from './AdminstrationModal/AddMemberModal';
import DeleteUserModal from './AdminstrationModal/DeleteUserModal';
import { useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive'


const MembersList = () => {
  const navigate = useNavigate();
  const { addMemeberPopUp,
    setAddMemberPopUp, deletMemberPopUp, getSubUser,
    setDeleteMemberPopUp, getsubUserState, setDeleteSubUserId, isVisibleSb, setIsVisibleSb } = useContext(DataContext);

  const handleNavigation = (item) => {
    console.log(item, 'item')
    navigate('/edit-member-access',
      { state: item })
  };
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });


  function checkPremission(data) {
    for (let key in data) {
      if (typeof data[key] === 'boolean') {
        if (data[key] === true) {
          return true
        }
      } else if (typeof data[key] === 'object') {
        if (checkPremission(data[key])) {
          return true
        }
      }
    }
  };

  useEffect(() => {
    getSubUser()

  }, [])

  return (
    <>
      <div className={`w-full bg-white h-screen  ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
        {
          addMemeberPopUp === true ? <AddMemberModal /> : <></>
        }
        {
          deletMemberPopUp === true ? <DeleteUserModal /> : <></>
        }
         <div className="flex justify-between pb-12 xs:pb-4 sm:pb-6 4xl:pl-8 3xl:pl-6 pl-4 pt-4 w-full">
          <div className="hidden flex-col gap-4 xs:flex sm:flex md:flex w-full pr-4">
            <div className="flex justify-between items-center">
          <FiMenu className="text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2" onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>
          <AuroLogo/>
          </div>
          <div className="flex justify-between items-center">
          <h1 className='4xl:text-[48px] 3xl:text-[24px] text-[20px] xs:text-[18px] font-medium'>Members & team</h1>
            {
              getsubUserState.length === 0 ? <></> : <button className='bg-theme-color p-[.6rem_1.5rem] xs:p-[.6rem_1rem] self-end text-[14px] xs:text-[13px] text-white rounded-xl font-normal' onClick={() => { setAddMemberPopUp(true) }}>Add Members</button>
            }
            </div>
            </div>
          
          <div className="flex justify-between w-full items-center xs:hidden sm:hidden md:hidden mt-7">
            <h1 className='4xl:text-[48px] 3xl:text-[24px] text-[25px] font-semibold'>Members & team</h1>
            {
              getsubUserState.length === 0 ? <></> : <button className='bg-theme-color p-[.6rem_1.5rem] mr-4 4xl:text-[24px] 3xl:text-[16px] self-end text-[14px] text-white rounded-xl font-normal' onClick={() => { setAddMemberPopUp(true) }}>Add Members</button>
            }
          </div>
      

        </div>

        {
          getsubUserState.length === 0 ?
            <>
              <div className="h-[2px] bg-gray-300 mt-4 ml-4"></div>
              <div className="pl-4 py-6 mt-4 relative">
                <div className="border rounded-md">
                  <div className="m-auto flex flex-col pb-4">
                    <div className="p-8">
                      <h1 className="text-[25px] 4xl:text-[32px] font-semibold text-center pb-8">
                        Lorem ipsum dolor ?
                      </h1>
                      <p className="text-center text-[18px] text-[rgba(22,_22,_22,_0.6)] 4xl:text-[24px] leading-7 4xl:leading-10">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laboriosam dolore nihil nesciunt iure quod possimus blanditiis quidem necessitatibus distinctio tenetur ea repellendus odit illum corrupti, veniam provident tempora sed reiciendis. <br />
                      </p>
                    </div>
                    <div className="text-center pt-8">
                      <button type='button' className="text-[22px] xs:text-[15px] p-[9px_39px] 4xl:p-[12px_64px] 4xl:text-[32px] bg-theme-color text-white rounded-lg ... " onClick={() => { setAddMemberPopUp(true) }}>
                        Add Members
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <div className="grid grid-cols-3  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-8 4xl:pl-8 3xl:pl-6 pl-4 pr-6 mt-8">
              {getsubUserState.map((item, index) => (
                <div className="card 4xl:p-6 3xl:p-3 p-2 bg-white shadow-card-shadow rounded-xl bg-cardbg bg-center bg-cover border" >
                  <div className='flex justify-between items-center pb-[1rem]'>
                    <div className="flex flex-col">
                      <h3 className='4xl:text-[30px] 3xl:text-[24px] text-[18px] font-medium'>{item?.firstName}&nbsp;{item?.lastName}</h3>
                      <div className="edit_prof ml-auto mt-1">
                        <buttons className="4xl:text-[10px] text-[8px] cursor-pointer p-[.5rem_2rem] 3xl:p-[.5rem_2rem] mr-2 border rounded-md">Edit</buttons>
                        <buttons className="4xl:text-[10px] text-[8px] cursor-pointer p-[.5rem_2rem] text-white bg-red-500 rounded-md" onClick={() => { setDeleteMemberPopUp(true); setDeleteSubUserId(item._id) }}>Delete</buttons>
                      </div>
                    </div>
                    <div className='3xl:w-[90px] 3xl:h-[90px] 4xl:w-[110px] 4xl:h-[110px] w-[70px] h-[70px] rounded-full'>
                      <img src={item.image === '' ? profileImg : item.image} alt="" className="rounded-full 3xl:w-[90px] 3xl:h-[90px] border-4 border-[#D0DAFA] object-cover 4xl:w-[110px] 4xl:h-[110px] w-[70px] h-[70px]" />
                    </div>
                  </div>
                  <div className='' >
                    <p className='4xl:text-[16px] 3xl:text-[14px] pb-2 text-[12px] font-medium'>Permissions:</p>
                    {
                      checkPremission(item.permission) ? <span>Give more <span className='text-theme-color cursor-pointer' onClick={() => handleNavigation(item)}>permissions.</span></span> : <span>No permissions yet <span className='text-theme-color cursor-pointer' onClick={() => handleNavigation(item)}>give permissions.</span></span>
                    }
                    <div className="flex 4xl:text-[11px] 3xl:text-[10px] text-[8px] flex-wrap gap-x-5 3xl:gap-x-2 gap-y-3 3xl:gap-y-1 items-baseline">
                    </div>
                  </div>
                </div>
              ))}
            </div>
        }

      </div>
    </>
  )
}

export default MembersList;