import React from 'react'
import AuroLogo from '../Logo/AuroLogo'
import { AiOutlineTwitter, AiFillInstagram } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const LandingPage = () => {
   const navigate = useNavigate();
   return (
      <div className="bg-soonBg bg-cover bg-center h-screen w-full relative">
         <header className='bg-white left-1/2 -translate-x-1/2 flex justify-between w-full absolute z-10 py-3 px-4'>
            <AuroLogo />
            <button className='text-white bg-black self-center p-[.6rem_2rem] text-[14px] rounded-md' onClick={()=>(navigate('/login'))}>LOGIN</button>
         </header>
         <div className='absolute text-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-full text-center'>
            <h1 className='text-[10vw] font-bold'>Coming soon</h1>
            <p className='text-[24px]'>In the meantime, Sign up for our monthly newsletter to stay up to date.</p>
         </div>
         <div className="bottom absolute bottom-10 w-full left-1/2 -translate-x-1/2">
            <div className='flex justify-center gap-6'>
               <AiFillInstagram className='inline-block text-[25px]' />
               <AiOutlineTwitter className='inline-block text-[25px]' />
            </div>
         </div>

      </div>
   )
}

export default LandingPage;