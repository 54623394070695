import React, { useState } from 'react';
// import paidPlan from '../../assets/images/paidPlan.svg'
import back from '../../assets/images/back.svg';
import activemark from '../../assets/images/tickmark.svg';
import { Link, useNavigate } from "react-router-dom";
const UpgradePlan = () => {

  const [state, setState] = useState([
    {
      "plantitle": "FREE",
      "planexp": "Your Plan expire in 2 Weeks",
      "planprice": "0",
      "planactive": 'true',
      "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
    },
    {
      "plantitle": "Basic",
      "planprice": "200",
      "planexp": "",
      "planactive": 'false',
      "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
    },
    {
      "plantitle": "Advance",
      "planprice": "250",
      "planexp": "",
      "planactive": 'false',
      "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
    },
    {
      "plantitle": "Premium",
      "planprice": "300",
      "planexp": "",
      "planactive": 'false',
      "plandesc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum quasi, cum ipsam eius placeat ipsum. Quibusdam ea reiciendis minus molestiae voluptates esse. Quidem laborum facilis, itaque rem aut voluptatum minima!"
    }
  ]

  )

  const navigate = useNavigate();
  return (
    <div className='w-full'>
      <div className='4xl:h-[297px] 3xl:h-[154px] h-[110px] w-full bg-no-repeat bg-cover bg-paidPlan'>
        <div className='flex justify-center items-center h-full'>
          {/* <Link to='/group-dashboard' className='w-[42%]' ><img src={back} className='w-14 pt-24' ></img></Link> */}
          <h1 className='3xl:text-40px 2xl:text-[36px] text-[30px] text-white font-bold 4xl:text-[55px]'>My current Plan</h1>
        </div>
      </div>

      <div className='grid grid-cols-4  xl:px-4 2xl:px-6 lg:grid-cols-2 lg:gap-16 lg:px-8 3xl:gap-x-0 gap-4 4xl:gap-x-24 pt-16 3xl:px-12 3xl:place-items-center 4xl:place-items-center 4xl:px-36 pb-8'>
        {
          state.map((item) => (
            <div className='border border-theme-color rounded-2xl relative 4xl:max-w-[1800px] 3xl:w-64 4xl:w-[22rem] p-[23px] flex flex-col text-center'>
              <span>{item.planactive === 'true' ? <img src={activemark} className="absolute right-4 top-4"></img> : <></>}</span>
              <div className='body flex-auto text-center'>
                <h2 className='text-theme-color uppercase pt-6 font-bold text-[25px] 4xl:text-[40px]'>{item.plantitle}</h2>
                <span className='font-bold text-[40px] py-4 inline-block 4xl:text-[49px]'>&#x24;{item.planprice}</span>
                <span>{item.planactive === 'true' ? <h1 className='text-theme-color text-[.8rem]'>{item.planexp}</h1> : <></>}</span>
                <p className='font-normal text-[.75rem] 4xl:text-[1rem] p-2 text-center'>{item.plandesc}</p>
              </div>
              <div className='footer'>
                <button type='button' className="mt-6 mb-2 p-[5px_40px] text-[16px] 4xl:text-[21px] w-[90%] 4xl:p-[7px_60px] bg-theme-color text-white rounded-lg ...">{item.plantitle}</button>
              </div>
            </div>
          )
          )
        }
      </div>
      {/* <div className='flex justify-center'>
        <button type='button' className="w-40 h-8 bg-theme-color text-white rounded-lg ...">Upgrade Your Plan</button>
      </div> */}
    </div>
  )
}
export default UpgradePlan;