import React from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import GroupHeader from "./GroupHeader";
import GroupList from "./GroupList";
import GroupNew from "./GroupNew";
import { DataContext } from "../../../context/data/DataState";
import { useContext } from "react";
import { useEffect } from "react";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { useMediaQuery } from 'react-responsive'

const GroupDashboard = () => {
  const { groupListState, setGroupListState, groupList, isVisibleSb, setIsVisibleSb } = useContext(DataContext);

  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });

  // useEffect(() => {
  //   GroupList()
  // }, [])


  useEffect(() => {
    groupList()
  }, [])
  return (
    <>
      <div className={`w-full flex bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
        <div className=" bg-theme-color h-screen">
          
        </div>
        <div className="w-full mb-12 h-screen overflow-y-scroll overflow-x-scroll">
          <GroupHeader />
          {groupListState.length === 0 ? <GroupNew /> : <GroupList />}
        </div>
      </div>
    </>
  )
}
export default GroupDashboard;