import React, { useContext } from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DataContext } from "../../../context/data/DataState";
import { useEffect } from "react";


const ScheduleRecurring = () => {
  const [openPopGroup, setOpenPopGroup] = useState(true);
  const { setScheduleRecurringPopUp, sendEmailData, setSendEmailData, ScheduleEmail } = useContext(DataContext);
  const [dayState, setDayState] = useState([])

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSendEmailData(prevState => ({
      ...prevState,
      daysofweek: {
        ...prevState.daysofweek,
        [name]: checked,
      },
    }));
  };

  const handleDays = () => {
    for (const day in sendEmailData.daysofweek) {
      if (sendEmailData.daysofweek[day] === true) {
        console.log(day);
      }
    }
  };


  // const handleDays = () => {
  //   for (const day in sendEmailData.daysofweek) {
  //     if (sendEmailData.daysofweek[day] === true) {
  //       dayState.push(day);
  //     }
  //   }
  //   console.log(dayState); // logs the array of selected days
  // };

  useEffect(() => {
    handleDays()
  }, [sendEmailData])


  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[150px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full lg:p-[1.5rem_2rem] 4xl:p-[3rem_4rem] 3xl:p-[2rem_3.5rem] p-[1.5rem_3rem] sm:max-w-lg">
                  <h1 className="4xl:text-3xl 3xl:text-2xl text-[20px] font-semibold text-left text-theme-color pb-5">Schedule Recurring Mail</h1>
                  <div className="flex justify-center items-center">
                    <form action="" className="rec-popup">
                      <div className="grid grid-cols-2 mb-3">
                        <div className="">
                          <p className="4xl:text-[20px] flex gap-4 text-[rgba(22,_22,_22,_0.6)] pb-[.5rem]">
                            Starts
                          </p>
                          <input type="date" className="4xl:w-[360px] 3xl:w-[240px] w-[180px] 4xl:h-[54px] 3xl:h-[45px] h-[36px] 4xl:text-[24px] 3xl:text-[18px] text-[16px] p-2 border border-light_b rounded-md" onChange={(e) => setSendEmailData({ ...sendEmailData, startDate: e.target.value })} />
                        </div>
                        <div>
                          <p className="4xl:text-[20px] flex gap-4 text-[rgba(22,_22,_22,_0.6)] pb-[.5rem]">Send time</p>
                          <input type="time" name="" id="" className="4xl:w-[360px] 4xl:h-[54px] 3xl:h-[45px] h-[36px] 4xl:text-[24px] 3xl:text-[18px] text-[16px] p-2 border border-light_b rounded-md" onChange={(e) => setSendEmailData({ ...sendEmailData, time: e.target.value })} />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 mb-3">
                        <p className="4xl:text-[20px] text-[rgba(22,_22,_22,_0.6)]">
                          Sends every
                        </p>
                        <div class="flex">
                          <div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="radio"
                              name="send_freq"
                              id="send_freq"
                              value="week"
                              // onChange={(e)=>{console.log(e.target.value)}}
                              onChange={(e) => setSendEmailData({ ...sendEmailData, every: e.target.value })}
                            />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for="inlineRadio1"
                            >Week</label
                            >
                          </div>
                          <div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2 4xl:pl-6 3xl:pl-4 pl-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="radio"
                              name="send_freq"
                              id="inlineRadio2"
                              value="month" onChange={(e) => setSendEmailData({ ...sendEmailData, every: e.target.value })} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for="inlineRadio2"
                            >Month
                            </label>
                          </div>
                          <div class="mb-[0.125rem]  min-h-[1.5rem] flex items-center gap-2 pl-[1.5rem]">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="radio"
                              name="send_freq"
                              id="inlineRadio3"
                              value="year"
                              onChange={(e) => setSendEmailData({ ...sendEmailData, every: e.target.value })}
                            />
                            <label
                              class="mt-px  pl-[0.15rem] hover:pointer-events-none"
                              for="inlineRadio3"
                            >Yearly</label
                            >
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-2 flex-col mb-3">
                        <p className="4xl:text-[20px] text-[rgba(22,_22,_22,_0.6)]">
                          On
                        </p>
                        <div className="flex">
                          <div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="checkbox"
                              name="sunday"
                              id=""
                              value="sun" onChange={handleCheckboxChange} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for=""
                            >Sun</label
                            >
                          </div>
                          <div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2 4xl:pl-6 3xl:pl-4 pl-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="checkbox"
                              name="monday"
                              id=""
                              value="Mon" onChange={handleCheckboxChange} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for=""
                            >Mon</label>
                          </div><div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2 4xl:pl-6 3xl:pl-4 pl-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="checkbox"
                              name="tuesday"
                              id=""
                              value="Tue" onChange={handleCheckboxChange} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for=""
                            >Tue</label>
                          </div>
                          <div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2 4xl:pl-6 3xl:pl-4 pl-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="checkbox"
                              name="wednesday"
                              id=""
                              value="Wed" onChange={handleCheckboxChange} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for=""
                            >Wed</label
                            >
                          </div>
                          <div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2 4xl:pl-6 3xl:pl-4 pl-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="checkbox"
                              name="thursday"
                              id=""
                              value="Thu" onChange={handleCheckboxChange} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for=""
                            >Thu</label
                            >
                          </div>
                          <div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2 4xl:pl-6 3xl:pl-4 pl-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="checkbox"
                              name="friday"
                              id=""
                              value="Fri" onChange={handleCheckboxChange} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for=""
                            >Fri</label
                            >
                          </div><div class="mb-[0.125rem] min-h-[1.5rem] flex items-center gap-2 4xl:pl-6 3xl:pl-4 pl-2">
                            <input
                              class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                              type="checkbox"
                              name="saturday"
                              id=""
                              value="Sat" onChange={handleCheckboxChange} />
                            <label
                              class="mt-px  pl-[0.15rem] hover:cursor-pointer"
                              for=""
                            >Sat</label
                            >
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 mb-3">
                        <p className="4xl:text-[20px] text-[rgba(22,_22,_22,_0.6)]">
                          Ends on
                        </p>
                        <div class="flex gap-2 items-center">
                          {/* <input
                            class="4xl:w-[34px] 4xl:h-[34px] 3xl:w-[25px] 3xl:h-[25px] w-[15px] h-[15px] border border-light_b rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="option1" /> */}
                          {/* <label
                            class=" pl-[0.15rem] hover:cursor-pointer"
                            for=""
                          >On</label> */}
                          <input type="date" className="4xl:w-[224px] w-[180px] 4xl:h-[54px] 3xl:h-[45px] h-[36px] 4xl:text-[24px] 3xl:text-[18px] text-[16px] p-2 border border-light_b rounded-md" onChange={(e) => setSendEmailData({ ...sendEmailData, endDate: e.target.value })} />
                        </div>
                      </div>
                      <div className="pb-4 border-b-4">
                        <p className="4xl:text-[20px] text-[rgba(22,_22,_22,_0.6)] pb-2">
                          Summary
                        </p>
                        <p className="4xl:text-[24px] 3xl:text-[20px] text-[18px] text-theme-color font-medium">
                          Every {sendEmailData.every}, at {sendEmailData.time}
                        </p>
                      </div>
                      <div className="flex justify-start 4xl:gap-12 3xl:gap-8 gap-4 4xl:mt-12 mt-8">
                        <button className="bg-white text-theme text-theme-color p-[.7rem_1.5rem] lg:p-[.55rem_1.5rem] border 4xl:text-[24px] 3xl:text-[18px] font-medium text-[14px] rounded-lg border-theme-color" onClick={() => { setScheduleRecurringPopUp(false) }}>
                          Cancel
                        </button>
                        <button className="bg-theme-color rounded-lg text-white p-[.7rem_1.5rem] lg:p-[.55rem_1.5rem] 4xl:text-[24px] 3xl:text-[18px] font-medium text-[14px]" onClick={() => { ScheduleEmail(); setScheduleRecurringPopUp(false); }}>
                          Schedule Send
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default ScheduleRecurring;
