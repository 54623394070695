import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { DataContext } from "../../context/data/DataState";
import nooData from "../../assets/images/No data.gif";
import caretDown from "../../assets/images/caret blue.svg";
import AuroLogo from "../Logo/AuroLogo";
import { AiOutlineSearch, AiOutlineReload } from "react-icons/ai";
import del from "../../assets/images/delete_all.svg";
import { MdDelete } from "react-icons/md";
import Parser from "html-react-parser";
import dots from "../../assets/images/email-dots.svg";
import MobileHeader from "../MobileHeader/MobileHeader";
import { FiMenu } from "react-icons/fi";
import { useMediaQuery } from 'react-responsive';

const HistoryCampaign = () => {
  const {
    HistoryCampaigns,
    historyCamapaignState,
    DeleteSentEmail,
    isVisibleSb,
    setIsVisibleSb,
  } = useContext(DataContext);
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const [isSelected, setIsSelected] = useState(false);
  const [selectionmail, setSelectionMail] = useState([]);

  const handleDropdown = (index) => {
    setDropdownIndex(index === dropdownIndex ? -1 : index);
  };
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });


  const handleChange = (checked, id) => {
    console.log(checked, id, "checkedid");
    if (checked === true) {
      // Add the id to the array
      setSelectionMail([...selectionmail, id]);
    } else {
      // Remove the id from the array
      setSelectionMail(selectionmail.filter((item) => item !== id));
    }
  };
  console.log(selectionmail, "selectionMail");
  useEffect(() => {
    HistoryCampaigns();
  }, []);

  return (
    <>
      <div
        className={`w-full flex bg-white h-screen ${
          !isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
        <div className="bg-theme-color h-screen"></div>
        <div className="w-full mb-12 4xl:pr-[2.5rem] 3xl:pr-0 h-[100vh] overflow-y-scroll">
          <div className="justify-between items-center py-4 hidden xs:flex sm:flex md:flex pl-4 mb-12">
            <FiMenu
              className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`}
              onClick={() => {
                setIsVisibleSb(!isVisibleSb);
              }}
            />
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium">
              History Campaigns
            </h1>
            <AuroLogo />
          </div>
          <div className="pl-4 4xl:pr-6 pr-4 flex justify-between lg:items-center mt-4 items-end mb-12 xs:hidden sm:hidden md:hidden">
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium">
              History Campaigns
            </h1>
            <div className="xs:hidden sm:hidden">
              <AuroLogo />
            </div>
          </div>
          {historyCamapaignState.length > 0 ? (
            <div className="">
              {/* <div className="search relative ml-4">
                  <input type="text" className="4xl:text-[16px] text-[14px] w-1/2 border p-2 pl-8 rounded-md" placeholder="Search mails" />
                  <AiOutlineSearch className="absolute left-2 top-1/2 -translate-y-2/4 text-gray-500" />
                </div> */}
              {/* <div className="refresh flex items-center gap-4 ml-4 pl-2 my-6">
                  <input type="checkbox" name="" id="" className="w-5 h-5 rounded-md 4xl:ml-2 3xl:ml-4 cursor-pointer" />
                  <AiOutlineReload className="text-gray-400 text-[20px] cursor-pointer" onClick={() => { HistoryCampaigns() }} />
                  <img src={del} alt="" className="w-5 h-5 cursor-pointer" />
                </div> */}
              <div className="main h-[75vh] overflow-y-scroll no-scrollbar mx-4 flex 4xl:text-[16px] 3xl:text-[14px] text-[12px] flex-col 4xl:mr-12 3xl:ml-6 ml-4">
                <>
                  {historyCamapaignState &&
                    historyCamapaignState.map((item) => (
                      <div
                        className={`border-b-1 group bg-[#F2F6FC] relative items-center py-2 pl-6 pr-4 px-4 gap-2 border-white grid w-full grid-cols-[2fr_9fr_1fr] hover:bg-[#C2DBFF] hover:shadow-inbox hover:border-b-0 hover:z-10 `}
                      >
                        <img
                          src={dots}
                          alt=""
                          className="hidden absolute left-2 h-5 top-1/2 -translate-y-1/2 group-hover:block"
                        />
                        <div className="flex gap-2 items-center">
                          {/* <input type="checkbox" name="" id="" className="w-5 h-5 rounded-md cursor-pointer" onChange={(e) => { handleChange(e.target.checked, item._id) }} /> */}
                          <span>
                            To:<span>{item.groupName}</span>
                          </span>
                        </div>
                        <div className="flex items-center cursor-pointer">
                          <span
                            className={`bg-[#b8b8b85e] px-2 py-1 mr-2 text-[] rounded-md text-black`}
                          >
                            {item.categoriesType}
                          </span>
                          <span className="text-black font-semibold whitespace-nowrap">
                            {item.subject}
                          </span>
                          <span className="text-black font-normal ml-2 opacity-50">
                            {Parser(item.content)}
                          </span>
                        </div>
                        <div className="justify-self-end group:hover-hidden">
                          <p className="text-theme-color font-bold group-hover:hidden">
                            {item.sent_at.slice(0, -12)}
                          </p>
                          <MdDelete
                            className="text-gray-500 text-[20px] hidden group-hover:block cursor-pointer"
                            onClick={() => {
                              DeleteSentEmail(item._id);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </>
              </div>
            </div>
          ) : (
            <div
              className={`flex justify-start items-center w-full
                      h-[75vh] flex-col `}
            >
              <img
                src={nooData}
                alt=""
                className="w-2/5
                      object-cover"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default HistoryCampaign;
