import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuroLogo from "../../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";
import { DataContext } from "../../../context/data/DataState";
const CategoryHeader = () => {
  const location = useLocation();
  
  const navigate = useNavigate();

  const handleNavigate=()=>{
    navigate('/create-categories')
  }
const {isVisibleSb, setIsVisibleSb } = useContext(DataContext)
  const groupInfo = JSON.parse(localStorage.getItem('userInfo'));
  return (
    <>
      <div className={`pl-4`}>
<div className="hidden flex-col gap-4 xs:flex sm:flex pt-4 md:flex ">
  <div className="flex justify-between items-center pr-4">
  <FiMenu className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`} onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>
    
    <AuroLogo/>
  </div>
  <div className="flex justify-between items-center">
  <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Categories</h1>
  <div className="flex items-start gap-2 pt-4 px-4">
            {
              location.pathname === '/categories' ?  <button type='button' className="p-[.5rem_1.5rem] font-medium text-[14px] border-1 border-theme-color bg-white text-theme-color rounded-lg ... " onClick={()=>{handleNavigate()}}> Create Categories
            </button>  : <></>
              
            }
          </div>
  </div>

</div>

        <div className="flex justify-between items-center 4xl:pr-6 pr-4 xs:hidden sm:hidden md:hidden">
          <div className="pt-4">
          <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Categories</h1>
            {/* <p className="3xl:text-[22px] 4xl:text-[30px] mb-[16px] text-[18px] lg:mb-2 xl:mb-2 font-medium text-[rgba(22,_22,_22,_0.6)] 4xl:mt-0">{groupInfo?.name}</p> */}
          </div>
          <div className="flex items-start gap-2 pt-4">
            {
              location.pathname === '/categories' ?  <button type='button' className="p-[.5rem_1.5rem] mt-[10px] lg:p-[.4rem_1rem] mr-4 xl:p-[.4rem_1rem] lg:text-[13px] xl:text-[13px] font-medium 3xl:text-[16px] 4xl:text-[24px] border-1 border-theme-color bg-white text-theme-color rounded-lg ... " onClick={()=>{handleNavigate()}}> Create Categories
            </button>  : <></>
              
            }
            <AuroLogo/>
          </div>
          
        </div>
        
      </div>
    </>
  )
}
export default CategoryHeader;