import React from "react";
import { useContext } from "react";
import { DataContext } from "../../context/data/DataState";
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ch_temp from '../../assets/images/Choose-amico.svg';
import { AiOutlineClose } from 'react-icons/ai';

const CopyGroupModal = () => {
  const { copyGroupPopUp,
    setCopyGroupPopUp, CopyAllData, deleteGroupId , CopyData} = useContext(DataContext);
  const [openPopGroup, setOpenPopGroup] = useState(true);
  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden w-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-gray-50 p-6">
                    <AiOutlineClose className="absolute right-5 top-5 text-red-500 text-[22px] cursor-pointer" onClick={() => setCopyGroupPopUp(false)} />
                    <img src={ch_temp} alt="" className="flex mx-auto w-1/2" />
                    <div className="flex justify-center pt-12 gap-4">
                      <button
                        type="button"
                        className="border-1 border-theme-color p-[.45rem_1.5rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg"
                        onClick={() => {
                          CopyAllData(deleteGroupId)
                        }}
                      >
                        Copy Group With Data
                      </button>
                      <button
                        type="button"
                        className="border-1 border-theme-color p-[.45rem_1.5rem] text-[14px] 3xl:text-[16px] 4xl:text-[20px] bg-theme-color text-white disabled:opacity-40 rounded-lg"
                        onClick={() => {
                          CopyData(deleteGroupId)
                        }}
                      >
                        Copy Group Without Data
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default CopyGroupModal;