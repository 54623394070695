import React, { useContext } from "react";
import { useState } from "react";
import SideNavBar from "../SideNavBar/SideNavBar";
import emailcampaign from '../../assets/images/email_campaign.svg';
import messagecampaign from '../../assets/images/messagecampaign.svg';
import ivrcampaign from '../../assets/images/ivrcampaign.svg';
import whatsappcampaign from '../../assets/images/whatsappcampaign.svg';
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/data/DataState";
import AuroLogo from "../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";
import { useMediaQuery } from 'react-responsive';



const CreateCampaign = () => {
  const navigate = useNavigate()
  const { setSendEmailData, sendEmailData } = useContext(DataContext);
  const [cardState, setcardState] = useState([{
    cardName: 'E-mail',
    stateemailcampaign: emailcampaign,
    navigatepath: `/send-email`,
    type: 'Email'
  }, {
    cardName: 'Messages',
    stateemailcampaign: messagecampaign,
    navigatepath: '/send-message',
    type: 'Message'
  }, {
    cardName: 'Whatsapp',
    stateemailcampaign: whatsappcampaign,
    navigatepath: '/send-whatsapp',
    type: 'Whatsapp'
  }, {
    cardName: 'IVR',
    stateemailcampaign: ivrcampaign,
    navigatepath: '/send-ivr',
    type: 'IVR'
  },
  ]);
const {isVisibleSb, setIsVisibleSb } = useContext(DataContext)
const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });


  return (
    <div className={`w-full flex bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
      <div className="bg-theme-color h-screen">
        
      </div>
      <div className="w-full 4xl:mb-12 3xl:pr-0 h-[100vh] overflow-y-scroll ">
      <div className="justify-between hidden xs:flex sm:flex md:flex items-center px-4 mb-8 mt-3">
      <FiMenu className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`} onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>
        <h1 className="text-[20px] sm:text-[16px] xs:text-[13px] item-center text-center font-medium pl-4">
          Create campaigns
        </h1>
        <AuroLogo/>
        </div>

        <div className="flex justify-between 4xl:pr-6 pr-4 xs:hidden sm:hidden md:hidden">
        <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium pl-4 mb-8 pt-6">
          Create campaigns
        </h1>
        <AuroLogo/>
        </div>
        <div className="4xl:px-16 px-4 2xl:px-8 3xl:px-12 grid lg:gap-x-4 xl:gap-x-4 2xl:gap-x-6 gap-y-8 gap-x-[54px] 3xl:gap-y-12  4xl:gap-y-20 grid-cols-2 grid-rows-2 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 place-items-center">
          {cardState.map((item) => (
            <>
              <div className="4xl:w-[540px] w-[220px] xl:w-[300px] 2xl:w-[340px] lg:h-auto xl:h-auto 2xl:h-auto cursor-pointer 4xl:h-[388px] 3xl:w-[405px]  3xl:h-[291px] sm:h-[165px] sm:w-[205px] xs:h-[145px] xs:w-[190px] rounded-xl 4xl:rounded-2xl 3xl:rounded-2xl border overflow-hidden shadow-[0px_5px_18px_-5px_rgba(16,_24,_40,_0.08)]" onClick={() => {
                setSendEmailData({ ...sendEmailData, categoriesType: item.type });
                localStorage.setItem("CategoryType", JSON.stringify(item.type));
                setSendEmailData({
                  from: '',
                  groupAudience: '',
                  to: '',
                  subject: '',
                  body: '',
                  groupId: '',
                  startDate: '',
                  endDate: '',
                  every: '',
                  time: '',
                  categoriesType: '',
                  daysofweek: { "sunday": false, "monday": false, "tuesday": false, "wednesday": false, "thursday": false, "friday": false, "saturday": false }
                });
                navigate(item.navigatepath)
              }}>
                <img className="w-full 4xl:h-[292px] sm:h-[119px] xs:h-[100px] 3xl:rounded-2xl 4xl:rounded-2xl rounded-xl object-cover m-auto" src={item.stateemailcampaign} alt="" />
                <div className="px-2 pb-2">
                  <div className="flex py-3 lg:p-2 text[18px] xs:text-[14px] 2xl:text-[20px] items-center justify-center 4xl:text-[36px] 4xl:font-normal 4xl:leading-[54px] 3xl:text-[27px]">
                    {item.cardName}
                  </div>
                </div>
              </div>
            </>
          ))
          }
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}
export default CreateCampaign;