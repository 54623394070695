import React from "react";
import terms from '../../assets/images/terms.svg';

const TermsCondition = () => {
  return (
    <>
      <div className="w-4/5 mt-2 text-justify 3xl:text-[18px] 4xl:text-[20px] leading-normal text-[14px] m-auto scrollbar-hide xs:block xs:w-11/12 md:block md:w-11/12 sm:block sm:w-11/12 px-20 pt-8 rounded-xl text-light_black pb-16">
        <h1
          className=" text-center text-theme-color  3xl:text-4xl 4xl:text-5xl text-3xl font-bold pb-4
        "
        >
          Terms and Conditions
        </h1>
        <div className="">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
          facere ab dignissimos ipsa quibusdam delectus mollitia fugiat
          temporibus nam, minus omnis atque eum esse exercitationem incidunt
          repudiandae? Fugiat fugit, magni dolorem vitae tenetur ut minus,
          voluptatem iste amet ipsam laborum, reprehenderit omnis rerum?
          Quisquam, pariatur quasi nemo, velit nulla distinctio cupiditate
          tempore in impedit esse eum. Delectus rerum culpa adipisci non.
          Iste, doloribus similique aliquid distinctio, tenetur quo quis esse
          sit ullam eaque expedita nostrum necessitatibus ipsum, accusamus
          maxime voluptas libero pariatur doloremque et tempora? Quae possimus
          in excepturi doloribus laboriosam quaerat rem temporibus ipsam saepe
          repellendus expedita esse earum delectus aut et sunt, porro a,
          rerum, culpa perferendis alias. Ipsam, libero eaque illo rerum fuga
          culpa. Libero explicabo illo perspiciatis harum voluptate ex, a id
          aliquam fuga! Corrupti praesentium eveniet itaque suscipit iste?
          Eius, aliquam unde laudantium rem dicta et quia facere sed
          exercitationem cumque harum nulla dolores asperiores dolorem
          cupiditate quo rerum doloribus labore explicabo, consequatur
          perspiciatis vero molestiae molestias alias. Suscipit porro quo
          sequi qui voluptatem error nam aperiam quam dolore? Omnis ad
          corrupti eos dicta perferendis voluptatibus iure, fugit rerum illo
          non, expedita dolorum culpa recusandae quam. Neque quis nesciunt
          officia quaerat blanditiis eveniet! Animi debitis non eveniet amet
          nihil id molestiae, quasi reiciendis quas incidunt in, ea quaerat.
          Dolorem eos inventore quae omnis expedita obcaecati voluptas vitae
          dolores, rerum deserunt ullam mollitia deleniti quis incidunt
          dignissimos minima beatae tenetur cum blanditiis. Ullam ad quas,
          nemo cum eos id? Omnis iure doloremque fugiat quam, ut dolorum,
          sequi placeat veniam dolores sapiente aspernatur eum itaque! Nihil
          maxime, fugiat neque architecto, laborum quos, voluptatum totam
          voluptatibus doloribus asperiores maiores. Perferendis excepturi,
          nisi et aut at expedita quasi veritatis maiores voluptas repudiandae
          qui quae labore possimus, cupiditate eveniet consectetur incidunt
          iusto fugit voluptatibus, eius quas necessitatibus. Dolor,
          consequuntur? Error, quos maiores? Ut at eos magnam, placeat quaerat
          soluta sed perferendis commodi! Saepe amet assumenda placeat nihil
          at. Nisi, soluta. Sapiente sint modi repellendus ipsa suscipit
          corrupti molestiae ducimus, praesentium enim consequatur nobis
          aliquid voluptates perspiciatis. Sunt, facilis consectetur possimus
          eos quam laboriosam consequuntur magnam culpa animi molestiae
          commodi? Pariatur adipisci sed ad impedit laboriosam, fugiat ut
          sapiente! Libero autem quis rem ducimus blanditiis voluptatum saepe
          nulla unde molestias impedit. Magnam quisquam atque nisi error
          laudantium distinctio quis hic ratione, tempora totam perferendis
          praesentium impedit accusantium odio labore eum magni fugiat libero
          id maiores, dicta repudiandae quae modi! Ipsam eum rerum similique
          ut odit quam architecto facere iste eaque dicta, dolor ad eveniet
          eligendi odio perspiciatis repudiandae nemo recusandae ipsa est
          voluptates saepe expedita. Molestias reprehenderit id similique
          architecto, impedit, rem recusandae enim delectus officiis deleniti
          qui porro! Veniam voluptatem quidem, recusandae quos temporibus
          animi eius earum et voluptas atque natus explicabo ex dolore!
          Aliquam in corporis at est odio debitis nisi, error accusantium nam
          soluta placeat? Eius, ea quasi deleniti dolorum distinctio quisquam
          molestias voluptates iusto similique, temporibus quae et, rerum sunt
          obcaecati. Animi non, placeat, est ipsa necessitatibus, aut sed
          fugit nostrum sequi recusandae eveniet. In facilis, ad placeat
          impedit dolores ea, laboriosam tempore sed veniam ex obcaecati quas
          dolor non quibusdam labore repellat illum! Laudantium fugiat est
          magni, eveniet nulla corrupti laborum unde consequatur quod
          accusamus perferendis aut libero minima porro quia a accusantium
          ratione? Quibusdam odit, cumque possimus provident porro accusantium
          ducimus rerum? At tenetur amet quaerat, eum incidunt minima
          consectetur facilis, optio tempore doloremque inventore? Quae
          laborum molestiae magnam? Fugiat necessitatibus totam recusandae
          doloremque expedita, ullam nostrum perferendis fuga veritatis hic
          iusto quae inventore non dolores aliquam commodi aperiam dolorem
          deleniti voluptatem. Harum quos dolor nisi consectetur, labore unde
          ipsum deserunt, distinctio quae dignissimos vel consequuntur laborum
          laboriosam saepe ea nihil earum accusantium esse, veritatis natus
          ducimus delectus vero doloremque! Porro quaerat sunt labore officia
          ipsa quos, fugit magni. Velit fuga modi alias rem rerum nisi sint!
          Voluptates, aut praesentium quasi suscipit dicta maxime expedita
          quia natus dolor soluta sunt ex labore error dignissimos, placeat
          officia quisquam. Vel quae omnis quam hic, harum consequatur eveniet
          velit perspiciatis quos rerum dolor ipsa ab non optio ratione
          repellat. Consectetur enim cumque quod quae. Reiciendis deserunt
          esse iusto, quod necessitatibus magni nihil vero! Vero ipsa sunt
          quos, voluptatibus quam laudantium a at, accusantium animi fuga
          reiciendis hic. Unde ab nihil maiores ratione dolore minus id
          doloribus voluptates quisquam expedita impedit explicabo aperiam,
          saepe rem, ad earum assumenda illo tempora est voluptate eum numquam
          aliquam? Eligendi suscipit officiis obcaecati id sed exercitationem
          atque, veritatis aut nemo accusantium adipisci. Ex praesentium
          mollitia dolor molestiae quibusdam possimus minus aperiam soluta,
          blanditiis quo adipisci nesciunt labore fugit ipsa quae, vitae, est
          error debitis nobis provident? Error dicta velit reiciendis quisquam
          reprehenderit ex! Excepturi qui dignissimos autem repellat
          necessitatibus laudantium possimus! Nulla provident quasi ea
          voluptate mollitia repellat quae animi laudantium voluptates,
          aspernatur doloremque ullam blanditiis? Voluptates inventore,
          accusantium doloremque, earum est corporis dolorem officia magnam
          laboriosam pariatur odit ratione culpa id iure omnis quae nulla
          labore cupiditate qui rem! Dicta at iusto dolorem accusamus alias,
          eum qui eligendi aperiam consequatur quam iste natus. Maxime neque
          molestias explicabo quisquam nulla quam quidem modi aliquam quas
          voluptatum vitae ut cum, aut voluptas quasi error! Velit
          exercitationem dolorum est explicabo obcaecati similique inventore
          facilis nobis saepe, veniam molestiae ipsam incidunt iste quos optio
          dolores ea nulla, officiis reprehenderit ipsa architecto? Dolorum
          neque consequuntur sapiente aspernatur ad reiciendis rerum,
          dignissimos expedita velit dolorem corporis voluptatibus minus
          temporibus veniam ratione vitae animi accusamus. Eveniet rerum
          itaque corporis, debitis quas sit quibusdam explicabo nulla, numquam
          magnam eius soluta nesciunt? Totam quod nisi sunt odio nulla
          blanditiis veritatis distinctio architecto ullam quos, libero,
          maiores molestias delectus porro quisquam recusandae suscipit? Quam,
          odio non? Neque alias rem illo ipsa eum aperiam molestiae dicta
          quasi dolorum quod voluptas harum quos, debitis impedit porro fugit
          accusantium modi, suscipit dolorem assumenda at amet! Nam veritatis
          est voluptatum consequatur ad aut fugit aliquid impedit facere.
          Maxime laudantium culpa, aperiam tenetur vitae asperiores rerum.
          Quia vero eius laborum sed, cupiditate quaerat, amet ut ducimus
          molestiae aperiam, assumenda repellat obcaecati minus dolor!
          Recusandae dolore iusto perferendis.
        </div>
      </div>
    </>
  );
};
export default TermsCondition;
