import React, { useContext } from "react";
import SideNavBar from "../SideNavBar/SideNavBar";
import CategoryHeader from "../Templates/Categories/CategoryHeader";
import noTemplateData from "../../assets/images/nodata.svg";
import { DataContext } from "../../context/data/DataState";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import defaultCategory from "../../assets/images/defaultCategory.png";
import nooData from "../../assets/images/No data.gif";

const CampaignCategory = () => {
  const navigate = useNavigate();
  const { getCategoryState, getCategory, sendEmailData, setSendEmailData , isVisibleSb , isSmallScreen } =
    useContext(DataContext);

  const handleNavigate = (item) => {
    const id = item._id;
    navigate(`/select-template/${id}`);
  };

  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("CategoryType")));
    setSendEmailData({
      ...sendEmailData,
      categoriesType: JSON.parse(localStorage.getItem("CategoryType")),
    });
  }, []);

  useEffect(() => {
    getCategory(JSON.parse(localStorage.getItem("CategoryType")));
  }, []);

  return (
    <>
      <div
        className={`w-full flex bg-white h-screen ${
          !isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        <div className="bg-theme-color h-screen"></div>
        <div className="w-full mb-12 4xl:pr-[2.5rem] 3xl:pr-0 h-[100vh] overflow-y-scroll">
          <CategoryHeader />
          <div
            className={`h-[75vh] overflow-x-scroll] pl-4 ${
              getCategoryState.length === 0
                ? "flex justify-center items-center"
                : "grid grid-flow-dense gap-4 pr-12 pt-6 4xl:grid-cols-4x 3xl:grid-cols-3 lg:grid-cols-2 grid-cols-3x w-full place-items-baseline lg:place-items-center justify-start 4xl:gap-x-8 4xl:pr-12 3xl:gap-4 gap-y-12 ml-auto"
            }`}
          >
            {getCategoryState?.length === 0 ? (
              <img src={noTemplateData} className="h-[50vh] m-auto"></img>
            ) : (
              getCategoryState?.map((item) => (
                <>
                  <div class="3xl:w-[262px] rounded-2xl 3xl:h-[230px] xl:w-[280px] 2xl:w-[200px] table-wrp 4xl:w-[305px] 4xl:h-[305px] 3xl:rounded-xl 4xl:rounded-2xl border overflow-hidden shadow-[0px_5px_18px_-5px_rgba(16,_24,_40,_0.08)]">
                    {item.image === "" ? (
                      <img
                        className="w-full xl:h-[190px] lg:h-[150px] 2xl:h-[128px] 3xl:h-[142px] 4xl:h-[189px] h-[190px] rounded-2xl object-cover  m-auto"
                        src={defaultCategory}
                        alt=""
                      />
                    ) : (
                      <img
                        className="w-full xl:h-[190px] lg:h-[150px] 2xl:h-[128px] 3xl:h-[142px] 4xl:h-[189px] h-[190px] rounded-2xl object-cover  m-auto"
                        src={item.image}
                        alt=""
                      />
                    )}
                    <div class="px-2 pb-2 2xl:px-1">
                      <div class="flex py-3 items-center justify-center">
                        <p className="font-normal xl:text-[17px]  lg:text-[17px] 3xl:text-[18px] 4xl:text-[22px]">{`${item.categoryName.slice(
                          0,
                          18
                        )}`}</p>
                        <span className=" text-[#16161699] cursor-pointer ml-1 inline-block text-[13px] 4xl:text-[18px]"></span>
                      </div>
                      <div class="flex justify-center gap-2">
                        <button
                          type="button"
                          className="3xl:text-[11px] xl:text-[10px] lg:p-[4px_6px] lg:text-[8px] 4xl:text-[14px] 2xl:text-[10px] 2xl:p-[4px_5px] p-[6px_14px] 4xl:p-[8px_24px] font-normal bg-theme-color text-white rounded-lg ... "
                          onClick={() => {
                            handleNavigate(item);
                          }}
                        >
                          View Template
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignCategory;
