import React from "react";
import { useState } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import GroupHeader from "./GroupHeader";
import bin from '../../../assets/images/bin.svg';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Dialog } from '@headlessui/react';
import danger from '../../../assets/images/danger.svg';
import { useRef } from "react";
import GroupSucessModal from "../../Modal/GroupSucessmodal";
import ColumnSucessModal from "../../Modal/ColumnSucessModal";

const UpdateGroup = () => {
  const { id } = useParams()
  const { createColumn, UpdateGroupName, getColumn, columnHeader, updateColumn, updateGroupNameModal, updateColumnModal,
    setUpdateColumnModal } = useContext(DataContext);
  const location = useLocation();
  const { from } = location.state;
  const [groupState, setGroupState] = useState({
    group: from.groupName
  });

  const [columnList, setColumnList] = useState(columnHeader);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  };

  const handleAddColumn = () => {
    setColumnList([...columnList, {
      columnName: "",
      dataType: "",
      validation: "",
      maximumLength: "",
      required: false,
      unique: false
    }])
  };
  const [columnDeleteIndex, setColumnDeleteIndex] = useState()
  const handleRemoveColumn = () => {
    const list = [...columnList]
    list.splice(columnDeleteIndex, 1)
    setColumnList(list)
  };
  const buttonDisabled = (groupState.group !== '');

  const handleUpdateGroup = () => {
    UpdateGroupName(groupState.group, from._id)
  };

  const handleSaveColumn = () => {
    updateColumn(columnList, from._id);
  };

  const handleCreatingColumn = (e, index) => {
    const { name, value, checked } = e.target
    const list = [...columnList]
    if (name === 'required' || name === 'unique') {
      list[index][name] = checked;
    }
    else {
      list[index][name] = value;
      if (name === 'dataType') {
        list[index].validation = '';
      }
    }
    setColumnList(list)
  };

  const [validationTypeState, setValidationTypeState] = useState()

  const handleChecking = (e, index) => {
    console.log(columnList[index].dataType, 'dataType')
    if (columnList[index].dataType === 'string') {
      setValidationTypeState(['string', 'email'])
    }
    else if (columnList[index].dataType === 'number') {
      setValidationTypeState(['number' , 'mobilenumber'])
    }
    else if(columnList[index].dataType === 'dob'){
      setValidationTypeState(['dob'])
    }
    else if (columnList[index].dataType === "") {
      setValidationTypeState()
    }
  };

  const [openPopDelete, setOpenPopDelete] = useState(true);
  const [columnLoader, setColumnLoader] = useState(false);
  const cancelButtonRef = useRef(null);
  const deleteButtonRef = useRef(null);


  useEffect(() => (
    getColumn(from._id)
  ), []);

  useEffect(() => {
    columnHeader.length === 0 ? setColumnList([{
      columnName: "",
      dataType: "",
      validation: "",
      maximumLength: "",
      required: false,
      unique: false,
    }]) :
      setColumnList(columnHeader)
  }, [columnHeader])
  console.log(columnList, 'columnList')

  return (
    <>
      {updateGroupNameModal === true ? <GroupSucessModal></GroupSucessModal> : <></>}
      {updateColumnModal === true ? <ColumnSucessModal></ColumnSucessModal> : <></>}
      {
        columnLoader ? <>  <Transition.Root show={openPopDelete} as={Fragment} >
          <Dialog as="div" className="relative z-10" initialFocus={deleteButtonRef} onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden 3xl:w-[45%] 4xl:w-[45%] w-4/5 rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-2 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="m-auto w-56 flex h-28 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                          <img className="w-[120px]" src={danger} ></img>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <div className="mt-6">
                            <p className="text-sm text-gray-500">
                              Are you sure, you want to delete this column?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => { setOpenPopDelete(true); setColumnLoader(false); handleRemoveColumn() }}
                        ref={deleteButtonRef}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => { setOpenPopDelete(true); setColumnLoader(false); }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root> </> : <></>
      }
      <div className="w-full flex bg-[#F5F5F5] h-screen">
        <div className="bg-theme-color h-screen">

        </div>
        <div className="w-full mb-12 h-screen overflow-y-scroll">
          <GroupHeader />
          <div className="pl-4 pr-12 pt-6 ">
            <div className="mt-4 pb-2 text-[28px] lg:text-[22px] xl:text-[22px]">
              Update Group Name
            </div>
            <div className="flex gap-4">
              <input type="text" placeholder="Enter group name" name="group" defaultValue={from.groupName} value={groupState.group} onChange={(e) => setGroupState({ ...groupState, group: e.target.value })} className=" 
             3xl:h-[46px] lg:w-1/3 lg:text-[12px] xl:text-[12px] 2xl:text-[14px] xl:w-1/3 w-1/4 rounded-lg 4xl:h-[55px] lg:text-[] border border-slate-300 rouded-lg placeholder-slate-400 lg:p-[.3rem_1rem] xl:p-[.3rem_1rem] 2xl:p-[.3rem_1rem] p-[20px]" />
              <button type='button' className="text-[14px] 3xl:text-[16px] 4xl:text-[18px] font-normal p-[.45rem_1.5rem] border-1 border-theme-color bg-theme-color text-white disabled:opacity-40 rounded-lg ... " onClick={() => { handleUpdateGroup() }}>
                Update Group
              </button>
            </div>
          </div>

          <div className={`px-4 mt-3  
          `}>
            <div className="text-[24px] 4xl:text-[28px] lg:text-[18px] xl:text-[18px] mt-6 mb-2">
              Column Names
            </div>
            <div>
              <form className="pb-4">
                {
                  columnList?.map((item, index) => (
                    <>
                      <div key={index}>


                        <div className="flex items-center gap-1 4xl:gap-4">
                          <input type="text" placeholder="Enter a Column Name" defaultValue={item.columnName} name="columnName" value={columnList[index].columnName} className="w-[20%] lg:h-[32px] xl:h-[32px] h-[41px] lg:w-2/12 xl:w-2/12 lg:text-[11px] xl:text-[11px] border border-slate-300 placeholder-slate-400 lg:p-2 xl:p-2 p-[20px]" onChange={(e) => handleCreatingColumn(e, index)} />

                         <div className="flex items-center ml-2 lg:w-2/12 xl:w-2/12">
                            <label for="dataColumnList"></label>
                            <select name="dataType" id="dataType" defaultValue={item.dataType} value={columnList[index].dataType} className="lg:text-[11px] bg-white xl:text-[11px] h-[41px] lg:h-[32px] xl:h-[32px] w-full border pl-1" onChange={(e) => handleCreatingColumn(e, index)}>
                              <option value="Choose data type">Choose data type</option>
                              <option value="string">String</option>
                              <option value="number">Numeric</option>
                              <option value="dob">DOB</option>
                            </select>
                          </div>

                         {/* <div className="flex items-center ml-2 lg:w-2/12 xl:w-2/12">
                            <label for="validateColumnList"></label>
                            <select name="validation" id="validation" className="w-[160px] bg-white lg:w-full xl:w-full lg:h-[32px] xl:h-[32px] lg:text-[11px] xl:text-[11px] h-[41px] border pl-1" value={columnList[index].validate} defaultValue={item.validation} onChange={(e) => handleCreatingColumn(e, index)}
                              onClick={(e) => { handleChecking(e, index) }}
                            >
                              <>
                                <option className="">Choose your data Type first</option>
                                <option value="string">Name</option>
                                <option value="number">Number</option>
                                <option value="email">Email</option>
                                <option value="mobilenumber">Phone Number</option>
                              </>

                            </select>
                          </div> */}



                          {/* <div className="flex items-center ml-2 lg:w-3/12 xl:3/12">
                            <label for="dataColumnList"></label>
                            <select name="dataType" id="dataType" className="lg:text-[11px] bg-white xl:text-[11px] h-[41px] lg:h-[32px] xl:h-[32px] w-full border pl-1" onChange={(e) => { handleCreatingColumn(e, index) }}>
                              <option value="Choose data type" >Choose data type</option>
                              <option value="string">String</option>
                              <option value="number">Numeric</option>
                            </select>
                          </div> */}

                          <div className="flex items-center ml-2 lg:w-2/12 xl:2/12">
                            <label for="validateColumnList"></label>
                            <select name="validation" id="validation" value={columnList[index].validation} className="w-[160px] lg:w-full bg-white xl:w-full lg:h-[32px] xl:h-[32px] lg:text-[11px] xl:text-[11px] h-[41px] border pl-1" onClick={(e) => { handleChecking(e, index) }}
                              onChange={(e) => { handleCreatingColumn(e, index) }}>
                              <option value="Validate field" >
                                {columnList[index].validation === '' ? <>Validate Field</> :
                                  columnList[index].validation === 'string' ? 'Name' : columnList[index].validation}
                              </option>
                              {
                                validationTypeState === undefined ?
                                  <option>Choose your data Type first</option>
                                  :
                                  validationTypeState?.map((item) => (
                                    <option value={item}>{item === 'string' ? 'Name' : item}</option>
                                  ))
                              }
                            </select>
                          </div>



                          <input type="text" placeholder="Enter maximum length" value={columnList[index].maximumLength} defaultValue={item.maximumLength} name="maximumLength" className="ml-2 lg:h-[32px] xl:h-[32px] lg:w-2/12 xl:w-2/12 lg:text-[11px] xl:text-[11px] text-sm w-[20%] h-[35px] border
               border-slate-300 placeholder-slate-400 p-[8px]" onChange={(e) => handleCreatingColumn(e, index)} />
                          <div className="ml-4">

                            <small className="text-theme-color lg:text-[11px] xl:text-[11px]">
                              Required
                            </small>
                            <div className="flex gap-1">
                              <input type="checkbox" id="required" name="required" value={columnList[index].required} defaultValue={item.required} defaultChecked={item.required} onChange={(e) => handleCreatingColumn(e, index)} /> <small>
                                Yes
                              </small>
                            </div>
                          </div>
                          <div className="ml-4">
                            <small className="text-theme-color lg:text-[11px] xl:text-[11px]">
                              Unique
                            </small>
                            <div className="flex gap-1">
                              <input type="checkbox" id="unique" name="unique" value={columnList[index].unique} defaultValue={item.unique} defaultChecked={item.unique} onChange={(e) => handleCreatingColumn(e, index)} /> <small>
                                Yes
                              </small>
                            </div>
                          </div>

                          {
                            columnList.length > 1 && (<img src={bin} width='24px' className="cursor-pointer w-[24px] ml-[15px] xl:ml-4 lg:ml-4" onClick={() => { setColumnLoader(true); setColumnDeleteIndex(index) }}></img>)
                          }
                        </div>
                        <br></br>

                      </div>
                      {columnList.length - 1 === index && (
                        <button type='button' className="mt-6 p-[.45rem_1.3rem] text-[14px] 3xl:text-[16px] 4xl:text-[18px] font-normal bg-white text-theme-color border-1 border-theme-color rounded-lg ...  ... " onClick={() => handleAddColumn()}>
                          Add column
                        </button>)}
                    </>
                  ))
                }
                <button type='button' className=" ml-4 p-[.45rem_1.3rem] text-[14px] 3xl:text-[16px] 4xl:text-[18px] font-normal bg-theme-color text-white rounded-lg ...  ... " onClick={() => { handleSaveColumn() }}>
                  Update column
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UpdateGroup;