import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import back from '../../assets/images/back.svg';
import passcodeicon from '../../assets/images/passcodeicon.svg';
import { DataContext } from "../../context/data/DataState";

const ResetPassword = () => {
  const [passError , setPassError] = useState(false)
  const [state, setState] = useState({
    newPassword: '',
    cNewPassword: ''
  });
  const { forgetUpdatePassword, forgetEmailState , resetPassError , setResetPassError } = useContext(DataContext);

  const buttonDisabled = (state.newPassword !== '', state.cNewPassword !== '');
  const validatePassword = ()=>{
    setResetPassError('')
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.newPassword !== state.cNewPassword) {
      setPassError(true)
    }
    else{
      forgetUpdatePassword(state, forgetEmailState.email)
    }
  };
  return (
    <div className="grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 w-full  lg:gap-24  place-content-center gap-0 max-h-screen">
      <div className="grid place-content-center  xs:pb-20 md:pb-0 relative">
        <div className="lg:ml-7 md:mx-5">
        <Link to='/forgot-password' ><img src={back}  className='w-[23px] h-[20px] absolute lg:top-[-20%] sm:mx-6 xl:mx-9 xs:left-6 top-[25%] md:top-[28%]  sm:top-[-21%] xs:top-[-11%] xl:top-[19%]  xl:left-[2.25rem]'></img> </Link>

          <div className="xl:px-4 sm:px-6 xs:px-6">
            <h1 className="text-theme-color lg:text-[28px] xl:text-[26px] mt-4 font-bold text-[24px] 3xl:text-[27px] 4xl:text-[39px]">
              Reset Your Password
            </h1>
            <p className="mt-3 3xl:text-[17px] xl:text-[14px] xl:ml-0 lg:ml-0 4xl:text-[22px] font-medium">
              Strong password include number, letter and punctuation Marks.
            </p>
            <form>
              <div className="relative mt-1 flex">
                <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                  <span className="text-gray-500 sm:text-sm"><img src={passcodeicon} width='18px' /></span>
                </div>
                <input type="text" placeholder="Enter your new password." name="newPassword" className="4xl:h-[55px] 3xl:h-[41px] mt-4 block m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color flex-auto
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            invalid:border-pink-500 invalid:text-pink-600
            focus:invalid:border-pink-500 focus:invalid:ring-pink-500 pl-[35px]
            " value={state.newPassword} onChange={(e) => setState({ ...state, newPassword: e.target.value })}
            onKeyUp ={()=>validatePassword()}
                />
              </div>
              <div className="relative mt-1 flex">
                <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2 pt-3.5">
                  <span className="text-gray-500 sm:text-sm"><img src={passcodeicon} width='18px' /></span>
                </div>
                <input type="text" placeholder="Confirm new password." name="cNewPassword" className="4xl:h-[55px] 3xl:h-[41px] mt-4 block m-auto px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-theme-color focus:ring-1 focus:ring-theme-color flex-auto
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            invalid:border-pink-500 invalid:text-pink-600
            focus:invalid:border-pink-500 focus:invalid:ring-pink-500 pl-[35px]
            "
                  value={state.cNewPassword} onChange={(e) => setState({ ...state, cNewPassword: e.target.value })}
                />
              </div>
              {passError ? <span className="text-red-600 text-[10px]">Password and confirm password must be same.</span> : <></>}
             <span className="text-red-600 text-[10px]">{resetPassError}</span> 
            </form>
            <button type='button' disabled ={!buttonDisabled} onClick={(e) => {handleSubmit(e) }} className="bg-theme-color text-white lg:rounded-xl xl:rounded-xl rounded-2xl mt-8 4xl:mt-9 text-[14px] 3xl:text-[18px] 4xl:text-[23px] font-bold p-[.7rem_5rem] 3xl:p-[.7rem_5rem]  4xl:p-[14px_113px] ...">Verify</button>
          </div>
          
        </div>
      </div>
      <div className="sm:hidden  xs:hidden h-screen">
        {/* <img src={sidebanner} className="w-full" />  */}
        <div className="h-full bg-sidebanner bg-no-repeat bg-cover bg-center w-full"></div>
      </div>
    </div>
  )
}
export default ResetPassword;