import React from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { AiOutlineRight } from 'react-icons/ai';
import { useState } from "react";
import { DataContext } from "../../../context/data/DataState";
import { useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SucessTempModal from "./TemplateModal/SucessTemplateModal";
const MessageCreateTemplate = () => {
  const { id } = useParams();
  const { columnHeader, groupListState, templates, setTemplates, groupList, getColumn, createMessageTemplate, ivrStatePopup, successDltTempPop } = useContext(DataContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValueOpen, setSelectedValueOpen] = useState(false);
  const getdata = (gid) => {
    getColumn(gid)
  }
  const SelectedValueOpen = (oid, groupname,) => {
    setTemplates({ ...templates, groupname: groupname })
    getdata(oid)
    setSelectedValueOpen(true)
  }
  const handleSelectedValue = (item) => {
    setTemplates({ ...templates, subgroupdata: item })
    setDropdownOpen(false);
    setSelectedValueOpen(false)
  };
  const [stateBody, setStateBody] = useState();
  const handleBodyValue = (e) => {
  };

  useEffect(() => {
    groupList()
  }, [])
  useEffect(() => {
    if (templates.groupname != "" && templates.subgroupdata != "") {
      setTemplates({ ...templates, subject: `Dear #${templates.groupname}/${templates.subgroupdata}` })
    } else {
      setStateBody("")
    }
  }, [templates.groupname, templates.subgroupdata]);

  const handleDltApproval = () => {
    createMessageTemplate(templates, id)
  };
  return (
    <div className="w-full flex bg-white h-screen">
      <div className="bg-theme-color h-screen">
        
      </div>
      <div className="w-full mb-12 h-screen overflow-x-scroll">
        {
          successDltTempPop === true ? <SucessTempModal></SucessTempModal> : <></>
        }
        <div className="pl-4 pr-12 mt-6">  
          <h1 className="text-[18px] font-medium pb-2">Template name</h1>
          <div className=" flex sm:flex-col xs:flex-col sm:gap-3 xs:gap-3 ">
          <input  type='text' onChange={(e) => setTemplates({ ...templates, templateName: e.target.value })} placeholder="Type name here" className="placeholder:text-left sm:py-2 xs:py-1 placeholder:text-[12px] 4xl:placeholder:text-[18px] rounded-lg border-black border flex justify-center font-normal pl-4 items-center bg-[#FAFAFA] text-[15px] 4xl:text-[18px]" />
            <div className="ml-4 sm:ml-0 xs:ml-0" onMouseLeave={() => { setDropdownOpen(false); setSelectedValueOpen(false) }}>
              <button className="justify-center  items-center gap-x-1.5  rounded-md bg-theme-color p-[.55rem_1.2rem] text-[12px] 4xl:text-[16px] font-normal text-white" onMouseEnter={() => setDropdownOpen(true)}>
                Insert system variable
              </button>
              {
                dropdownOpen && (
                  <>
                    <div className="absolute z-10 w-[215px] origin-top-right mt-[0.5px] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                      {groupListState.map((option) => (
                        <div key={option._id} className="text-[18px] lg:text-[13px] md:text-[12px] sm:text-[11px] xs:text-[11px] 2xl:text-[14px] pb-[10px] pl-[10px]">
                          <div className="flex cursor-pointer items-center justify-between px-2
                                                        " onClick={(e) => { SelectedValueOpen(option._id, option.groupName) }}>
                            <span className=" ">{option.groupName}</span> <span className="text-black"><AiOutlineRight /> </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )
              }
              {
                selectedValueOpen === true ? <> <div className="absolute z-10 ml-[215px] w-[171px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-[10px]">
                  {
                    columnHeader.map((item) => (
                      <div className="text-[18px] pb-[10px] pl-[10px]">
                        <div onClick={() => handleSelectedValue(item.columnName)}>
                          {item.columnName}
                        </div>
                      </div>
                    ))
                  }
                </div></> : <></>
              }
            </div>
            <div className="ml-4 sm:ml-0 xs:ml-0">
              <button className="justify-center items-center gap-x-1.5 rounded-md bg-theme-color p-[.55rem_1.2rem] text-[12px] 4xl:text-[16px] font-normal text-white">
                Insert user variable
              </button>
            </div>
          </div>
          <div className="w-full border-[#161616] border rounded-lg mt-4 min-h-[66vh] shadow-edit-shadow">
            <div className="px-[22px] pt-[20px] h-full">
              <div className="h-full">
                <textarea type="text" value={templates.subject} onChange={(e) => setTemplates({ ...templates, subject: e.target.value })} name="subject" rows="2" cols="50" placeholder="What's the subject line for this template" className="w-full  xs:h-[245px]  sm:h-[253px] md:h-[250px] lg:h-[275px] xl:h-[275px] 2xl:h-[382px] 3xl:h-[415px] 4xl:h-[650px]  text-[20px] xl:text-[15px] 2xl:text-[15px] lg:text-[13px] md:text-[11px] sm:text-[12px] xs:text-[11px] xl:placeholder:text-[14px] lg:placeholder:text-[12px] md:placeholder:text-[12px] sm:placeholder:text-[12px] xs:placeholder:text-[10px] mt-2 font-normal outline-0 mb-[35px]"></textarea>
              </div>
              <button className="4xl:mb-6 border-1  border-theme-color p-[8px_30px] mb-4 text-[14px] lg:text-[13px] md:text-[12px] sm:text-[11px] xs:text-[11px] 4xl:text-[24px] bg-theme-color text-white disabled:opacity-40 rounded-lg ..." onClick={() => { handleDltApproval() }}>
                Send for DLT approval
              </button>
              <div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  )
}
export default MessageCreateTemplate;