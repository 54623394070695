import React, { useContext } from "react";
import AuroLogo from "../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";
import { DataContext } from "../../context/data/DataState";
import { BiDownload } from "react-icons/bi";
import { useMediaQuery } from 'react-responsive'
import pdf from '../../assets/images/invoicecommunication.pdf'

const TransactionsHistory = () => {
  const { isVisibleSb, setIsVisibleSb } = useContext(DataContext);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });
  return (
    <div className={`w-full flex bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
      <div className="w-full mb-12 h-screen overflow-y-scroll">
        <div className="hidden justify-between items-center pl-4 pr-8 pt-4 xs:flex sm:flex md:flex">
          <FiMenu className="text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2" onClick={() => { setIsVisibleSb(!isVisibleSb) }} />
          <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">
            Transactions History
          </h1>
          <AuroLogo />
        </div>
        <div className="flex justify-between items-center pl-4 pr-8 pt-4 xs:hidden sm:hidden md:hidden">
          <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Transactions History</h1>
          <AuroLogo />
        </div>
        <div class="mr-12 ml-4 mt-12 txns overflow-scroll no-scrollbar h-[80vh]">

          <table class="w-full text-[18px] 3xl:text-[12px] border-collapse border font-medium">
            <thead class="sticky top-0 bg-theme-color text-white font-medium border border-theme-color">
              <th className="p-2">Payment invoice</th>
              <th className="p-2">Amount</th>
              <th className="p-2">Plan className</th>
              <th className="p-2">Date</th>
              <th className="p-2">Status</th>
              <th className="p-2">Action</th>
            </thead>
            <tbody class="">
              <tr className="">
                <td className="px-4 py-3 ">ID87877-April 2023</td>
                <td className="px-4 py-3 text-[#5DC264]">₹ 2400</td>
                <td className="px-4 py-3 ">Plan a</td>
                <td className="px-4 py-3 text-[#5F6D7E] ">Apr 14, 2023</td>
                <td className="px-4 py-3 text-[#048848]">Success</td>
                <td className="px-4 py-3 "><a target='blank' href={pdf} className="flex text-theme-color gap-1 items-center cursor-pointer"><BiDownload /><span>Download</span></a></td>
              </tr>
              <tr className="">
                <td className="px-4 py-3 ">ID87877-April 2023</td>
                <td className="px-4 py-3 text-[#5DC264]">₹ 2400</td>
                <td className="px-4 py-3 ">Plan a</td>
                <td className="px-4 py-3 text-[#5F6D7E] ">Apr 14, 2023</td>
                <td className="px-4 py-3 text-[#E11900]">Failed</td>
                <td className="px-4 py-3 "><a target='blank' href={pdf} className="flex text-theme-color gap-1 items-center cursor-pointer"><BiDownload /><span>Download</span></a></td>
              </tr>
              <tr className="">
                <td className="px-4 py-3 ">ID87877-April 2023</td>
                <td className="px-4 py-3 text-[#5DC264]">₹ 2400</td>
                <td className="px-4 py-3 ">Plan a</td>
                <td className="px-4 py-3 text-[#5F6D7E] ">Apr 14, 2023</td>
                <td className="px-4 py-3 text-[#FFC043]">Pending</td>
                <td className="px-4 py-3 "><a target='blank' href={pdf} className="flex text-theme-color gap-1 items-center cursor-pointer"><BiDownload /><span>Download</span></a></td>
              </tr>

            </tbody>
          </table>


        </div>
      </div>
    </div>
  );
};

export default TransactionsHistory;