import React, { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../context/data/DataState";
import SideNavBar from "../SideNavBar/SideNavBar";
import noTemplateData from '../../assets/images/notemplate.svg';


const SelectTemplate = () => {
  const navigate = useNavigate();
  const { GetSelectTemplate, selectTempState, selectedEmailTemplate, setSelectedEmailTemplate } = useContext(DataContext);

  const { id } = useParams();

  useEffect(() => {
    GetSelectTemplate(id)
  }, []);

  const handleNavigate = item => {
    navigate('/send-email')
    setSelectedEmailTemplate(item);
  };

  return (
    <>
      <div className="w-full flex bg-white h-screen">
        <div className="bg-theme-color h-screen">
          
        </div>
        <div className="w-full mb-12 4xl:pr-[2.5rem] 3xl:pr-0 h-[100vh] overflow-y-scroll">
          <div className="pl-4 pr-12 flex justify-between mt-4 items-end lg:items-center mb-4">
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium">Template List</h1>
          </div>
          <div className={`${selectTempState.length === 0 ? "pl-4 pr-12 min-h-[80vh] flex" : "pl-4 pr-12"}`}>
            {
              selectTempState.length === 0 ? <img src={noTemplateData} className="h-[50vh] m-auto"></img> :
                selectTempState.map((item) => (
                  <div className="mb-[32px]">
                    <div className="flex justify-between items-end">
                      <div className="w-4/5 flex">
                        <div className="flex items-center"><input className="cursor-pointer" type="checkbox" onClick={() => { handleNavigate(item) }}></input></div>
                        <div className="ml-[20px]">
                          <h1 className="3xl:text-[27px] font-semibold 4xl:text-[36px] 2xl:text-[22px] text-[18px] text-theme-color">{item.templateName}<span className="3xl:text-[11px] 4xl:text-[14px] text-[8px] font-normal text-[#16161699] ml-2">{`(Created on ${(item.createdAt).slice(0, 10)})`}</span></h1>
                          <p className="2xl:text-[16px] text-[14px] 3xl:text-[18px] 4xl:text-[24px] mt-2 opacity-60 leading-9 text-[#161616] font-normal 3xl:leading-[27px] break-words">{item.subject}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default SelectTemplate;