import React from "react"
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import sucess from '../../../assets/images/correct.svg';
import { useNavigate } from "react-router-dom";

const CategoryModal = () => {
  const [openPopGroup, setOpenPopGroup] = useState(true);
  const navigate = useNavigate()
  const { categorySucessPopup, setCategorySucessPopup , getCategory , categoryUpdatePopup,
    setCategoryUpdatePopup } = useContext(DataContext);
  const handleFunction = () => {
    setOpenPopGroup(false);
    setCategorySucessPopup(false);
    setCategoryUpdatePopup(false);
    navigate("/categories");
    getCategory('Email')
  }
  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden w-[45%] lg:w-4/5 rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-2 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="m-auto w-56 flex h-28 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                        <img className="w-[120px]" src={sucess} ></img>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-10">
                          <p className="text-sm text-gray-500">
                         { categorySucessPopup === true  ? <>Category Created Succesfully.</> : <>Category Updated Succesfully.</>}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-theme-color px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-theme-color sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => handleFunction()}
                    >
                      Continue
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default CategoryModal