import React from "react";
import { useState } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { RiPencilFill } from 'react-icons/ri';
import bin from '../../../assets/images/bin.svg';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import htmlReactParser from 'html-react-parser';
import DeleteTemplateModal from "./TemplateModal/DeleteModal";
import noTemplateData from '../../../assets/images/notemplate.svg';
import { useEffect } from "react";
import AuroLogo from "../../Logo/AuroLogo";
const EmailTemplateList = () => {
    const { id } = useParams()
    const location = useLocation();
    const { state } = location;
    const { getEmailTemplate, getStateEmailTemplate, setDeleteTemplateModal, setDeleteIvrTempId, deleteTemplateModal } = useContext(DataContext);
    console.log(getStateEmailTemplate)

    const navigate = useNavigate();
    const type = 'Email'



    const handleDeleteIvr = (id, type) => {
        setDeleteTemplateModal(true)
        setDeleteIvrTempId({ templateId: id, category: type });
    };

    const handlenavigate = (categoryId, item) => {
        navigate(`/create-template/${categoryId}`, {
            state: {
                item //Email
            }
        })
    };
    const handleUpdateTemplate = (categoryId, item, categoriesType) => {
        navigate(`/update-template/${categoryId}`, {
            state: {
                item,
                categoriesType //Email
            }
        })
    }
    useEffect(() => {
        //console.log
        getEmailTemplate(id)
    }, [])
    return (
        <>
            <div className="w-full flex bg-white h-screen">
                <div className=" bg-theme-color h-screen">
                    
                </div>
                <div className=" w-full mb-12 h-screen overflow-y-scroll">
                    {deleteTemplateModal === true ? <DeleteTemplateModal></DeleteTemplateModal> : <></>}
                    <div className="flex w-full 4xl:pr-6 pr-4">
                    <div className="pl-4 flex justify-between mt-4 lg:items-center w-full items-center mb-4">
                    <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium">Template List</h1>
                        <button type='button' className="rounded-xl text-[14px] 3xl:text-[17px] 4xl:text-[24px] mr-4 font-medium p-[.55rem_1.2rem] bg-white text-theme-color border border-theme-color rounded-lg... " onClick={() => { handlenavigate(state.item._id, state.item) }}>
                            Create Templates
                        </button>
                    </div>
                        <AuroLogo/>
                        </div>
                    <div className={`pl-4 mt-8 ${getStateEmailTemplate.length === 0 ? "4xl:pr-6 pr-4 min-h-[80vh] flex" : " 4xl:pr-6 pr-4"}`}>
                        {
                            getStateEmailTemplate.length === 0 ? <img src={noTemplateData} className="h-[50vh] m-auto"></img> :
                                getStateEmailTemplate.map((item) => (

                                    <div className="mb-[32px]">
                                        <div className="flex justify-between items-center">
                                            <div className="w-4/5">
                                            <h1 className="text-[20px] 3xl:text-[24px] 4xl:text-[24px] font-medium text-theme-color">{item.templateName}<span className="3xl:text-[10px] 4xl:text-[12px] text-[8px] font-normal text-[#16161699] ml-2">{`(Created on ${(item.createdAt).slice(0, 10)})`}</span></h1>
                                                <p className="4xl:text-[18px] 3xl:text-[15px] text-[14px] opacity-60 leading-9 text-[#161616] font-normal 3xl:leading-[27px] break-words">{item.subject}</p>
                                            </div>
                                            <div className="">
                                                {/* <p className={` ${`Pending` === 'APPROVED' ? 'text-[#3C7D40]' : 'text-[#944FEB]'} ${`Pending` === 'APPROVED' ? 'bg-[#E9F4EB]' : 'bg-[#F1EAFB]'} font-normal 3xl:text-[18px] 4xl:text-[24px] bg-[#E9F4EB] rounded-lg flex justify-center items-center p-[6px_12px] 4xl:p-[8px_16px]`}>Pending</p> */}
                                                <div className="flex justify-end mt-2">
                                                    <span className="cursor-pointer text-[18px] 4xl:text-[27px] text-theme-color" onClick={() => handleUpdateTemplate(state.item._id, item, state.item.categoriesType)}><RiPencilFill className="text-[18px] 4xl:text-[27px]" /></span> <span><img src={bin} className="cursor-pointer w-[18px] 4xl:w-[27px] ml-[37px]" onClick={() => { handleDeleteIvr(item._id, type) }} ></img></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailTemplateList;