import React from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import CategoryHeader from "./CategoryHeader";
import noData from "../../../assets/images/nodata.svg";
import { RiPencilFill } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { FiMessageSquare } from "react-icons/fi";
import { VscCallOutgoing } from "react-icons/vsc";
import { useState } from "react";
import { DataContext } from "../../../context/data/DataState";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import noTemplateData from "../../../assets/images/notemplate.svg";
import defaultCategory from "../../../assets/images/defaultCategory.png";
import { MdDelete } from "react-icons/md";
import { useMediaQuery } from 'react-responsive'
const CategoryList = () => {
  const {
    categorytypeState,
    setCategoryTypeState,
    getCategory,
    getCategoryState,
    setTemplates,
    templates, isVisibleSb, setisVisibleSb
  } = useContext(DataContext);

  const navigate = useNavigate();

  const handleNavigate = (value, item) => {
    setTemplates({ ...templates, templateName: "" });
    setTemplates({ ...templates, subject: "" });
    setTemplates({ ...templates, groupname: "" });
    setTemplates({ ...templates, subgroupdata: "" });
    const id = item._id;
    if (value === "Email") {
      navigate(`/create-template/${id}`, {
        state: {
          item,
        },
      });
    } else if (value === "Whatsapp") {
      navigate(`/create-template/${id}`, {
        state: {
          item,
        },
      });
    } else if (value === "Message") {
      setTemplates({ ...templates, templateName: "" });
      setTemplates({ ...templates, subject: "" });
      navigate(`/message-create-template/${id}`, {
        state: {
          item,
        },
      });
    } else if (value === "IVR") {
      navigate(`/ivr-create-list/${id}`, {
        state: {
          item,
        },
      });
    }
  };

  const handleViewNavigate = (value, item) => {
    const id = item._id;
    if (value === "Email") {
      navigate(`/email-template-list/${id}`, {
        state: {
          item,
        },
      });
    } else if (value === "Whatsapp") {
      navigate(`/whatsapp-template-list/${id}`, {
        state: {
          item,
        },
      });
    } else if (value === "Message") {
      navigate(`/message-template-list/${id}`, {
        state: {
          item,
        },
      });
    } else if (value === "IVR") {
      navigate(`/ivr-template-list/${id}`, {
        state: {
          item,
        },
      });
    }
  };

  const handleEdit = (item) => {
    navigate("/update-categories", {
      state: {
        item,
      },
    });
  };
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });

  useEffect(() => {
    setCategoryTypeState(categorytypeState);
    getCategory(categorytypeState);
  }, []);
  return (
    <>
      <div className={`w-full flex bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
        <div className="bg-theme-color h-screen"></div>
        <div className="w-full mb-12 4xl:pr-[2.5rem] 3xl:pr-0 h-[100vh] overflow-y-scroll">
          <CategoryHeader />
          <div className="flex 3xl:mt-2 gap-8 4xl:gap-16 xl:gap-0 4xl:mt-4 xl:mr-8 2xl:gap-4 mr-8 3xl:mr-12 lg:mr-4 4xl:mr-12">
            <div className="3xl:w-[195px] 4xl:w-[260px] 2xl:w-[180px] xl:w-[169px]">
              <div className="whitespace-nowrap bg-[#003162] p-2 text-white 3xl:p-2 4xl:p-[10px] 2xl:text-[16px] text-[14px] flex justify-between border-b-2 3xl:text-[16px] 4xl:text-[22px] cursor-pointer">
                View by Types
              </div>
              <div
                className={` ${
                  categorytypeState === "Email"
                    ? "bg-[#4D86BF]"
                    : "bg-[#F4F4F4]"
                } text-theme-color 2xl:text-[14px] 3xl:p-2 4xl:p-[10px] 2xl:p-1 flex lg:p-1 xl:p-1 justify-between border-b-2 cursor-pointer hover:bg-[#4D86BF]`}
                onClick={() => {
                  setCategoryTypeState("Email");
                  getCategory("Email");
                }}
              >
                <div className="flex items-center lg:text-[12px] text-[14] 3xl:text-[15px] 4xl:text-[20px]">
                  <span
                    className={`${
                      categorytypeState === "Email"
                        ? "text-white"
                        : "text-theme-color"
                    }`}
                  >
                    <AiOutlineMail />
                  </span>
                  &nbsp;{" "}
                  <span
                    className={`${
                      categorytypeState === "Email"
                        ? "text-white"
                        : "text-black"
                    }`}
                    value="email"
                  >
                    {" "}
                    Email{" "}
                  </span>
                </div>
              </div>
              <div
                className={` ${
                  categorytypeState === "Message"
                    ? "bg-[#4D86BF]"
                    : "bg-[#F4F4F4]"
                } text-theme-color 2xl:text-[14px] 2xl:p-1 3xl:p-2 xl:p-1 lg:p-1 4xl:p-[10px] flex justify-between border-b-2 cursor-pointer hover:bg-[#4D86BF]`}
                onClick={() => {
                  setCategoryTypeState("Message");
                  getCategory("Message");
                }}
              >
                <div className="flex items-center lg:text-[12px] text-[14] 3xl:text-[15px] 4xl:text-[20px]">
                  <span
                    className={`${
                      categorytypeState === "Message"
                        ? "text-white"
                        : "text-theme-color"
                    }`}
                  >
                    <FiMessageSquare />
                  </span>
                  &nbsp;
                  <span
                    className={`${
                      categorytypeState === "Message"
                        ? "text-white"
                        : "text-black"
                    }`}
                    value="Message"
                  >
                    {" "}
                    Message{" "}
                  </span>
                </div>
              </div>
              <div
                className={` ${
                  categorytypeState === "Whatsapp"
                    ? "bg-[#4D86BF]"
                    : "bg-[#F4F4F4]"
                } text-theme-color 2xl:p-1 2xl:text-[14px] 3xl:p-2 xl:p-1 lg:p-1 4xl:p-[10px] flex justify-between border-b-2 cursor-pointer hover:bg-[#4D86BF]`}
                onClick={() => {
                  setCategoryTypeState("Whatsapp");
                  getCategory("Whatsapp");
                }}
              >
                <div className="flex items-center lg:text-[12px] text-[14] 3xl:text-[15px] 4xl:text-[20px]">
                  <span
                    className={`${
                      categorytypeState === "Whatsapp"
                        ? "text-white"
                        : "text-theme-color"
                    }`}
                  >
                    <BsWhatsapp />
                  </span>
                  &nbsp;
                  <span
                    className={`${
                      categorytypeState === "Whatsapp"
                        ? "text-white"
                        : "text-black"
                    }`}
                    value="Whatsapp"
                  >
                    {" "}
                    Whatsapp{" "}
                  </span>
                </div>
              </div>
              <div
                className={` ${
                  categorytypeState === "IVR" ? "bg-[#4D86BF]" : "bg-[#F4F4F4]"
                }   3xl:p-2 4xl:p-[10px] 2xl:text-[14px] 2xl:p-1 xl:p-1 lg:p-1 flex justify-between border-b-2 cursor-pointer hover:bg-[#4D86BF]`}
                onClick={() => {
                  setCategoryTypeState("IVR");
                  getCategory("IVR");
                }}
              >
                <div className="flex items-center lg:text-[12px] text-[14] 3xl:text-[15px] 4xl:text-[20px]">
                  <span
                    className={`${
                      categorytypeState === "IVR"
                        ? "text-white"
                        : "text-theme-color"
                    }`}
                  >
                    <VscCallOutgoing />
                  </span>
                  &nbsp;{" "}
                  <span
                    className={`${
                      categorytypeState === "IVR" ? "text-white" : "text-black"
                    }`}
                    value="IVR"
                  >
                    {" "}
                    IVR{" "}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={` h-[75vh] overflow-y-scroll ${
                getCategoryState.length === 0
                  ? "w-[80%] flex"
                  : "grid grid-flow-dense gap-4 4xl:grid-cols-4x 3xl:grid-cols-3 lg:grid-cols-2 grid-cols-3x w-full place-items-start lg:place-items-center justify-start 4xl:gap-x-8 4xl:pr-12 3xl:gap-4 gap-y-12 ml-auto"
              }`}
            >
              {getCategoryState?.length === 0 ? (
                <img
                  src={noTemplateData}
                  className="h-[50vh] w-[448px] m-auto"
                ></img>
              ) : (
                getCategoryState?.map((item) => (
                  <>
                    <div class="3xl:w-[262px] rounded-2xl 3xl:h-[235px] xl:w-[280px] 2xl:w-[200px] table-wrp 4xl:w-[350px] 4xl:h-[305px] 3xl:rounded-xl 4xl:rounded-2xl border overflow-hidden shadow-[0px_5px_18px_-5px_rgba(16,_24,_40,_0.08)]">
                      {item.image === "" ? (
                        <img
                          className="w-full xl:h-[190px] lg:h-[150px] 2xl:h-[128px] 3xl:h-[142px] 4xl:h-[189px] h-[190px] rounded-2xl object-cover  m-auto"
                          src={defaultCategory}
                          alt=""
                        />
                      ) : (
                        <img
                          className="w-full xl:h-[190px] lg:h-[150px] 2xl:h-[128px] 3xl:h-[142px] 4xl:h-[189px] h-[190px] rounded-2xl object-cover  m-auto"
                          src={item.image}
                          alt=""
                        />
                      )}

                      <div class="px-2 pb-2 2xl:px-1">
                        <div class="flex py-3 items-center justify-center">
                          <p className="font-normal xl:text-[17px]  lg:text-[17px] 3xl:text-[18px] 4xl:text-[22px]">{`${item.categoryName.slice(
                            0,
                            18
                          )}`}</p>
                          <span
                            className=" text-[#16161699] cursor-pointer ml-1 inline-block text-[13px] 4xl:text-[18px]"
                            onClick={() => {
                              handleEdit(item);
                            }}
                          >
                            <RiPencilFill />
                          </span>
                          <span className=" text-[#16161699] cursor-pointer ml-1 inline-block text-[13px] 4xl:text-[18px] text-red-500">
                            <MdDelete />
                          </span>
                        </div>
                        <div class="flex justify-center gap-2">
                          <button
                            type="button"
                            className="3xl:text-[11px] xl:text-[10px] lg:p-[4px_6px] lg:text-[8px] 4xl:text-[14px] 2xl:text-[10px] 2xl:p-[4px_5px] p-[6px_14px] 4xl:p-[8px_24px] font-normal bg-theme-color text-white rounded-lg ... "
                            onClick={() => {
                              handleViewNavigate(item.categoriesType, item);
                            }}
                          >
                            View Template
                          </button>
                          <button
                            type="button"
                            className="3xl:text-[11px]  xl:text-[10px] lg:text-[8px] lg:p-[4px_6px] 4xl:text-[14px] 2xl:text-[10px] 2xl:p-[4px_5px] p-[6px_14px] 4xl:p-[8px_24px]  font-normal bg-white text-theme-color border-1 border-theme-color rounded-lg ... "
                            onClick={() => {
                              handleNavigate(item.categoriesType, item);
                            }}
                          >
                            Create Template
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryList;
