import { useContext } from "react";
import { useEffect } from "react";
import { React, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/images/backBlue.svg";
import { DataContext } from "../../context/data/DataState";
import AuroLogo from "../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";
import { useMediaQuery } from 'react-responsive'

const EditAccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const State = location.state;
  const {
    groupList,
    groupListState,
    getEmailCategory,
    emailCategoryList,
    getSmsCategory,
    smsCategoryList,
    getWhatsAppCategory,
    whatsappCategoryList,
    ivrCategoryList,
    getIvrCategory,
    getManageTempAdmin,
    getemailManageTemp,
    givePermission,
    isVisibleSb,
    setIsVisibleSb,
  } = useContext(DataContext);
  const [groupManageToggle, setGroupManageToggle] = useState(false);
  const [emailManageToggle, setEmailManageToggle] = useState(false);
  const [emailManageTempToggle, setEmailManageTempToggle] = useState(false);

  const categoriesMenu = (e) => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };

  const [editMemberPremission, setEditMemberPremission] = useState({
    permission: {
      emailCategory: {
        templates: {
          create: false,
          manage: false,
          subTemplates: [],
        },
        create: false,
        manage: false,
        subCategories: [],
      },
      whatsappCategory: {
        templates: {
          create: false,
          manage: false,
          subTemplates: [],
        },
        create: false,
        manage: false,
        subCategories: [],
      },
      ivrCategory: {
        templates: {
          create: false,
          manage: false,
          subTemplates: [],
        },
        create: false,
        manage: false,
        subCategories: [],
      },
      messageCategory: {
        templates: {
          create: false,
          manage: false,
          subTemplates: [],
        },
        create: false,
        manage: false,
        subCategories: [],
      },
      createGroup: {
        create: false,
        manage: false,
        subGroup: [],
      },
      sendEmailCampaign: {
        send: false,
        verifyid: [],
      },
      sendMessageCampaign: {
        send: false,
        verifyid: [],
      },
      sendWhatsappCampaign: {
        send: false,
        verifyid: [],
      },
      sendIvrCampaign: false,
    },
  });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });

  useEffect(() => {
    groupList();
    getEmailCategory();
    // getSmsCategory()
    // getWhatsAppCategory()
    // getIvrCategory()
  }, []);

  return (
    <div
      className={`w-full bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
      <div className="hidden justify-between items-center gap-4 w-full sm:flex xs:flex md:flex px-4 pt-4 4xl:pl-8 3xl:pl-6 pl-4">
        <FiMenu
          className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`}
          onClick={() => {
            setIsVisibleSb(!isVisibleSb);
          }}
        />
        <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium pt-4 pb-6 4xl:pl-8 3xl:pl-6">
          {State?.firstName} {State?.lastName}
        </h1>
        <AuroLogo />
      </div>

      <div className="flex justify-between w-full pr-4 xs:hidden sm:hidden md:hidden pt-4 4xl:px-8 3xl:px-6 px-4">
        <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">
          {State?.firstName} {State?.lastName}
        </h1>
        <AuroLogo />
      </div>

        <div className="flex justify-between items-center w-full 4xl:px-8 3xl:px-6 px-4 gap-8 py-4">
          <div className="flex gap-4 items-center">
            <img
              src={back}
              alt=""
              className="back w-[18px] cursor-pointer"
              onClick={() => {
                navigate("/members-list");
              }}
            />{" "}
            <p className="text-gray-400 text-[18px] 4xl:text-[20px] font-medium">
              Back to team members
            </p>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="p-[.6rem_1.5rem] bg-theme-color w-auto text-white rounded-md mb-2 "
              onClick={() => {
                givePermission(
                  location.state._id,
                  editMemberPremission.permission
                );
              }}
            >
              Save
            </button>
          </div>
        </div>

      <div className="flex ml-4 flex-col gap-4 h-[75vh] overflow-y-scroll no-scrollbar 4xl:mr-8 3xl:mr-6 mr-4">
        {/* create Group categories  */}
        <div className="bg-white shadow-card-shadow  m-2 rounded-xl">
          <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
            <div className="w-1/4 justify-between 4xl:pr-[6px] flex items-center">
              <span className="text-black 4xl:text-[20px] text-[14px] inline-block font-semibold">
                Create Group
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input
                      type="checkbox"
                      className="sr-only"
                      onChange={(e) => {
                        setEditMemberPremission((prevState) => ({
                          ...prevState,
                          permission: {
                            ...prevState.permission,
                            createGroup: {
                              ...prevState?.permission?.createGroup,
                              create: e.target.checked,
                            },
                          },
                        }));
                      }}
                    />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
            <div className="flex justify-between items-baseline">
              <span className="text-black 4xl:text-[20px] text-[14px]">
                Manage Group
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input
                      type="checkbox"
                      className="sr-only"
                      onChange={(e) => {
                        setGroupManageToggle(e.target.checked);
                        setEditMemberPremission((prevState) => ({
                          ...prevState,
                          permission: {
                            ...prevState.permission,
                            createGroup: {
                              ...prevState?.permission?.createGroup,
                              manage: e.target.checked,
                            },
                          },
                        }));
                      }}
                    />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
            {groupManageToggle ? (
              <div className="grid grid-cols-3 gap-4 pl-12">
                {groupListState.map((item) => (
                  <div>
                    <label htmlFor="" className="flex gap-2">
                      <input
                        type="checkbox"
                        className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                        onChange={(e) => {
                          setEditMemberPremission((prevState) => {
                            if (e.target.checked) {
                              return {
                                ...prevState,
                                permission: {
                                  ...prevState.permission,
                                  createGroup: {
                                    ...prevState.permission.createGroup,
                                    subGroup: [
                                      ...prevState.permission.createGroup
                                        .subGroup,
                                      item._id,
                                    ],
                                  },
                                },
                              };
                            } else {
                              return {
                                ...prevState,
                                permission: {
                                  ...prevState.permission,
                                  createGroup: {
                                    ...prevState.permission.createGroup,
                                    subGroup:
                                      prevState.permission.createGroup.subGroup.filter(
                                        (subGroupName) =>
                                          subGroupName !== item._id
                                      ),
                                  },
                                },
                              };
                            }
                          });
                        }}
                      />

                      <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                        {item.groupName}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div></div>
        </div>

        {/* create email categories  */}
        <div className="bg-white shadow-card-shadow  m-2 rounded-xl">
          <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
            <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
              <span className="text-black 4xl:text-[20px] text-[14px] inline-block font-semibold">
                Create email categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input
                      type="checkbox"
                      className="sr-only"
                      onChange={(e) => {
                        setEditMemberPremission((prevState) => ({
                          ...prevState,
                          permission: {
                            ...prevState.permission,
                            emailCategory: {
                              ...prevState?.permission?.emailCategory,
                              create: e.target.checked,
                            },
                          },
                        }));
                      }}
                    />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
            <div className="flex justify-between items-baseline">
              <span className="text-black 4xl:text-[20px] text-[14px]">
                Manage email categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input
                      type="checkbox"
                      className="sr-only"
                      onClick={(e) => {
                        setEmailManageToggle(e.target.checked);
                        setEditMemberPremission((prevState) => ({
                          ...prevState,
                          permission: {
                            ...prevState.permission,
                            emailCategory: {
                              ...prevState?.permission?.emailCategory,
                              manage: e.target.checked,
                            },
                          },
                        }));
                      }}
                    />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
            {emailManageToggle ? (
              <div className="grid grid-cols-3 gap-4 pl-12">
                {emailCategoryList.map((item) => (
                  <div>
                    <label htmlFor="" className="flex gap-2">
                      <input
                        type="checkbox"
                        className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                        onChange={(e) => {
                          setEditMemberPremission((prevState) => {
                            if (e.target.checked) {
                              return {
                                ...prevState,
                                permission: {
                                  ...prevState.permission,
                                  emailCategory: {
                                    ...prevState.permission.emailCategory,
                                    subCategories: [
                                      ...prevState.permission.emailCategory
                                        .subCategories,
                                      item._id,
                                    ],
                                  },
                                },
                              };
                            } else {
                              return {
                                ...prevState,
                                permission: {
                                  ...prevState.permission,
                                  emailCategory: {
                                    ...prevState.permission.emailCategory,
                                    subCategories:
                                      prevState.permission.emailCategory.subCategories.filter(
                                        (subGroupName) =>
                                          subGroupName !== item._id
                                      ),
                                  },
                                },
                              };
                            }
                          });
                        }}
                      />
                      <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                        {item.categoryName}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
              <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
                <span className="text-black 4xl:text-[20px] text-[14px] font-semibold inline-block">
                  Create email templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input
                        type="checkbox"
                        className="sr-only"
                        onClick={(e) => {
                          setEditMemberPremission((prevState) => ({
                            ...prevState,
                            permission: {
                              ...prevState.permission,
                              emailCategory: {
                                ...prevState.permission.emailCategory,
                                templates: {
                                  ...prevState.permission.emailCategory
                                    .templates,
                                  create: e.target.checked,
                                },
                              },
                            },
                          }));
                        }}
                      />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
            </div>
            <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
              <div className="flex justify-between items-baseline">
                <span className="text-black 4xl:text-[20px] text-[14px]">
                  Manage email templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input
                        type="checkbox"
                        className="sr-only"
                        onChange={(e) => {
                          setEmailManageTempToggle(e.target.checked);
                          setEditMemberPremission((prevState) => ({
                            ...prevState,
                            permission: {
                              ...prevState.permission,
                              emailCategory: {
                                ...prevState.permission.emailCategory,
                                templates: {
                                  ...prevState.permission.emailCategory
                                    .templates,
                                  manage: e.target.checked,
                                },
                              },
                            },
                          }));
                          getManageTempAdmin(
                            editMemberPremission.permission.emailCategory
                              .subCategories
                          );
                          {
                            e.target.checked ? (
                              console.log(
                                editMemberPremission.permission.emailCategory
                                  .subCategories,
                                "editMemberPremission.permission.emailCategory.subCategories"
                              )
                            ) : (
                              <></>
                            );
                          }
                        }}
                      />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
              <div className="grid grid-cols-3 gap-4 pl-12">
                {emailManageTempToggle ? (
                  <>
                    {getemailManageTemp.map((item) => (
                      <div>
                        <label htmlFor="" className="flex gap-2">
                          <input
                            type="checkbox"
                            className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                            onChange={(e) => {
                              setEditMemberPremission((prevState) => {
                                if (e.target.checked) {
                                  return {
                                    ...prevState,
                                    permission: {
                                      ...prevState.permission,
                                      emailCategory: {
                                        ...prevState.permission.emailCategory,
                                        templates: {
                                          ...prevState.permission.emailCategory
                                            .templates,
                                          subTemplates: [
                                            ...prevState.permission
                                              .emailCategory.templates
                                              .subTemplates,
                                            item._id,
                                          ],
                                        },
                                      },
                                    },
                                  };
                                } else {
                                  return {
                                    ...prevState,
                                    permission: {
                                      ...prevState.permission,
                                      emailCategory: {
                                        ...prevState.permission.emailCategory,
                                        templates: {
                                          ...prevState.permission.emailCategory
                                            .templates,
                                          subTemplates:
                                            prevState.permission.emailCategory.templates.subTemplates.filter(
                                              (subTemplateName) =>
                                                subTemplateName !== item._id
                                            ),
                                        },
                                      },
                                    },
                                  };
                                }
                              });
                            }}
                          />
                          <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                            {item.templateName}
                          </span>
                        </label>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* create SMS categories  */}
        <div className="bg-white shadow-card-shadow  m-2 rounded-xl">
          <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
            <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
              <span className="text-black 4xl:text-[20px] text-[14px] inline-block font-semibold">
                Create SMS categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input type="checkbox" className="sr-only" />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
            <div className="flex justify-between items-baseline">
              <span className="text-black 4xl:text-[20px] text-[14px]">
                Manage SMS categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input type="checkbox" className="sr-only" />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
            <div className="grid grid-cols-3 gap-4 pl-12">
              {smsCategoryList.map((item) => (
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      {item.categoryName}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div>
            {/* create SMS templates  */}
            <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
              <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
                <span className="text-black 4xl:text-[20px] text-[14px] font-semibold inline-block">
                  Create SMS templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input type="checkbox" className="sr-only" />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
            </div>
            <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
              <div className="flex justify-between items-baseline">
                <span className="text-black 4xl:text-[20px] text-[14px]">
                  Manage SMS templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input type="checkbox" className="sr-only" />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
              <div className="grid grid-cols-3 gap-4 pl-12">
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 1
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 2
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 3
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 4
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 5
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 6
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 7
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 8
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 9
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* create Whatsapp categories  */}
        <div className="bg-white shadow-card-shadow  m-2 rounded-xl">
          <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
            <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
              <span className="text-black 4xl:text-[20px] text-[14px] inline-block font-semibold">
                Create Whatsapp categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input type="checkbox" className="sr-only" />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
            <div className="flex justify-between items-baseline">
              <span className="text-black 4xl:text-[20px] text-[14px]">
                Manage Whatsapp categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input type="checkbox" className="sr-only" />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
            <div className="grid grid-cols-3 gap-4 pl-12">
              {whatsappCategoryList.map((item) => (
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      {item.categoryName}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div>
            {/* create Whatsapp templates  */}
            <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
              <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
                <span className="text-black 4xl:text-[20px] text-[14px] font-semibold inline-block">
                  Create Whatsapp templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input type="checkbox" className="sr-only" />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
            </div>
            <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
              <div className="flex justify-between items-baseline">
                <span className="text-black 4xl:text-[20px] text-[14px]">
                  Manage Whatsapp templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input type="checkbox" className="sr-only" />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
              <div className="grid grid-cols-3 gap-4 pl-12">
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 1
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 2
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 3
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 4
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 5
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 6
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 7
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 8
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 9
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* create IVR categories  */}
        <div className="bg-white shadow-card-shadow  m-2 rounded-xl">
          <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
            <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
              <span className="text-black 4xl:text-[20px] text-[14px] inline-block font-semibold">
                Create IVR categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input type="checkbox" className="sr-only" />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
            <div className="flex justify-between items-baseline">
              <span className="text-black 4xl:text-[20px] text-[14px]">
                Manage IVR categories
              </span>
              <span className="inline-block">
                <label className="flex items-center cursor-pointer">
                  <div className="relative" title="Active">
                    <input type="checkbox" className="sr-only" />
                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                  </div>
                </label>
              </span>
            </div>
            <div className="grid grid-cols-3 gap-4 pl-12">
              {ivrCategoryList.map((item) => (
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      {item.categoryName}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div>
            {/* create IVR templates  */}

            <div className="flex  items-center pl-4 bg-gray-100 py-4 w-full">
              <div className="w-1/4 flex justify-between 4xl:pr-[6px] items-center">
                <span className="text-black 4xl:text-[20px] text-[14px] font-semibold inline-block">
                  Create IVR templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input type="checkbox" className="sr-only" />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
            </div>
            <div className="grid grid-cols-[1fr_3fr] gap-6 py-4 px-4">
              <div className="flex justify-between items-baseline">
                <span className="text-black 4xl:text-[20px] text-[14px]">
                  Manage IVR templates
                </span>
                <span className="inline-block">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative" title="Active">
                      <input type="checkbox" className="sr-only" />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                  </label>
                </span>
              </div>
              <div className="grid grid-cols-3 gap-4 pl-12">
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 1
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 2
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 3
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 4
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 5
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 6
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 7
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 8
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="flex gap-2">
                    <input
                      type="checkbox"
                      className="4xl:w-[24px] 4xl:h-[24px] 3xl:w-[20px] 3xl:h-[20px] w-[15px] h-[15px] border border-[#DAE0E6] rounded-md appearance-none checked:bg-theme-color 4xl:text-[24px] 3xl:text-[18px] text-[16px] checked:border-none 4xl:rounded-md"
                    />
                    <span className="4xl:text-[20px] 3xl:text-[14px] text-[14px]">
                      Categories 9
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAccess;
