import React from "react";
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import OtpInput from 'react-otp-input';
import { AiOutlineClose } from 'react-icons/ai';

const VerificationModal = () => {
  const [openPopGroup, setOpenPopGroup] = useState(true)
  const { verifyEmailPopUp, setVerifyEmailPopUp, senderverifyEmail, otpVerifyUserEmail, ResendOtpVerifyUserEmail, verifyEmailPopUpError,setVerifyEmailPopUpError, getEmailSenderId , setSenderEmailVerify } = useContext(DataContext);
  const [otpEmailVerify, setOtpEmailVerify] = useState("");

  const handleChangeOtp = (otp) => {
    setOtpEmailVerify(otp)
  };
  const handleVerifyOtp = () => {
    console.log(otpEmailVerify);
    otpVerifyUserEmail(senderverifyEmail, otpEmailVerify)
    // setOpenPopGroup(false); 
    // setVerifyEmailPopUp(false)
  };
  const handleClose = () => {
    setOpenPopGroup(false);
    setVerifyEmailPopUp(false);
    setVerifyEmailPopUpError('');
    getEmailSenderId()
    setSenderEmailVerify('')
  };
  const handleResendOtp = () => {
    setVerifyEmailPopUpError('')
    ResendOtpVerifyUserEmail(senderverifyEmail)
  }

  return (
    <>
      <Transition.Root show={openPopGroup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex ml-[200px] mt-[200px] justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden  3xl:w-[45%] 4xl:w-[45%] w-4/5 rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="flex justify-end px-4 pt-2" >
                    <span className="text-red-500  cursor-pointer text-[18px]" onClick={() => { handleClose() }}><AiOutlineClose /></span>
                  </div>
                  <div className="flex flex-col pt-8">
                  <div className="bg-white px-4 pb-2 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="text-center items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                      <h1 className="text-[36px] text-[#4c4c4c]">Verification Code</h1>
                        <p className="text-[18px] text-[#676767]">
                          Please type the verification code sent to
                        </p>
                        <p className="text-[18px] text-[#676767]">
                          {senderverifyEmail}
                        </p>
                      </div>
                      <div className="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="px-4 flex justify-center">
                          <OtpInput
                            className="w-12 bg-gray-100 rounded-2xl mr-2.5 mt-4 block p-3 text-2xl font-bold text-center bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400"
                            value={otpEmailVerify}
                            onChange={handleChangeOtp}
                            numInputs={4}
                            isInputNum
                            inputStyle={'bg-gray-100'}
                            separator={<span></span>}
                           
                          />
                        </div>
                        <span className="text-red-600 text-[10px]">{verifyEmailPopUpError}</span>
                      </div>
                    </div>
                  </div>

                  <div className="pb-[12px]">
                    <div className="bg-gray-50 px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        disabled={otpEmailVerify.length !== 4}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-theme-color px-4 py-2 text-base font-medium text-white disabled:opacity-40 shadow-sm hover:bg-theme-color sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => handleVerifyOtp()}
                      >
                        Verify & Proceed
                      </button>
                    </div>
                    <div className="text-[15px] font-normal text-center">
                      <span className="">I don’t receive a code!</span> <span className="text-theme-color border-b border-theme-color cursor-pointer" onClick={() => handleResendOtp()}>Resend Code </span>
                    </div>
                  </div>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default VerificationModal;