import React from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import CategoryHeader from "./CategoryHeader";
import backBlue from '../../../assets/images/backBlue.svg';
import categoryimg from '../../../assets/images/categoryimg.svg';
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import uploadsvgimg from '../../../assets/images/uploadsvgimg.svg';
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import { GrAttachment } from 'react-icons/gr';
import CategoryModal from "./CategoryModal";
import { useState } from "react";


const UpdateCategory = () => {

  const { updateCategory, categorySucessPopup, setSaveCategoryNav , categoryUpdatePopup , updateNavCategory} = useContext(DataContext);

  const { state } = useLocation();


  const [createCategoryState, setCreateCategoryState] = useState({
    categoryName: state.item.categoryName,
    categoryImage: state.item.image
  });
  console.log(createCategoryState ,'new state')
  const handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCreateCategoryState({ ...createCategoryState, categoryImage: event.target.files[0] });
    }
  };
  const handleCreateCategory = () => {
    updateCategory(createCategoryState , state.item._id)
  };
  const handleCreateCategoryNav = () => {
    updateNavCategory(createCategoryState ,state.item._id)
  }
  return (

    <div className="w-full flex bg-white h-screen">
      {categoryUpdatePopup === true ? <CategoryModal></CategoryModal> : <></>}
      <div className="bg-theme-color h-screen">
        
      </div>
      <div className="w-full mb-12 h-screen overflow-x-scroll">
        <CategoryHeader />
        <div className="mt-4 relative">
          <div>
          <Link to='/categories' className="inline-block absolute top-[-9%] xl:top-[-13%] xl:left-[3%] 2xl:top-[-20%] 2xl:left-[2%] 4xl:top-[-9%] lg:top-[-12%] lg:left-[3%] 3xl:left-[2%] 4xl:left-[1%] "><img src={backBlue} className='w-xl:[26px] h-[26px] w-[20px] 4xl:w-[31px] 4xl:h-[35px]  4xl:left-[2%]'></img> </Link>
          </div>
          <div className="flex ml-4 mt-14 lg:w-[90%] lg:mx-auto 4xl:mt-[3.5rem] lg:flex-col lg:gap-8">
          <div className="w-[63%] lg:order-1 lg:w-full  pb-0 4xl:pb-5 max-w-[1100px]">
          <div className="3xl:p-6 p-4 4xl:p-8 bg-[#E9EFFD] rounded-2xl">
                <div className="">
                  <h1 className="3xl:text-[27px] 4xl:text-[36px] text-[18px] font-medium 3xl:mb-3 mb-2 4xl:mb-3">Categories name</h1>
                  <input type="text" placeholder="Enter your categories name" className="mt-2 lg:mt-0 xl:mt-0 2xl:mt-0 text-[14px] 3xl:text-[20px] 4xl:text-[20px] font-normal border-theme-color w-full rounded-lg 3xl:h-[53px] 4xl:h-[70px] 3xl:pl-4 4xl:pl-4 3xl:py-[23px] 4xl:py-[23px] h-auto p-2 border-b outline-0" value={createCategoryState.categoryName} onChange={(e) => setCreateCategoryState({ ...createCategoryState, categoryName: e.target.value })}>
                  </input></div>
                  <div className="mt-6">
                  <h1 className="3xl:text-[27px] 4xl:text-[36px] text-[18px] font-medium  mb-2 3xl:mb-3 4xl:mb-3">Upload image <span className="3xl:text-[12px] text-[10px] 4xl:text-[16px] text-[#16161699]">(optional)</span></h1>
                  <div className="w-[100%] 3xl:py-3 4xl:py-3 py-0 bg-white rounded-2xl flex items-center justify-center">
                    <label htmlFor="upload_category_image" accept="image/png, image/gif, image/jpeg, image/svg"  className="mx-auto cursor-pointer flex w-full max-w-full h-full flex-col items-center rounded-2xl border-blue-400 bg-white p-4 text-center">
                      <img src={uploadsvgimg} class="3xl:w-[50px] w-[25px] 4xl:w-[50px] text-blue-500">
                      </img>
                      <h2 class="mt-4 text-[12px] font-medium text-gray-700 tracking-wide 3xl:text-[12px] 4xl:text-[16px]">Click or drag file to this area to upload</h2>

                      <p class="mt-2 text-gray-500 tracking-wide 3xl:w-[296px] w-auto text-[8px] 4xl:w-[395px] 3xl:text-[10px] 4xl:text-[14px]">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files.</p>
                    </label>
                    <input id="upload_category_image" type="file" className="hidden" onChange={(event) => { handleImage(event) }}></input>
                  </div>
                  {createCategoryState.categoryImage.name === undefined ? <div className="flex items-center text-[10px] lg:text-[10px] xl:text-[10px] 4xl:text-[14px] 2xl:text-[10px] my-2"><span className="w-[10px] mr-2"><GrAttachment /></span><span className="text-theme-color">{state.item.image.slice(70)}</span></div> : <div className="flex mt-1"><span className="w-[10px] mr-2"><GrAttachment /></span><span className="text-theme-color">{createCategoryState.categoryImage.name}</span></div>}
                  <div className="mb-10px flex gap-4 pt-3">
                    <button type='button' className="font-medium p-[.5rem_.8rem] 3xl:p-[.5rem_1.5rem] 4xl:p-[.5rem_1.5rem] text-[12px] bg-theme-color text-white 3xl:text-[18px] 4xl:text-[24px] rounded-lg ... " onClick={() => { handleCreateCategory() }}>
                      Save
                    </button>
                    <button type='button' className="p-[.5rem_.8rem] 3xl:p-[.5rem_1.5rem] 4xl:p-[.5rem_1.5rem] text-[12px] font-medium border border-theme-color bg-transparent text-theme-color 3xl:text-[18px] 4xl:text-[24px] rounded-lg ... " onClick={() => { handleCreateCategoryNav() }}>
                      Update & Create Template
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-8 w-[35%] lg:w-full flex justify-center items-center"> <img src={categoryimg} className="lg:w-[250px]"></img></div>
          </div>

        </div>
      </div>
    </div>
  )
}
export default UpdateCategory;