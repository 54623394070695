import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { DataContext } from "../../../context/data/DataState";
import SideNavBar from "../../SideNavBar/SideNavBar";
import VerificationDltModal from "./VerificationDltModal";
import VerificationModal from "./VerificationModal";
import noTemplateData from '../../../assets/images/blankdtrip.png';
import AuroLogo from "../../Logo/AuroLogo";
import { FiMenu } from "react-icons/fi";
import { useMediaQuery } from 'react-responsive'


const VerifiedId = () => {
  const { senderverifyEmail,
    setSenderEmailVerify, verifyEmailPopUp, setVerifyEmailPopUp, verifySenderEmail, getEmailSenderId, getVerifyEmail, senderSmsVerify, setSenderSmsVerify, verifyDltSms, getSmsDltId, getVerifySms, senderWhatsappVerify, setSenderWhatsappVerify, verifyDltWhatsApp, getWhatsAppDltId, getVerifyWhatsApp, setGetVerifyWhatsApp, dltSuccessPopup, dltSmsPopup, setDltSmsPopup, dltEmailPopUp, setDltEmailPopup, isVisibleSb, setIsVisibleSb } = useContext(DataContext);
  const [emailError, setEmailError] = useState(false);

  const handleValidationMail = (maildata) => {
    var emailreg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (maildata.match(emailreg)) {
      return setEmailError(false)
    }
    else if (maildata == '') {
      return setEmailError(false)
    }
    else {
      return setEmailError(true)
    }
  };
  const [smsError, setSmsError] = useState(false)
  const handleValidationSms = (smsdata) => {
    var smsreg = (/^[A-Za-z]+$/)
    if (smsdata.match(smsreg)) {
      return setSmsError(false)
    }
    else if (smsdata == '') {
      return setSmsError(false)
    }
    else {
      return setSmsError(true)
    }
  };

  const [numberError, setNumberError] = useState(false)
  const handleValidationNumber = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setNumberError(false)
    }
    else if (phoneData == '') {
      return setNumberError(false)
    }
    else {
      return setNumberError(true)

    }
  };

  const handleEmailVerify = () => {
    setVerifyEmailPopUp(true)
    verifySenderEmail(senderverifyEmail)
  };
  const handleSmsVerify = () => {
    verifyDltSms(senderSmsVerify)
  };
  const handleWhatsApp = () => {
    verifyDltWhatsApp(senderWhatsappVerify)
  };
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });



  const rows = [];
  const tbodyFunction = (length) => {
    for (let i = 0; i < 5 - length; i++) {
      rows.push(
        <tr className={`leading-[12px] border-b ${i % 2 == 0 ? "bg-[#ffff]" : "bg-[#e9effd]"}`} key={i}>
          <td className="px-6  p-[16px] whitespace-nowrap 3xl:text-[14px]">
          </td>
          <td className={`px-6  p-[16px] whitespace-nowrap flex justify-center1`}>
            {/* <p className={` rounded-lg text-[14px] 4xl:text-[18px] flex px-[15px] 3xl:py-[8px] 4xl:py-[11px]`}></p> */}
          </td>
        </tr>
      );
    }

    return rows;
  }

  const buttonDisabled = (senderverifyEmail !== '');
  const smsButtonDisabled = (senderSmsVerify !== '');
  const whatsappButtonDisabled = (senderWhatsappVerify !== '')

  useEffect(() => {
    getEmailSenderId()
    getSmsDltId()
    getWhatsAppDltId()
  }, []);

  return (
    <>

      {
        dltSmsPopup === true ? <VerificationDltModal></VerificationDltModal> : <></>
      }
      {
        dltEmailPopUp === true ? <VerificationDltModal></VerificationDltModal> : <></>
      }
      {
        dltSuccessPopup === true ? <VerificationDltModal></VerificationDltModal> : <></>
      }
      {
        verifyEmailPopUp === true ? <VerificationModal ></VerificationModal> : <></>
      }
      {/* <div className="bg-theme-color h-screen">
          
        </div> */}

<div className={`w-full flex bg-white h-screen ${!isVisibleSb && isSmallScreen ? "opacity-50 pointer-events-none" : ""}`}>
        <div className="pl-6 pr-6">
          <div className="hidden flex-col gap-4  xs:flex sm:flex md:flex pt-4">
            <div className="flex justify-between items-center">
            <FiMenu className={`text-[30px] w-[47px] h-[40px] bg-theme-color text-white rounded-md p-2`} onClick={()=>{setIsVisibleSb(!isVisibleSb)}}/>
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Verified Id's</h1>
            <AuroLogo />
            </div>
          <div className="flex justify-between">
          </div>
          </div>

          <div className="flex flex-col gap-4 md:hidden sm:hidden xs:hidden">
            <div className="flex justify-between items-center">
            <h1 className="4xl:text-[28px] 3xl:text-[24px] text-[20px] font-medium ">Verified Id's</h1>
            <AuroLogo />
            </div>
    
          </div>
          
          <div className="w-full-screen grid lg:pb-6 xl:pb-6 lg:mx-auto xl:mx-auto xl:w-4.5/5 lg:grid-cols-1  md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 xl:grid-cols-1 lg:pr-4 xl:pr-8 grid-cols-2 pt-4 gap-[3rem] 3xl:gap-16 4xl:gap-24">


            {/* Verified  senders id’s for Email */}

            <div>
              <h1 className="text-[20px] sm:text-[18px]  xs:text-[16px] 3xl:text-[24px] 4xl:text-[30px] font-normal text-theme-color mb-[8px]">Verified  senders id's for Email
              </h1>
              <div className="lg:w-full w-full overflow-scroll h-[315px] sm:h-[210px] xs:h-[200px] no-scrollbar">
                <table className="divide-y divide-gray-200 dark:divide-gray-700 table-fixed w-full">
                  <thead className="sticky top-0">
                    <tr className="bg-theme-color rounded-md" >
                      <th scope="col" className="px-6 py-3 text-[14px] sm:text-[13px] xs:text-[11px] 4xl:text-[18px] text-left text-white uppercase font-normal">Sender's Id</th>
                      <th scope="col" className="px-6 py-3 text-[14px] sm:text-[13px] xs:text-[11px] 4xl:text-[18px] text-left text-white uppercase font-normal flex justify-center">Status</th>
                    </tr>
                  </thead>
                  <tbody className="border-l border-r border-b">
                    {
                      getVerifyEmail?.length === 0 ?
                        <tr>
                          <td colSpan={2} className="p-0">
                            <img src={noTemplateData}></img>
                          </td></tr>
                        :
                        getVerifyEmail?.map((item, index) => (
                          <tr className={`leading-[12px] ${getVerifyEmail.length % 2 === 0 ? index % 2 === 0 ? "bg-[#ffff]" : "bg-[#e9effd]" : index % 2 !== 0 ? "bg-[#ffff]" : "bg-[#e9effd]"}`}>
                            <td className="px-6 py-[6px]  text-[14px] 4xl:text-[18px] whitespace-nowrap border-b">
                              {item.email}
                            </td>
                            <td className={`px-6 py-[6px] 4xl:py-3 whitespace-nowrap border-b flex justify-center  ${item.verifyid === 'APPROVED' ? 'text-[#3C7D40]' : 'text-[#d6792d]'} text-[14px] 4xl:text-[18px]`}>
                              <p className={` ${item.verifyid === 'APPROVED' ? 'bg-[#E9F4EB]' : 'bg-[#fbf0e4]'} rounded-md text-[14px] flex px-[15px] p-[4px_9px] 4xl:p-[6px_12px] `}>{item.verifyid}</p></td>
                          </tr>
                        ))
                    }
                    {(getVerifyEmail.length > 0 && getVerifyEmail.length < 5) ?
                      tbodyFunction(getVerifyEmail.length)
                      : <></>}
                  </tbody>
                </table>
              </div>
              <div className="mt-4 mb-1 flex gap-4  justify-between">
                <input type="text" name="emailVerify" value={senderverifyEmail} placeholder="Enter your sender's id" className="p-1 border-b outline-0 w-3/5 xs:w-4/5 sm:text-[12px] xs:text-[12px]" onChange={(e) => setSenderEmailVerify(e.target.value)} onKeyUp={() => { handleValidationMail(senderverifyEmail) }}>
                </input>
                <button type='button' disabled={!buttonDisabled || emailError !== false} className="text-center ml-2 p-[6px]  4xl:p-[8px] sm:text-[12px] xs:text-[12px] 3xl:text-[14px] 4xl:text-[18px] bg-theme-color text-white disabled:opacity-40 rounded-lg ... " onClick={() => handleEmailVerify()}>
                  Send For Verification
                </button>
              </div>
              <div>
                {emailError ? <span className="text-red-600 text-[10px]">Please enter a valid email address.</span> : <></>}
              </div>
            </div>


            {/* Verifired  DLT id’s for SMS */}

            <div>
            <h1 className="text-[20px] sm:text-[18px]  xs:text-[16px] 3xl:text-[24px] 4xl:text-[30px] font-normal text-theme-color mb-[8px]">Verifired  DLT id’s for SMS
              </h1>
              <div className="lg:w-full w-full overflow-scroll h-[315px] sm:h-[210px] xs:h-[200px] no-scrollbar">
                <table className="divide-y divide-gray-200 dark:divide-gray-700 table-fixed w-full">
                  <thead className="sticky top-0">
                    <tr className="bg-theme-color" >
                      <th scope="col" className="px-6 py-3 3xl:text-[14px] sm:text-[13px] xs:text-[11px] text-left text-white font-normal uppercase">Sender's Id</th>
                      <th scope="col" className="px-6 py-3 3xl:text-[14px] sm:text-[13px] xs:text-[11px] flex justify-center text-white font-normal uppercase">Status</th>
                    </tr>
                  </thead>
                  <tbody className="border-l border-r border-b">
                    {
                      getVerifySms.length === 0 ? <tr>
                        <td colSpan={2} className="p-0">
                          <img src={noTemplateData}></img>
                        </td></tr>
                        : getVerifySms.map((item, index) => (
                          <tr className={`leading-[12px] ${getVerifySms.length % 2 === 0 ? index % 2 === 0 ? "bg-[#ffff]" : "bg-[#e9effd]" : index % 2 !== 0 ? "bg-[#ffff]" : "bg-[#e9effd]"}`}>
                            <td className="px-6 py-[6px]  text-[14px] 4xl:text-[18px] whitespace-nowrap border-b">
                              {item.Id}
                            </td>
                            <td className={`px-6 py-[6px] 4xl:py-3 whitespace-nowrap border-b flex justify-center ${"Pending" === 'APPROVED' ? 'text-[#3C7D40]' : 'text-[#d6792d]'} text-[14px] 4xl:text-[18px]`}>
                              <p className={` ${"Pending" === 'APPROVED' ? 'bg-[#E9F4EB]' : 'bg-[#fbf0e4]'} rounded-md text-[14px] flex px-[15px] p-[4px_9px] 4xl:p-[6px_12px]`}>Pending</p>
                            </td>
                          </tr>
                        ))
                    }
                    {(getVerifySms.length > 0 && getVerifySms.length < 5) ?
                      tbodyFunction(getVerifySms.length)
                      : <></>}
                  </tbody>
                </table>
              </div>
              <div className="mt-4 flex gap-4 justify-between">
                <input type="text" name="smsVerify" value={senderSmsVerify} placeholder="Enter your sender's id" maxLength={6} className="p-1 border-b outline-0 w-3/5 sm:text-[12px] xs:text-[12px]" onChange={(e) => setSenderSmsVerify(e.target.value)} onKeyUp={() => { handleValidationSms(senderSmsVerify) }}>
                </input>
                <button type='button' disabled={!smsButtonDisabled || smsError !== false} className="text-center ml-2 p-[6px] 4xl:p-[8px] 3xl:text-[14px] 4xl:text-[18px] sm:text-[12px] xs:text-[12px] bg-theme-color text-white disabled:opacity-40 rounded-lg ... " onClick={() => { handleSmsVerify() }}>
                  Send For Verification
                </button>
              </div>
              <div>
                {smsError ? <span className="text-red-600 text-[10px]">Please enter a valid sms username.</span> : <></>}
              </div>
            </div>

            {/* Verifired  DLT id’s for WhatsApp */}
            <div>
            <h1 className="text-[20px] sm:text-[18px]  xs:text-[16px] 3xl:text-[24px] 4xl:text-[30px] font-normal text-theme-color mb-[8px]">Verified  DLT id's for Whatsapp
              </h1>
              <div className="lg:w-full w-full overflow-scroll h-[315px] sm:h-[210px] xs:h-[200px] no-scrollbar">
                <table className="divide-y divide-gray-200 dark:divide-gray-700 table-fixed w-full">
                  <thead className="sticky top-0">
                    <tr className="bg-theme-color" >
                      <th scope="col" className="px-6 py-3 text-[14px] sm:text-[13px] xs:text-[11px] 4xl:text-[18px] text-left text-white uppercase font-normal">Sender's Id</th>
                      <th scope="col" className="px-6 py-3 3xl:text-[14px] sm:text-[13px] xs:text-[11px] flex justify-center text-white uppercase font-normal">Status</th>
                    </tr>
                  </thead>
                  <tbody className="border-l border-r border-b">
                    {
                      getVerifyWhatsApp.length == 0 ?
                        <>
                          <tr>
                            <td colSpan={2} className="p-0">
                              <img src={noTemplateData}></img>
                            </td></tr>
                        </>
                        : getVerifyWhatsApp.map((item, index) => (
                          <tr className={`leading-[12px] ${getVerifyWhatsApp.length % 2 === 0 ? index % 2 === 0 ? "bg-[#ffff]" : "bg-[#e9effd]" : index % 2 !== 0 ? "bg-[#ffff]" : "bg-[#e9effd]"}`}>
                            <td className="px-6 py-[6px] text-[14px] 4xl:text-[18px] whitespace-nowrap border-b">
                              {item.Id}
                            </td>
                            <td className={`px-6 py-[6px] 4xl:py-3 whitespace-nowrap border-b flex justify-center ${"Pending" === 'APPROVED' ? 'text-[#3C7D40]' : 'text-[#d6792d]'} text-[14px] 4xl:text-[18px]`}>
                              <p className={` ${"Pending" === 'APPROVED' ? 'bg-[#E9F4EB]' : 'bg-[#fbf0e4]'} rounded-md text-[14px] flex px-[15px] p-[4px_9px] 4xl:p-[6px_12px]`}>Pending</p>
                            </td>
                          </tr>
                        ))
                    }
                    {getVerifyWhatsApp.length > 0 && getVerifyWhatsApp.length < 5 ?
                      tbodyFunction(getVerifyWhatsApp.length)
                      : <></>}

                  </tbody>
                </table>
              </div>
              <div className="mt-4 mb-[24px] flex gap-4 justify-between">
                <input type="text" name="whatsAppVerify" value={senderWhatsappVerify} maxLength={10} placeholder="Enter your sender's id" className="p-1 border-b outline-0 w-3/5 sm:text-[12px]  xs:text-[12px]" onChange={(e) => setSenderWhatsappVerify(e.target.value)} onKeyUp={() => { handleValidationNumber(senderWhatsappVerify) }}>
                </input>
                <button type='button' disabled={!whatsappButtonDisabled || numberError !== false} className="text-center ml-2 p-[6px] 4xl:p-[8px] 3xl:text-[14px] 4xl:text-[18px] sm:text-[12px] xs:text-[12px] bg-theme-color text-white disabled:opacity-40 rounded-lg ...  " onClick={() => { handleWhatsApp() }}>
                  Send For Verification
                </button>
              </div>
              <div className="mb-4">
                {numberError ? <h1 className="text-red-600 text-[10px]">Please enter a valid Phone Number.</h1> : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default VerifiedId